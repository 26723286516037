import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect"; // Use this package for device detection

const ShowLiveImage = ({
  mobileHeight = 200,
  tabHeight = 276,
  newWidth = 290,
  currentImage,
  nextImage,
  contentType,
  fadeAnimCurrent,
  fadeAnimNext,
}) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { width } = windowDimensions;
  const isHandset = isMobile;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#080f18",
        paddingRight: "150px", // You may need to adjust this value
        border: "1px solid #FF0000",
        position: "relative", // Helps with absolute positioning of images
        width: width - newWidth,
        paddingTop: isHandset ? "0" : "0",
        height: isHandset ? mobileHeight : "1200px",
      }}
    >
      {currentImage && currentImage.length > 0 && (
        <img
          style={{
            alt: "high-quality",
            width: width - newWidth,
            height: isHandset ? mobileHeight : tabHeight,
            opacity: fadeAnimCurrent,
            position: "absolute",
            transition: "opacity 0.5s ease",
          }}
          src={`data:${contentType};base64,${currentImage}`}
          srcSet={`
                data:${contentType};base64,${currentImage} 1x,
                data:${contentType};base64,${currentImage} 2x
              `}
          alt="HD Image"
        />
      )}
      {nextImage && nextImage.length > 0 && (
        <img
          style={{
            width: width - newWidth,
            alt: "high-quality",
            height: isHandset ? mobileHeight : tabHeight,
            opacity: fadeAnimNext,
            position: "absolute",
            objectFit: "cover",
            imageRendering: "auto",

            transition: "opacity 0.5s ease",
            height: "1000px", // Add transition for fade effect
          }}
          src={`data:${contentType};base64,${nextImage}`}
          alt="Next"
        />
      )}
      {(!currentImage || currentImage.length === 0) &&
        (!nextImage || nextImage.length === 0) && (
          <div
            style={{
              display: "flex",
              alt: "high-quality image",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <p style={{ color: "white" }}> -- Data is not available -- </p>
          </div>
        )}
    </div>
  );
};

// Prop Types for type checking
ShowLiveImage.propTypes = {
  mobileHeight: PropTypes.number,
  tabHeight: PropTypes.number,
  newWidth: PropTypes.number,
  currentImage: PropTypes.string, // Change to string to match your expected data type
  nextImage: PropTypes.string, // Change to string to match your expected data type
  contentType: PropTypes.string.isRequired,
  fadeAnimCurrent: PropTypes.number.isRequired,
  fadeAnimNext: PropTypes.number.isRequired,
};

export default ShowLiveImage;
