import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//mui-components
import { DevicesTwoTone, DnsRounded } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

//components
import Iconify from '../../components/iconify/Iconify';

//services
import ApiCalls from "../../services/try";

//externals
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

const DeviceProfileReview = ({ handleBack, data }) => {

  const [groups, setGroups] = useState([]);
  const [detailsShown, setDetailShown] = useState([]);
  const [detailsShowns, setDetailShowns] = useState([]);
  const [redirect, setRedirect] = useState(false);

  const fetchGroupDetails = () => {
    var tenantId = secureSessionStorage.getItem("tenantId");
    var companyId = secureSessionStorage.getItem("compId");

    console.log("-----" + tenantId + "-------" + companyId + "------");

    ApiCalls.getdetails("tenant/" + tenantId + "/company/" + companyId + "/physician/0/parametergroup")
      .then(result => {
        const values = result.data.parametergroup;
        console.log(values);
        setGroups(values);
      })
  }

  useEffect(() => {
    fetchGroupDetails();
  }, [])

  const toggleShown = groupName => {
    const shownState = detailsShown.slice();
    const index = shownState.indexOf(groupName);
    if (index >= 0) {
      shownState.splice(index, 1);
      setDetailShown(shownState);
    } else {
      shownState.push(groupName);
      setDetailShown(shownState);
    }
  }
  const toggleShowns = username => {
    const shownState = detailsShowns.slice();
    const index = shownState.indexOf(username);
    if (index >= 0) {
      shownState.splice(index, 1);
      setDetailShowns(shownState);
    } else {
      shownState.push(username);
      setDetailShowns(shownState);
    }
  };

  const handleSubmit = () => {
    const profileForm = {
      modelNumber: data.modelNumber,
      modelName: data.modelName,
      modelmanufacturer: data.modelmanufacturer,
      modelTags: data.modelTags === "" ? "#" + data.modelName : data.modelTags,
      versionNumber: data.versionNumber,
      devImage: data.devImage === "" ? "null" : data.devImage,
      streamEnabled: data.streamEnabled === true ? "active" : "",
      vitalsEnabled: data.vitalsEnabled === true ? "active" : "",
      promptText: data.promptText === "" ? "" : data.promptText,
      category: data.category,
      gateway: data.gateway,
      engine: data.engine,
      enginemodel: data.enginemodel,
      // Include transformed apiKeys with only id and apiKey
      apiKeys: data.apiKeys.map((key) => ({
        id: key.id,
        apiKey: key.apiKey,
      }))
    }

    var tenantId = secureSessionStorage.getItem("tenantId");
    var companyId = secureSessionStorage.getItem("compId");
    var physicianId = "";
    var rolename = secureSessionStorage.getItem("rolename");
    if (rolename === "Tenant") {
      tenantId = secureSessionStorage.getItem("UUID");
      companyId = "0";
      physicianId = "0";
    } else if (rolename === "Company") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = secureSessionStorage.getItem("UUID");
      physicianId = "0";
    }

    console.log(profileForm);

    ApiCalls.register("Tenant/" + tenantId + "/company/" + companyId + "/addmodel", profileForm)
      .then((response) => {
        console.log(response.data);
        if (response.data.modelstatus.message === "Success") {
          mapGrouptoModel(response.data.modelData.uuid);
        } else if (response.data.modelstatus.details === "Already existing Device Model") {
          swal("Already existing Device Profile", {
            icon: "info",
          });
        }
      }).catch((err) => {
        console.log(err);
        console.log(err.message);
        if (err.message === "Request failed with status code 409") {
          swal("Already existing Device Profile", {
            icon: "info",
          });
        }
      })
  }

  const mapGrouptoModel = (deviceId) => {
    console.log("going to map group with model" + deviceId);
    const firstGroup = groups[0];
    const parametersToMap = firstGroup.parameters && firstGroup.parameters.map(parameter => ({
      paramId: parameter.paramId,
      name: parameter.name
    }));

    // Create the groupData object with the updated parameters
    const groupData = {
      groupName: firstGroup.groupName,
      type: firstGroup.type,
      tags: firstGroup.tags,
      parameters: parametersToMap
    };
    var tenantId = secureSessionStorage.getItem("tenantId");
    var companyId = secureSessionStorage.getItem("compId");
    var physicianId = "";
    var rolename = secureSessionStorage.getItem("rolename");
    if (rolename === "Tenant") {
      tenantId = secureSessionStorage.getItem("UUID");
      companyId = "0";
      physicianId = "0";
    } else if (rolename === "Company") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = secureSessionStorage.getItem("UUID");
      physicianId = "0";
    }
    ApiCalls.register("tenant/" + tenantId + "/company/" + companyId + "/parametergroup/" + deviceId, groupData)
      .then((response) => {
        console.log("Mapping Group Details.....");
        console.log(response.data);

        if (response.data.status.message === "Success") {
          removeAddedGroup(tenantId, companyId);
          if (secureSessionStorage.getItem("ruleName")) {
            var ruleName = secureSessionStorage.getItem("ruleName").toString();
            updateRuleDevId(deviceId, ruleName);
          }
        } else {
          swal("Error adding Device Profile", {
            icon: "error",
          });
        }
      })
  }

  const updateRuleDevId = (deviceId, ruleName) => {
    console.log("inside this...");
    console.log(deviceId);
    console.log(ruleName);
    ApiCalls.edit("model/" + deviceId + "/rule/" + ruleName + "/updateruledevId")
      .then((response) => {
        console.log(response.data)
      })
  }


  const removeAddedGroup = (tenantId, companyId) => {
    console.log("TenantId------" + tenantId + "--------CompanyId--------" + companyId);
    ApiCalls.delete("tenant/" + tenantId + "/company/" + companyId + "/parametergroup")
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "Success") {
          swal("Device Profile Successfully Registered", {
            icon: "success",
          });
          secureSessionStorage.removeItem('currentDeviceID');
          secureSessionStorage.removeItem('checkedItems');
          secureSessionStorage.removeItem('currentDeviceName');
          secureSessionStorage.removeItem('editDeviceId');
          secureSessionStorage.removeItem('DeviceImage');
          secureSessionStorage.removeItem('selectedParameters');
          setRedirect(true);
        } else {
          swal("Error adding Device Profile", {
            icon: "error",
          });
        }
      })
  }

  if (redirect) {
    return <Redirect to="/deviceprofile" />;
  }

  return (
    <Container sx={{ marginTop: 5, minWidth: 800 }}>
      <Card>
        <CardHeader />
        <CardContent>
          <ListItemButton>
            <ListItemIcon>
              <DevicesTwoTone />
            </ListItemIcon>
            <ListItemText primary="Model Name" />
            <ListItemText primary={data.modelName} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <DnsRounded />
            </ListItemIcon>
            <ListItemText primary="Model Number" />
            <ListItemText primary={data.modelNumber} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <DnsRounded />
            </ListItemIcon>
            <ListItemText primary="Version" />
            <ListItemText primary={data.versionNumber} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <DnsRounded />
            </ListItemIcon>
            <ListItemText primary="Manufacturer" />
            <ListItemText primary={data.modelmanufacturer} />
          </ListItemButton>
          {groups.map(group => (
            <div key={group.id}>
              <ListItemButton onClick={() => toggleShown(group.groupName)}>
                <ListItemIcon>
                  <DnsRounded />
                </ListItemIcon>
                <ListItemText primary={group.groupName} />
                <ListItemIcon>
                  {detailsShown.includes(group.groupName) ? (
                    <Iconify icon={'ph:caret-circle-up-fill'} />
                  ) : (
                    <Iconify icon={'ph:caret-circle-down-fill'} />
                  )}
                </ListItemIcon>
              </ListItemButton>
              <Collapse in={detailsShown.includes(group.groupName)}>
                <List component="div" disablePadding>
                  {group.parameters && group.parameters.map((parameter, index) => (
                    <ListItemButton key={index} sx={{ pl: 10 }} onClick={() => toggleShowns(parameter.name)}>
                      <ListItemIcon>
                        <Iconify icon={'line-md:check-list-3-filled'} />
                      </ListItemIcon>
                      <ListItemText primary={parameter.name} />
                      <ListItemText primary={parameter.typeName} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}

        </CardContent>
        <CardActions>
          <Button
            fullWidth
            color="error"
            size="small"
            type="submit"
            variant="outlined"
            onClick={handleBack}
            sx={{
              borderRadius: "12px",
            }}
            startIcon={
              <Iconify icon={'ic:twotone-arrow-left'} />
            }
          >
            Previous
          </Button>
          <Button
            fullWidth
            color="primary"
            size="small"
            type="submit"
            variant="outlined"
            onClick={handleSubmit}
            sx={{
              borderRadius: "12px",
            }}
            endIcon={
              <Iconify icon={'ion:checkmark-done-circle'} />
            }
          >
            Finish
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}

export default DeviceProfileReview
