import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//internals
import Preview from "../../layout/Preview";

//mui-components
import { Add, Cancel, Close, Edit, Home, PlaylistRemoveRounded } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    Fab,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { pink } from '@mui/material/colors';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//-----------------------------------------------------------------------------

const EditDevice = () => {

    const [name, setName] = useState('');
    const [macId, setMacId] = useState('');
    const [modelName, setModelName] = useState('');
    const [devImg, setDevImg] = useState('');
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [patientInfo, setPatientInfo] = useState('false');

    const [showIcon, setShowIcon] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [macIdError, setMacIdError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [loadPatients, setLoadPatients] = useState(false);
    const [openAddMorePatient, setOpenAddMorePatient] = useState(false);
    const [loading, setLoading] = useState(false);

    const [assignedPatients, setAssignedPatients] = useState([]);
    const [patient, setPatient] = useState([]);
    const [selectedPatienttoAssign, setSelectedPatienttoAssign] = useState([]);

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const loadDeviceDetailsById = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/viewdevice")
            .then((response) => {
                console.log(response.data);
                if (response.data != undefined) {
                    setDevImg(response.data.deviceImage);
                    setName(response.data.devicename);
                    setMacId(response.data.devmacid);
                    setModelName(response.data.modelName);
                }
            })
    }

    const loadDeviceAsignedPatients = (deviceId) => {
        setLoadPatients(true);
        ApiCalls.getdetails("device/" + deviceId + "/getpatientsassigned")
            .then((response) => {
                console.log(response.data);
                if (response.data.patient != undefined) {
                    setAssignedPatients(response.data.patient);
                    setLoadPatients(false);
                } else {
                    setAssignedPatients([]);
                    setLoadPatients(false)
                }
            })
    }

    const loadPatientList = () => {
        setLoading(true);
        let companyId = secureSessionStorage.getItem("compId");
        let tenantId = secureSessionStorage.getItem("tenantId");
        let physicianId = "0";
        const rolename = secureSessionStorage.getItem("rolename");

        switch (rolename) {
            case "Tenant":
                tenantId = secureSessionStorage.getItem("UUID");
                companyId = secureSessionStorage.getItem("compId");
                physicianId = "0";
                break;
            case "Company":
                tenantId = secureSessionStorage.getItem("tenantId");
                companyId = secureSessionStorage.getItem("UUID");
                physicianId = "0";
                break;
            case "Physician":
                tenantId = secureSessionStorage.getItem("tenantId");
                companyId = secureSessionStorage.getItem("companyId");
                physicianId = secureSessionStorage.getItem("UUID");
                break;
            default:
                break;
        }

        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians/" + physicianId + "/patients")
            .then((response) => {
                console.log(response.data);
                if (response.data.patient != undefined) {
                    setPatient(response.data.patient);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setPatient([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setPatient([]);
            })
    }

    useEffect(() => {
        const macId = secureSessionStorage.getItem("deviceIdToEdit");
        const deviceId = secureSessionStorage.getItem("deviceIdSelected")
        console.log(macId)
        setSelectedDeviceId(deviceId);
        var roleId = secureSessionStorage.getItem("roleId");

        ApiCalls.getdetails("roleId/" + roleId + "/getpatientprivilegedetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.patientprivilege != undefined) {
                    const patientInfo = response.data.patientprivilege[0].patientInfo;
                    console.log("PatientInfo: " + patientInfo);
                    setPatientInfo(patientInfo);
                    if (patientInfo === "true") {
                        loadDeviceAsignedPatients(macId);
                    }
                }
            })
        loadPatientList();
        loadDeviceDetailsById(macId);
    }, [])

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'name':
                    setNameError(true);
                    break;
                case 'macId':
                    setMacIdError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'name':
                setNameError(false);
                setName(event.target.value);
                break;
            case 'macId':
                setMacIdError(false);
                setMacId(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleUpdate = () => {
        if (!name) {
            setNameError(true);
        } else if (!macId) {
            setMacIdError(true);
        } else {
            const deviceId = secureSessionStorage.getItem("deviceIdToEdit");
            const data = {
                devicename: name,
                modelName: modelName,
                bleid: "",
                devmacid: macId,
            }
            console.log(data);
            ApiCalls.edit("devmacid/" + deviceId + "/updatedevicebymacid?", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal(secureSessionStorage.getItem("DeviceName") + " Successfully Updated", {
                            icon: "success",
                        });
                        setRedirectToList(true);
                    } else {
                        setRedirectToList(true);
                    }
                })
        }
    }

    const handleOpenPatientList = () => {
        setOpenAddMorePatient(true);
    }

    const handleClosePatientList = () => {
        setOpenAddMorePatient(false);
    }

    const handleDeselectPatient = (patient) => {
        console.log(patient);
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                var deviceId = selectedDeviceId;
                var patientId = patient.patientuuid;

                console.log(deviceId, patientId)

                ApiCalls.delete("device/" + deviceId + "/patient/" + patientId + "/deselectDevice")
                    .then(function (response) {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Deselected Successfully!", {
                                icon: "success",
                            });
                            loadDeviceAsignedPatients(macId);
                        }
                    })
            }
        })
    }

    if (redirectToList) {
        return (
            <Redirect to={'/managedevice'} />
        )
    }

    const handleAssignPatienttoDevice = () => {
        console.log(selectedPatienttoAssign[0].id);
        console.log(selectedDeviceId);

        var data = {
            patients: [
                {
                    patientuuid: selectedPatienttoAssign[0].id
                }
            ]
        }

        ApiCalls.register("device/" + selectedDeviceId + "/patient/assign", data)
            .then((response => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal((secureSessionStorage.getItem("DeviceName") + "Assigned"), {
                        icon: "success",
                    });
                    handleClosePatientList();
                    loadDeviceAsignedPatients(macId);
                } else if (response.data.status.details === "Device Already Assigned To The Patient") {
                    swal((secureSessionStorage.getItem("DeviceName") + " Already Assigned"), {
                        icon: "warning",
                    });
                } else if (response.data.status.details === "A device of the same category is already assigned to the patient") {
                    swal((`A ${secureSessionStorage.getItem("DeviceName")} of the same category is already assigned to the ${secureSessionStorage.getItem("PatientName")}`), {
                        icon: "warning",
                    });
                }
            })).catch((error) => {
                console.log(error.message);
            });
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managedevice">
                            <Button variant="text" startIcon={<Icon icon={'tabler:devices-plus'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureSessionStorage.getItem("DeviceName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Edit " + secureSessionStorage.getItem("DeviceName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Edit " + secureSessionStorage.getItem("DeviceName")}
                        </Typography>}
                    />
                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 2 }}>
                                        <Box gridColumn="span 4" sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}>
                                            <Item>
                                                <Avatar
                                                    alt="image"
                                                    src={devImg}
                                                    variant="rounded"
                                                    sx={{
                                                        marginLeft: 5,
                                                        width: 200,
                                                        height: 200,
                                                        transition: 'all 0.2s ease-in-out',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            transform: 'scale(1.1)',
                                                        },
                                                    }}
                                                    onClick={() => document.getElementById('avatar-input').click()}
                                                    onMouseEnter={() => setShowIcon(true)}
                                                    onMouseLeave={() => setShowIcon(false)}
                                                />
                                            </Item>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box gridColumn="span 6">
                                    <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                        <TextField
                                            name="name"
                                            id="name"
                                            label={
                                                <span>
                                                    Device Name{' '}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            value={name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={nameError}
                                            helperText={nameError && "Device Name is required"}
                                        />
                                        <TextField
                                            name="macId"
                                            id="macId"
                                            label={
                                                <span>
                                                    Device MacId{' '}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            value={macId}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={macIdError}
                                            helperText={macIdError && "Device MacId is required"}
                                        />
                                        <TextField
                                            name="modelName"
                                            id="modelName"
                                            label={
                                                <span>
                                                    Model Name{' '}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            variant="filled"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={modelName}
                                            fullWidth
                                        />
                                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 2 }}>
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="error"
                                                onMouseDown={handleMouseDown}
                                                onClick={() => setRedirectToList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={<Cancel />}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onMouseDown={handleMouseDown}
                                                onClick={handleUpdate}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={<Edit />}
                                            >
                                                Update
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{
                    minWidth: 300,
                    marginTop: 2,
                    display: patientInfo === "false" ? 'none' : ''
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Assigned " + secureSessionStorage.getItem("PatientName")}
                        </Typography>}
                        action={
                            <Tooltip title='Assign More'>
                                <Fab disabled={assignedPatients.length > 0} onMouseDown={handleMouseDown} onClick={handleOpenPatientList} size="small" color="primary" aria-label="add">
                                    <Add />
                                </Fab>
                            </Tooltip>
                        }
                    />

                    <CardContent sx={{ maxHeight: 300, overflowY: "scroll" }}>
                        {loadPatients ? (
                            <center>
                                <CircularProgress />
                            </center>
                        ) : (
                            <Grid container spacing={2}>
                                {
                                    assignedPatients.length > 0 ?
                                        assignedPatients.map((patient) => (
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Card sx={{
                                                    position: 'relative',
                                                    boxShadow: 3,
                                                    '&:hover .delete-icon': {
                                                        display: 'block',
                                                    },
                                                }}>
                                                    <Tooltip title={"Deselect " + secureSessionStorage.getItem("PatientName")}>
                                                        <PlaylistRemoveRounded
                                                            className="delete-icon" sx={{
                                                                fontSize: "30px",
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                display: 'none',
                                                                color: pink[500],
                                                                cursor: 'pointer',
                                                                zIndex: 1,
                                                            }}
                                                            onClick={() => handleDeselectPatient(patient)}
                                                        />
                                                    </Tooltip>
                                                    <CardHeader
                                                        avatar={
                                                            <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: 2 }}>
                                                                <Avatar
                                                                    alt={patient.firstName + " " + patient.lastName}
                                                                    src={patient.image}
                                                                    sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                            {(patient.firstName ? patient.firstName : '') +
                                                                                (patient.lastName ? ' ' + patient.lastName : '') || '--'}
                                                                        </Typography>
                                                                        {patient.email && (
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                <Icon icon={'gridicons:mail'} /> {patient.email}
                                                                            </Typography>
                                                                        )}
                                                                        {patient.phone && (
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                <Icon icon={'gridicons:mail'} /> {patient.phone}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        } />
                                                </Card>
                                            </Grid>
                                        )) :
                                        <CardHeader
                                            avatar={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {"No " + secureSessionStorage.getItem("PatientName") + " Assigned"}
                                                </Box>
                                            } />
                                }
                            </Grid>
                        )}
                    </CardContent>
                </Card>

                <div style={{
                    height: "15px"
                }} />

                <div>
                    <Dialog open={openAddMorePatient} onClose={handleClosePatientList}>
                        <CardHeader
                            title={"Add " + secureSessionStorage.getItem("PatientName")}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePatientList}>
                                    <Close />
                                </IconButton>
                            }
                        />

                        <CardContent>
                            {loading ? (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 200 }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    {
                                        patient.length > 0 ?
                                            <>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    maxHeight: 400,
                                                    overflowY: "scroll"
                                                }} >
                                                    {patient
                                                        .map((patient) => (
                                                            <FormControlLabel
                                                                key={patient.patientuuid}
                                                                control={<Checkbox
                                                                    checked={selectedPatienttoAssign.some(
                                                                        (d) => d.id === patient.patientuuid
                                                                    )}
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        const patientId = patient.patientuuid;
                                                                        const patientName = patient.firstName + " " + patient.lastName;
                                                                        if (checked) {
                                                                            setSelectedPatienttoAssign([{ id: patientId, name: patientName }]);
                                                                        } else {
                                                                            setSelectedPatienttoAssign([]);
                                                                        }
                                                                    }}
                                                                    value={patient.patientuuid}
                                                                />}
                                                                label={<>
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Avatar
                                                                                    variant='rounded'
                                                                                    alt={patient.firstName + " " + patient.lastName}
                                                                                    src={patient.image}
                                                                                    sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                            {patient.firstName || ''} {patient.lastName ? patient.lastName : ''}
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                            {patient.gender}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        } />

                                                                </>}
                                                            />
                                                        ))}
                                                </Box>
                                            </>
                                            : <Box sx={{

                                                alignItems: "center"  // Center the content vertically
                                            }}>
                                                <div style={{ margin: "auto", textAlign: "center" }}>
                                                    No {secureSessionStorage.getItem("DeviceName")} Found
                                                </div>
                                            </Box>
                                    }
                                </>
                            )}
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleClosePatientList} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} disabled={!selectedPatienttoAssign.length > 0} onClick={handleAssignPatienttoDevice} fullWidth >Submit</Button>
                        </CardActions>
                    </Dialog>
                </div>

            </Container>
        </div>
    )
}

export default EditDevice
