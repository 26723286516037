import React from 'react';
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";

//internals
import AddCareGiver from './views/AddCareGiver';
import AddCompany from './views/AddCompany';
import AddDevice from './views/AddDevice/index';
import AddDeviceProfile from './views/AddDeviceProfile';
import AddPatient from './views/AddPatient';
import AddPhysician from './views/AddPhysician';
import AddSensor from './views/AddSensor';
import CMSView from './views/CMSView';
import Dashboard from './views/Dashboard';
import PatientDashboard from './views/Dashboard/components/patientDashboard';
import DeviceTwin from './views/DeviceTwin';
import DeviceView from './views/DeviceView';
import EditCompany from './views/EditCompany';
import EditDevice from "./views/EditDevice";
import EditDeviceProfile from "./views/EditDeviceProfile";
import EditPatient from './views/EditPatient';
import EditPhysician from './views/EditPhysician';
import EditSensor from './views/EditSensor';
import EnrollDevice from "./views/EnrollDevice";
import ManageBed from './views/ManageBed';
import ManageCMS from './views/ManageCMS';
import ManageCareGiver from './views/ManageCareGiver';
import ManageCompany from './views/ManageCompany';
import ManageDevice from './views/ManageDevice';
import DeviceHistory from './views/ManageDevice/DeviceHistory';
import DeviceList from './views/ManageDevice/DeviceList';
import ManageDeviceProfile from './views/ManageDeviceProfile';
import ManageDiagnostics from "./views/ManageDiagnostics";
import ManagePatient from './views/ManagePatient';
import ManagePhysician from './views/ManagePhysician';
import ManageProducer from './views/ManageProducer';
import ManageSensor from './views/ManageSensor';
import NotFound from "./views/NotFound";
import PatientCMS from "./views/PatientCMS";
import PatientView from './views/PatientView';
import Profile from './views/Profile';
import ForgotPassword from "./views/ResetPassword";
import Settings from './views/Settings';
import KeySettings from './views/Settings/components/KeySettings';
import SignIn from "./views/SignIn";
import SignUp from './views/SignUp';
import WizardEdit from "./views/WizardEdit";
import Graph from './views/iTouch/Graph';
import GraphBpl from './views/iTouch/GraphBpl';
import GraphBplNew from './views/iTouch/GraphBplNew';
import GraphNew from './views/iTouch/GraphNew';
import GraphPump from './views/iTouch/GraphPump';
import WizardSetup from "./views/wizardSetup";

const Routers = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/SignIn"
            />
            <Route exact path="/SignIn">
                <SignIn />
            </Route>
            <Route path="/wizardsetup">
                <WizardSetup />
            </Route>
            <Route path="/wizardedit">
                <WizardEdit />
            </Route>
            <Route path="/dashboard">
                <Dashboard />
            </Route>
            <Route path="/mydashboard">
                <PatientDashboard />
            </Route>
            <Route path="/SignUp">
                <SignUp />
            </Route>
            <Route path="/forgotPassword">
                <ForgotPassword />
            </Route>
            <Route path="/managecustomer">
                <ManageCompany />
            </Route>
            <Route path="/managetherapist">
                <ManagePhysician />
            </Route>
            <Route path="/managecaregiver" >
                <ManageCareGiver />
            </Route>
            <Route path="/managepatient">
                <ManagePatient />
            </Route>
            <Route path="/deviceprofile">
                <ManageDeviceProfile />
            </Route>
            <Route path="/devicehistory">
                <DeviceHistory />
            </Route>
            <Route path="/devicelist">
                <DeviceList />
            </Route>
            <Route path="/managedevice">
                <ManageDevice />
            </Route>
            <Route path="/managebeds">
                <ManageBed />
            </Route>
            <Route path="/cms" >
                <ManageCMS />
            </Route>
            <Route path="/cmsview">
                <CMSView />
            </Route>
            <Route path="/Graph">
                <Graph />
            </Route>
            <Route path="/GraphNew">
                <GraphNew />
            </Route>
            <Route path="/GraphBpl">
                <GraphBpl />
            </Route>
            <Route path="/GraphBplNew">
                <GraphBplNew />
            </Route>
            <Route path="/GraphPump">
                <GraphPump />
            </Route>
            <Route path="/manageproducer" >
                <ManageProducer />
            </Route>
            <Route path="/managesensor" >
                <ManageSensor />
            </Route>
            <Route path="/addcustomer">
                <AddCompany />
            </Route>
            <Route path="/registertherapist">
                <AddPhysician />
            </Route>
            <Route path="/registercaregiver">
                <AddCareGiver />
            </Route>
            <Route path="/registerpatient">
                <AddPatient />
            </Route>
            <Route path="/registerdevice">
                <AddDevice />
            </Route>
            <Route path="/adddeviceprofile">
                <AddDeviceProfile />
            </Route>
            <Route path="/registersensor">
                <AddSensor />
            </Route>
            <Route path="/editcompany">
                <EditCompany />
            </Route>
            <Route path="/editphysician">
                <EditPhysician />
            </Route>
            <Route path="/editpatient">
                <EditPatient />
            </Route>
            <Route path="/editdevice">
                <EditDevice />
            </Route>
            <Route path="/editdeviceprofile">
                <EditDeviceProfile />
            </Route>
            <Route path="/editsensor">
                <EditSensor />
            </Route>
            <Route path="/profile">
                <Profile />
            </Route>
            <Route
                exact
                path="/settings"
                component={Settings}
            />
            <Route
                path="/settings/key-settings"
                component={KeySettings}
            />
            <Route path="/deviceview">
                <DeviceView />
            </Route>
            <Route path="/devicetwin">
                <DeviceTwin />
            </Route>
            <Route path="/patientview">
                <PatientView />
            </Route>
            <Route path="/patientCMS">
                <PatientCMS />
            </Route>
            <Route path="/enrolldevice">
                <EnrollDevice />
            </Route>
            <Route path="/managediagnostics">
                <ManageDiagnostics />
            </Route>
            <Route path="*">
                <NotFound />
            </Route>
        </Switch>
    )
}

export default Routers