import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

//mui-components
import {
    Home
} from '@mui/icons-material';
import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Dialog,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

// External components
import QrScanner from 'react-qr-scanner';
import './QRCodeScanner.css';

// Internal components
import { Icon } from '@iconify/react';
import Preview from '../../layout/Preview';

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

//-----------------------------------------------------------------------------

const QRCodeScanner = () => {
    const [qrData, setQrData] = useState('');
    const [androidId, setAndroidId] = useState('');
    const [osVersion, setOsVersion] = useState('');
    const [device, setDevice] = useState('');

    const [openDialog, setOpenDialog] = useState(false);

    const registerButtonRef = useRef(null);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && openDialog) {
                registerButtonRef.current.click();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [openDialog]);

    const handleScan = (data) => {
        if (data) {
            setQrData(data);
            setOpenDialog(true);
        }
    };

    const handleError = (err) => {
        console.error('Error:', err);
    };

    const handleCloseDialog = () => {
        setQrData(''); // Clear the qrData when closing the dialog
        setOpenDialog(false);
    };

    const handleRegister = () => {
        // Handle registration logic here
        setOpenDialog(false);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const handleManualSubmit = () => { }

    return (
        <div style={{ display: 'flex' }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>
                        <StyledBreadcrumb label="Enroll Device" />
                    </Breadcrumbs>
                </Stack>

                <Card>
                    <div className="qr-scanner-container">
                        <div className="qr-scanner-window">
                            <QrScanner onScan={handleScan} onError={handleError} />
                        </div>
                    </div>

                    {/* Manual Input Form */}
                    <CardContent sx={{ textAlign: 'center', maxWidth: 500, mx: 'auto', my: 0 }}>
                        <Typography variant="h5" mb={2}>
                            OR
                        </Typography>

                        <Stack spacing={2} mb={2}>
                            <TextField
                                label="Android ID"
                                variant="outlined"
                                fullWidth
                                value={androidId}
                                onChange={(e) => setAndroidId(e.target.value)}
                            />
                            <TextField
                                label="OS Version"
                                variant="outlined"
                                fullWidth
                                value={osVersion}
                                onChange={(e) => setOsVersion(e.target.value)}
                            />
                            <TextField
                                label="Device"
                                variant="outlined"
                                fullWidth
                                value={device}
                                onChange={(e) => setDevice(e.target.value)}
                            />
                        </Stack>

                        <Button variant="contained"
                            color="primary"
                            sx={{ borderRadius: '50px' }}
                            startIcon={<Icon icon={'material-symbols-light:data-saver-on'} />}
                        >
                            Register
                        </Button>
                    </CardContent>
                </Card>
            </Container>

            {/* Dialog to display scanned data */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{
                    sx: {
                        position: 'relative',
                        borderRadius: '16px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        overflow: 'hidden',
                    },
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        minWidth: 300,
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    {/* Wave Shape Pseudo-element */}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: '250px',
                            background: 'linear-gradient(to bottom, #5C9CFE, #FFFFFF)',
                            zIndex: -1,
                            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
                            transform: 'scaleY(-1)', // Flip the background shape vertically
                        }}
                    />
                    <CardHeader
                        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        action={
                            <IconButton color="primary" onClick={handleCloseDialog}>
                                <Icon icon={"mingcute:close-fill"} width="30" />
                            </IconButton>
                        }
                    />

                    {/* Image */}
                    <div style={{ margin: '16px', marginTop: '15px', borderRadius: '8px', padding: '8px' }}>
                        <Icon
                            icon={'fluent:phone-tablet-24-filled'}
                            width="120"
                            style={{
                                color: '#5C9CFE', // Set the background color
                                // border: '2px solid #808080', // Set the border color and style
                                borderRadius: '8px', // Add border radius for rounded corners
                                padding: '8px', // Add padding for better visual appearance
                            }}
                        />

                    </div>


                    {/* Details */}
                    <CardContent>
                        <Typography variant="subtitle2" paragraph>
                            {qrData.text?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </Typography>
                    </CardContent>

                    {/* Register/Enroll Button */}
                    <CardActions sx={{
                        marginBottom: "20px"
                    }}>
                        <Button
                            onClick={handleRegister}
                            variant="contained"
                            color="primary"
                            sx={{ borderRadius: '50px' }}
                        >
                            Register
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>

        </div>
    );
};

export default QRCodeScanner;
