import React, { useEffect } from 'react'
import LiveLineChart from './LiveLineChart'
import MinimumDistanceSlider from './SiliderComp'
import { useState } from 'react';
import PpgChart from './PpgChart';
import EcgChart from './EcgChart';

const Index = () => {
  const maxDataPoints = 125;
  const [ppg, setPpg] = useState(Array(620).fill(0));
  const [ecg, setEcg] = useState(Array(1250).fill(0));
  const [rrw, setRrw] = useState(Array(620).fill(0));
  const [params, setParams] = useState({});
  const [paramValues, setParamValues] = useState({

  })
  const onParamValueChange = (data) => {
    console.log(data, "dataforparams");
    if (data.paramUUID == "10001") {
      setParams({ ...params, ['10001']: { ...data } });
    }
    if (data.paramUUID == "10003") {
      setParams({ ...params, ['10003']: { ...data } });
    }
  }
  useEffect(() => {
    const ws = new WebSocket("ws://164.92.90.27:4567/consumer");
    ws.onopen = () => {
      console.log('WebSocket connection opened');
      ws.send(JSON.stringify(
        {
          "PacketID": "WS_DEVICE_CONSUME_REQ",
          "DeviceUUID": "678899",
          // "DeviceUUID": deviceId,
          // "DeviceUUID": "f63324200444899d",
          "SamplingRate": 250,
          "WindowSize": 10,
          "MinYVal": 0,
          "MaxYVal": 128
        }
      ));
    };


    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onmessage = async (e) => {
      // const message = event.data;
      const message = JSON.parse(e.data);
      console.log(message, "messaged");
        const {doubleArray,ParamUUID } = await processMessage(message);
        console.log(doubleArray,ParamUUID, "newdatafound");
        handleEachArray(doubleArray, ParamUUID)
    };
    const handleEachArray = (array, id) => {
      if (id == '10000') setEcg(array)
      if (id == '10002') setPpg(array)
      if (id == '10004') setRrw(array)
    }
    return () => {
      ws.close();
    };
  }, []);

  const processMessage = async (msgString, parameterId) => {
    console.log(msgString , 'msgstringgot');
    let ParamUUID = ''
    let doubleArray = null;
    let paramValues = [];
    try {
      const commandParams = msgString;
      const command = commandParams.PacketID;
      console.log(commandParams.PacketID);

      switch (command) {
        case "WS_DEVICE_INFO":
          // Handle WS_DEVICE_INFO case if needed
          break;

        case "WS_PARAM_INFO":
          // Handle WS_PARAM_INFO case if needed
          break;

        case "WS_PARAM_DATA":
          const paramUUID = commandParams.ParamUUID;
          const paramType = commandParams.ParamType;
          // const seqNum = commandParams["SNo"];

          const dataType = commandParams.hasOwnProperty("DataType")
            ? commandParams["DataType"]
            : "Double";

          // console.log(paramUUID, parameterId);

          if (paramType === "Stream") {
            ParamUUID = commandParams.ParamUUID == undefined ? 10004 : commandParams.ParamUUID
            console.log('Stream', typeof (ParamUUID), paramUUID);
            // if (ParamUUID === '10002') {
            const data = commandParams["Data"];
            if (dataType === "Double") {
              console.log('itsDoublesss');
              doubleArray = await convertFromBase64StringToDouble(data);
            } else {
              console.log('itsDouble');
              doubleArray = await convertBase64ComaSeparatedStringToDoubleArray(data);

            }
            // }
          } else {
            const paramValue = commandParams.Value;
            console.log("paramValues: " + paramValue);
            const valD = parseFloat(paramValue);
            let alarmBorderColor = "transparent";
            // console.log("valId: " + valD);
            paramValues[paramUUID] = valD;
            console.log(paramValues, "paramValues test");
            // Check if paramValue is not null or undefined before sending the data
            // Check if the incoming value is not -100 before updating

            // Check if the data exceeds the threshold
            // const newAlarmBorderColor = valD == -100 ? alarmBorderColor : (valD > 100 ? "red" : (valD < 96 ? "red" : alarmBorderColor));
            // const newAlarmBorderColor = await setBorderColor(valD, paramUUID);
            setParamValues((paramValues) => ({
              ...paramValues,
              [paramUUID ? paramUUID : '10005']: {
                paramUUID,
                paramName: 'SpO₂',
                value: valD,
                // alarmBorderColor: newAlarmBorderColor
              }
            }));

            // onParamValueChange({
            //     paramUUID,
            //     paramName: commandParams["ParamName"],
            //     value: valD,
            //     alarmBorderColor: alarmBorderColor, // include alarmBorderColor
            // });

          }
          break;

        default:
          console.error("Unknown command:", command);
          break;
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    return { doubleArray, ParamUUID };
  };
  const convertFromBase64StringToDouble = (message) => {
    try {
      const byteData = atob(message);
      const byteArray = new Uint8Array(byteData.length);
      for (let i = 0; i < byteData.length; i++) {
        byteArray[i] = byteData.charCodeAt(i);
      }

      const doubleArray = [];
      const dataView = new DataView(byteArray.buffer);

      // Ensure that there is enough data for at least one double
      if (byteArray.length >= 8) {
        for (let i = 0; i < byteArray.length; i += 8) {
          // Check if there is enough data remaining for the current double
          if (i + 8 <= byteArray.length) {
            console.log(dataView.getFloat32(i, false), "dataat");
            doubleArray.push(dataView.getFloat32(i, false)); // true for little-endian
          } else {
            // console.error("Not enough data for a double at offset", i);
            // console.log("Current byteArray:", byteArray);
            console.log("Current doubleArray:", doubleArray);
            break;
          }
        }
      } else {
        console.error("Not enough data for a double");
      }

      return doubleArray;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const convertBase64ComaSeparatedStringToDoubleArray = (message) => {
    try {
      const byteData = atob(message);
      const intStrings = byteData.split(",");
      const doubleArray = intStrings.map((str) => parseInt(str));
      return doubleArray;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };



  return (
    <div style={{ width: "100%", height: '100vh', backgroundColor: '#080f18' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 4 }}>
          <EcgChart liveData={ecg} parameterName='ECG' parameterId={"10000"} onParamValueChange={onParamValueChange} />
          <PpgChart liveData={ppg} parameterName='SPO2' parameterId={"10002"} />
          <LiveLineChart liveData={rrw} parameterName='RR' parameterId={"10004"} />
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ height: '30%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: 'green', fontSize: '60px' }}>{params['10001']?.value}</p>
          </div>
          <div style={{ height: '30%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: 'blue', fontSize: '60px' }}>{params['10003']?.value}</p>
          </div>
          <div style={{ height: '30%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: 'yellow', fontSize: '60px' }}>{params['10005']?.value}</p>
          </div>
        </div>
      </div>
      <MinimumDistanceSlider />
    </div>
  )
}

export default Index