import React from 'react';
import { Dialog, colors } from '@mui/material';

function AlertBox({ openAlert, message, setOpenAlert }) {
    return (
        <Dialog open={openAlert} onClose={() => { setOpenAlert(false) }}>
            <div style={styles.dialog}>
                <span >
                    {message}
                </span>
            </div>
        </Dialog >
    )
}

export default AlertBox;

const styles = {
    dialog: {
        padding: '20px',
        borderRadius: '4px',
        width: '400px',
        height: '150px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        backgroundColor: '#080f18'
    },
};