import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

//mui-components
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

//components
import Iconify from '../../components/iconify';

//externals
import { Icon } from '@iconify/react';
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript'; // Language support for JavaScript (includes JSON)
import 'prismjs/themes/prism-tomorrow.css'; // You can choose a Prism theme you like
import Editor from 'react-simple-code-editor';
import secureSessionStorage from "../../crypto";

//services
import ApiCalls from "../../services/try";
//-----------------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const highlightWithPrism = (code) =>
    Prism.highlight(code, Prism.languages.javascript, 'javascript');

//-----------------------------------------------------------------------------

const DeviceProfile = ({
    data,
    formData,
    setFormData,
    handleChange,
    handleStreamEnabled,
    handleVitalsEnabled,
    onChangeX,
    handleNext,
    apiKeys
}) => {
    const [modelNameError, setModelNameError] = useState(false);
    const [modelNumberError, setModelNumberError] = useState(false);
    const [hover, setHover] = useState(false);
    const [redirectToList, setRedrectToList] = useState(false);
    const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
    const [apiKeyError, setApiKeyError] = useState(false);
    const [engineError, setEngineError] = useState(false);
    const [promptError, setPromptError] = useState(false);

    const handleBlur = (event) => {
        const name = event.target.name;
        if (name === 'modelName') {
            setModelNameError(!event.target.value);
        } else if (name === 'modelNumber') {
            setModelNumberError(!event.target.value);
        }
    }

    const handleFocus = (event) => {
        const name = event.target.name;
        if (name === 'modelName') {
            setModelNameError(false);
        } else if (name === 'modelNumber') {
            setModelNumberError(false);
        }
    }

    const handleCloseSettingsDialog = () => {
        setOpenSettingsDialog(false); // Close the dialog
    };

    // Handle prompt submission
    const handleSettingsSubmit = () => {
        let hasError = false;

        // Check if API keys are selected
        if (!data.apiKeys || data.apiKeys.length === 0) {
            setApiKeyError(true);
            hasError = true;
        } else {
            setApiKeyError(false);
        }

        // Check if engine is selected
        if (!data.engine) {
            setEngineError(true);
            hasError = true;
        } else {
            setEngineError(false);
        }

        // Check if prompt text is provided
        if (!data.promptText || data.promptText.trim() === "") {
            setPromptError(true);
            hasError = true;
        } else {
            setPromptError(false);
        }

        // If any error is found, prevent form submission
        if (hasError) return;

        // If validation passes, proceed with submission
        console.log(data);

        const profileForm = {
            modelNumber: data.modelNumber,
            modelName: data.modelName,
            modelmanufacturer: data.modelmanufacturer,
            modelTags: data.modelTags === "" ? "#" + data.modelName : data.modelTags,
            versionNumber: data.versionNumber,
            devImage: data.devImage === "" ? "null" : data.devImage,
            streamEnabled: data.streamEnabled === true ? "active" : "",
            vitalsEnabled: data.vitalsEnabled === true ? "active" : "",
            promptText: data.promptText,
            category: data.category,
            gateway: data.gateway,
            engine: data.engine,
            enginemodel: data.enginemodel,
            apiKeys: data.apiKeys,
        }

        var modelId = secureSessionStorage.getItem("devProfileIdtoEdit")
        ApiCalls.edit("updateDM/" + modelId, profileForm)
            .then(function (response) {
                console.log("Editing Model Details.....")
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    setOpenSettingsDialog(false);
                }
            }).catch((err) => {
                console.log(err);
            })
    };

    const handleAddProfileDetails = () => {
        console.log(data);
        if (!data.modelName) {
            setModelNameError(true);
        } else if (!data.modelNumber) {
            setModelNumberError(true);
        } else {
            setModelNameError(false);
            setModelNumberError(false);
            handleNext();
        }
    }

    const handleCancelDeviceProfile = () => {
        setRedrectToList(true);
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    if (redirectToList) {
        return (
            <Redirect to={'/deviceprofile'} />
        )
    }

    return (
        <Container sx={{ marginTop: 5, minWidth: 800 }}>
            <Box sx={{ width: 1 }}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                    <Box gridColumn="span 4">
                        <Item>
                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 10 }}>
                                <Avatar
                                    alt="Image"
                                    variant="rounded"
                                    src={data.devImage}
                                    sx={{
                                        marginLeft: 5,
                                        width: 150,
                                        height: 150,
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            transform: 'scale(1.1)',
                                        },
                                    }}
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                    onClick={() => document.getElementById('avatar-input').click()}
                                >
                                    <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                        {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="tabler:device-ipad-horizontal-cog" color="#4481eb" width="80" />}
                                        <div className="text-container">
                                            <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                        </div>
                                    </div>
                                </Avatar>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="avatar-input"
                                    onChange={onChangeX}
                                    style={{ display: 'none', }}
                                />
                            </Stack>
                        </Item>
                    </Box>
                    <Box gridColumn="span 8">
                        <Item>
                            <Stack
                                direction="column"
                                spacing={2}
                                justifyContent="space-between"
                            >
                                <TextField
                                    name="modelName"
                                    label={
                                        <span>
                                            Model Name{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    value={data.modelName}
                                    fullWidth
                                    error={modelNameError}
                                    helperText={modelNameError && "Profile Name is required"}
                                />
                                <TextField
                                    name="modelNumber"
                                    label={
                                        <span>
                                            Model Number{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    fullWidth
                                    value={data.modelNumber}
                                    onChange={handleChange}
                                    error={modelNumberError}
                                    helperText={modelNumberError && "Model Number is required"}
                                />
                                {/* Category Selection */}
                                <TextField
                                    select
                                    name="category"
                                    label="Category"
                                    value={data.category}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                >
                                    <MenuItem value="Patient Monitor">Patient Monitor</MenuItem>
                                    <MenuItem value="Ventilator">Ventilator</MenuItem>
                                    <MenuItem value="Infusion Pump">Infusion Pump</MenuItem>
                                    <MenuItem value="X-Ray">X-Ray</MenuItem>
                                </TextField>

                                {/* Gateway Selection */}
                                <TextField
                                    select
                                    name="gateway"
                                    label="Gateway"
                                    value={data.gateway}
                                    onChange={(e) => {
                                        setFormData({
                                            ...data,
                                            [e.target.name]: e.target.value,
                                        });
                                        if (e.target.value === "Vitals CAM")
                                            setOpenSettingsDialog(true)
                                    }}
                                    fullWidth
                                    variant="outlined"
                                >
                                    <MenuItem value="Vitals CAM">Vitals CAM</MenuItem>
                                    <MenuItem value="Vitals HUB">Vitals HUB</MenuItem>
                                    <MenuItem value="DICOM HUB">DICOM HUB</MenuItem>
                                    <MenuItem value="Desktop App">Desktop App</MenuItem>
                                </TextField>
                                <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                    <TextField
                                        name="versionNumber"
                                        label="Version"
                                        fullWidth
                                        value={data.versionNumber}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        name="modelTags"
                                        label="Tags"
                                        placeholder="#modelName"
                                        value={data.modelTags}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Stack>
                                <TextField
                                    name="modelmanufacturer"
                                    label="Manufacturer"
                                    fullWidth
                                    value={data.modelmanufacturer}
                                    onChange={handleChange}
                                />
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-around"
                                >
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={data.streamEnabled}
                                            name="streamEnabled"
                                            onChange={handleStreamEnabled}
                                        />}
                                        label="Enable Streaming"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={data.vitalsEnabled}
                                            name="vitalsEnabled"
                                            onChange={handleVitalsEnabled}
                                        />}
                                        label="Enable Vitals"
                                    />
                                </Stack>

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-around"
                                >
                                    <Button
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        color="error"
                                        variant="outlined"
                                        onClick={handleCancelDeviceProfile}
                                        onMouseDown={handleMouseDown}
                                        sx={{
                                            borderRadius: "12px",
                                        }}
                                        startIcon={
                                            <Iconify icon={"material-symbols:cancel-presentation"} />
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    {/* Add Settings Button - Only show if gateway is 'Vitals CAM' */}
                                    {data.gateway === "Vitals CAM" && (
                                        <Button
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            color="secondary"
                                            sx={{
                                                borderRadius: "12px",
                                            }}
                                            onClick={(e) => setOpenSettingsDialog(true)}
                                            onMouseDown={handleMouseDown}
                                            startIcon={<Iconify icon={'fluent:settings-16-regular'} />}
                                        >
                                            Settings
                                        </Button>
                                    )}
                                    <Button
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleAddProfileDetails}
                                        onMouseDown={handleMouseDown}
                                        sx={{
                                            borderRadius: "12px",
                                        }}
                                        endIcon={<Iconify icon={"ic:twotone-arrow-right"} />}
                                    >
                                        Next
                                    </Button>
                                </Stack>
                            </Stack>
                        </Item>
                    </Box>
                </Box>
            </Box>

            {/* Settings Dialog */}
            <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} fullWidth maxWidth="md">
                <DialogTitle>
                    Settings
                    {/* Close Icon Button */}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseSettingsDialog}
                        onMouseDown={handleMouseDown}
                        color="primary"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="space-between"
                        sx={{
                            padding: 5
                        }}
                    >
                        <TextField
                            select
                            name="engine"
                            label="Engine"
                            value={data.engine}
                            onChange={(e) => {
                                handleChange(e);
                                setEngineError(false); // Clear error on valid selection
                            }}
                            fullWidth
                            error={engineError}
                            helperText={engineError && "Please select an engine"}
                        >
                            <MenuItem value="Gemini">
                                <Icon icon={'simple-icons:googlegemini'} style={{ marginRight: '8px' }} />
                                <span>Gemini</span>
                            </MenuItem>
                        </TextField>

                        {data.engine === 'Gemini' && (
                            <TextField
                                name="enginemodel"
                                select
                                label="Model"
                                value={data.enginemodel}
                                onChange={handleChange}
                                fullWidth
                                sx={{ marginTop: 2 }}
                            >
                                <MenuItem value="gemini-1.5-pro-002">Gemini 1.5 Pro 002</MenuItem>
                                <MenuItem value="gemini-1.5-flash-002">Gemini 1.5 Flash 002</MenuItem>
                            </TextField>
                        )}

                        <FormControl fullWidth error={apiKeyError} variant="outlined">
                            <InputLabel id="multiple-select-label">
                                <span>
                                    API Keys <span style={{ color: 'red' }}>*</span>
                                </span>
                            </InputLabel>
                            <Select
                                labelId="multiple-select-label"
                                label="API Keys *"
                                name="apiKeys"
                                multiple
                                value={data.apiKeys || []} // Ensure value is always an array
                                onChange={(e) => {
                                    const value = e.target.value;

                                    // Ensure data.apiKeys is always an array
                                    const currentApiKeys = data.apiKeys || [];

                                    // Check the last selected item (to determine if it should be added or removed)
                                    const lastSelectedKey = value[value.length - 1];

                                    // Toggle selection: Add if not selected, remove if already selected
                                    const updatedApiKeys = currentApiKeys.some((key) => key.id === lastSelectedKey.id)
                                        ? currentApiKeys.filter((key) => key.id !== lastSelectedKey.id) // Remove deselected key
                                        : [...currentApiKeys, lastSelectedKey]; // Add newly selected key

                                    setFormData({
                                        ...data,
                                        apiKeys: updatedApiKeys,
                                    });

                                    setApiKeyError(updatedApiKeys.length === 0); // Show error if none selected
                                }}
                                renderValue={(selected) =>
                                    selected.map((s) => `${s.apiKey}`).join(", ") // Display selected API keys in the field
                                }
                            >
                                {apiKeys.map((option) => (
                                    <MenuItem key={option.id} value={option} disabled={!option.isActive}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={(data.apiKeys || []).some((s) => s.id === option.id)} // Match keys by ID
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${option.apiKey} (${option.description})`}
                                            sx={{
                                                color: option.isActive ? 'inherit' : 'text.disabled', // Gray out inactive options
                                            }}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                            {apiKeyError && (
                                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                    Please select at least one API key.
                                </Typography>
                            )}
                        </FormControl>

                        {/* Simple code editor with syntax highlighting */}
                        <Editor
                            value={data.promptText}
                            onValueChange={(code) => {
                                const syntheticEvent = {
                                    target: {
                                        name: "promptText",
                                        value: code
                                    }
                                };
                                handleChange(syntheticEvent); // Update the formData with the new promptText
                                setPromptError(false); // Clear error on valid input
                            }}
                            highlight={highlightWithPrism}
                            padding={10}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 15,
                                minHeight: '200px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                color: '#333',
                                backgroundColor: '#f9f9f9',
                                fontWeight: 545
                            }}
                        />
                        {promptError && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                Please provide a prompt text
                            </Typography>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSettingsSubmit}
                        onMouseDown={handleMouseDown}
                        color="primary"
                        variant="outlined"
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}

export default DeviceProfile
