import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// MUI components
import { Add, Delete, Edit, Home } from '@mui/icons-material';
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { blue, grey } from '@mui/material/colors';
import { emphasize, styled } from '@mui/material/styles';


//components
import { Icon } from '@iconify/react';
import Preview from '../../../layout/Preview';

// services
import ApiCalls from "../../../services/try";

//externals
import swal from 'sweetalert';

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

//-------------------------------------------------------------------------------

const KeySettings = () => {
    const [keys, setKeys] = useState([]);

    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [newApiKey, setNewApiKey] = useState('');

    const [selectedKey, setSelectedKey] = useState({ apiKey: '', usageCount: '', retryCount: '' });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Fetch all keys on component mount
    useEffect(() => {
        fetchKeys();
    }, []);

    const fetchKeys = async () => {
        setLoading(true);
        try {
            const response = await ApiCalls.getdetails('keys');
            console.log(response.data); // for debugging
            setKeys(response.data);
        } catch (error) {
            console.error('Failed to fetch keys', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleKeyStatus = async (id) => {
        try {
            await ApiCalls.patch(`keys/${id}/toggle`, {});
            fetchKeys(); // Refresh the list after toggling
        } catch (error) {
            console.error('Failed to toggle key status', error);
        }
    };

    const addNewKey = async () => {
        const request = {
            apiKey: newApiKey,
            isActive: true,
            usageCount: 0,
            lastUsed: false,
            retryCount: 0
        };
        try {
            const response = await ApiCalls.register('keys', request);
            swal("Success", "Key created successfully!", "success");
            setNewApiKey('')
            fetchKeys(); // Refresh the list after adding
        } catch (error) {
            console.error('Failed to add new key', error);
            swal("Error", "Failed to add new key", "error");
        }
    };

    const handleEditClick = async (id) => {
        try {
            const response = await ApiCalls.getdetails(`keys/${id}`);
            setSelectedKey(response.data);
            setOpenUpdateDialog(true);
        } catch (error) {
            console.error('Failed to fetch key details', error);
        }
    };

    const updateKeyDetails = async () => {
        try {
            await ApiCalls.edit(`keys/${selectedKey.id}`, selectedKey);
            swal("Updated", "Key updated successfully!", "success");
            setOpenUpdateDialog(false);
            fetchKeys(); // Refresh list
        } catch (error) {
            console.error('Failed to update key', error);
            swal("Error", "Failed to update key", "error");
        }
    };

    const deleteKey = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this key!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    await ApiCalls.delete(`keys/${id}`);
                    swal("Deleted", "Key deleted successfully", "success");
                    fetchKeys(); // Refresh the list after deletion
                } catch (error) {
                    console.error('Failed to delete key', error);
                    swal("Error", "Failed to delete key", "error");
                }
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewApiKey('');
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredKeys = keys.filter((key) =>
        key.apiKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
        key.usageCount.toLowerCase().includes(searchTerm.toLowerCase()) ||
        key.retryCount.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/settings">
                            <Button variant="text" startIcon={<Icon icon={"eva:settings-2-fill"} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Settings
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Key Management" />
                    </Breadcrumbs>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={handleDialogOpen}
                        onMouseDown={handleMouseDown}
                        sx={{ marginBottom: 2 }}
                    >
                        Add New Key
                    </Button>
                </Stack>

                <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm"> {/* Adjust maxWidth as needed */}
                    <DialogTitle>Add New API Key</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="API Key"
                            value={newApiKey}
                            onChange={(e) => setNewApiKey(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            sx={{
                                marginTop: 2
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} onMouseDown={handleMouseDown} color="error" variant="outlined">Cancel</Button>
                        <Button onClick={addNewKey} onMouseDown={handleMouseDown} color="primary" variant="outlined">Submit</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openUpdateDialog} onClose={() => setOpenUpdateDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Update API Key</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="API Key"
                            value={selectedKey.apiKey}
                            onChange={(e) => setSelectedKey({ ...selectedKey, apiKey: e.target.value })}
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                marginTop: 2
                            }}
                        />
                        <TextField
                            label="Usage Count"
                            type="number"
                            value={selectedKey.usageCount}
                            onChange={(e) => setSelectedKey({ ...selectedKey, usageCount: e.target.value })}
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            label="Retry Count"
                            type="number"
                            value={selectedKey.retryCount}
                            onChange={(e) => setSelectedKey({ ...selectedKey, retryCount: e.target.value })}
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 2 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onMouseDown={handleMouseDown} onClick={() => setOpenUpdateDialog(false)} color="error" variant="outlined">Cancel</Button>
                        <Button onMouseDown={handleMouseDown} onClick={updateKeyDetails} color="primary" variant="outlined">Save</Button>
                    </DialogActions>
                </Dialog>

                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h4" gutterBottom>
                                API Key Management
                            </Typography>
                        }
                        action={
                            <Stack direction="row" spacing={2} >
                                <TextField
                                    label="Search Key"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Stack>
                        }
                    />
                    <CardContent>
                        {loading ? (
                            <center><CircularProgress /></center>
                        ) : filteredKeys.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>API Key</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Usage Count</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Retry Count</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Status</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((key) => (
                                            <TableRow key={key.id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f4f4f4' } }}>
                                                <TableCell sx={{ textAlign: 'center' }}>{key.apiKey}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{key.usageCount}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{key.retryCount}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={key.isActive ? "Active" : "Inactive"}>
                                                        <Switch
                                                            checked={key.isActive} // Assuming `isActive` is a boolean field
                                                            onChange={() => toggleKeyStatus(key.id)}
                                                            color="primary"
                                                            sx={{
                                                                '& .MuiSwitch-thumb': {
                                                                    color: key.isActive ? 'green' : 'red',
                                                                },
                                                                '& .MuiSwitch-track': {
                                                                    bgcolor: key.isActive ? 'green' : 'red',
                                                                },
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    <IconButton color="primary" size="small" onMouseDown={handleMouseDown} onClick={() => handleEditClick(key.id)}>
                                                        <Edit fontSize="small" />
                                                    </IconButton>
                                                    <IconButton color="error" size="small" onMouseDown={handleMouseDown} onClick={() => deleteKey(key.id)}>
                                                        <Delete fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={keys.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                        '& .MuiTablePagination-toolbar': {
                                            alignItems: 'center',
                                        },
                                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'black', // Adjust this to your desired text color
                                        },
                                        '& .MuiTablePagination-select': {
                                            color: blue[700], // Adjust this to your desired text color
                                        },
                                        '& .MuiButtonBase-root': {
                                            outline: 'none',
                                            '&:focus': {
                                                outline: 'none',
                                                boxShadow: 'none',
                                            },
                                        },
                                        // Add these new styles for the icons
                                        '& .MuiSvgIcon-root': {
                                            color: blue[700], // Use a color that contrasts with your background
                                        },
                                        '& .Mui-disabled .MuiSvgIcon-root': {
                                            color: grey[400], // Use a lighter color for disabled state
                                        },
                                    }}
                                />
                            </TableContainer>


                        ) : (<center>No Keys Found</center>)}
                    </CardContent>
                </Card>

            </Container>

        </div>
    );
};

export default KeySettings;
