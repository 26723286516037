import { Icon } from "@iconify/react"; // Import Iconify
import React from "react";
import logo from "../../../assets/images/hilltop.jpg";
import "../styles/Header.css";

const Header = ({ patientData }) => {
  return (
    <header className="report-header">
      <div className="left-section">
        <img src={logo} alt="Clinic Logo" className="clinic-logo" />
        <div className="clinic-details">
          <h1 className="hospital-name">
            {patientData.hospitalname || "Hospital Name"}
          </h1>
          <p>{patientData.hospitaladdress}</p>{" "}
          {/* Display the formatted address */}
        </div>
      </div>
      <div className="right-section">
        <div className="contact-info">
          <p>
            <Icon icon={"mdi:phone"} className="icon" />{" "}
            {patientData.hospitalnumber || "123-456-7890"}
          </p>
          <p>
            <Icon icon={"mdi:email"} className="icon" />{" "}
            {patientData.hospitalemail || "info@hospital.com"}
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
