import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import {
    Close,
    Delete,
    DevicesOutlined,
    Edit,
    Home,
    ManageAccountsRounded,
    MonitorHeartOutlined,
} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//internals
import DeviceImg from "../../assets/images/devices.png";

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    gender: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    postalcode: "",
    country: "",
};

const bedInitialValues = {
    bedNo: "",
    wardNo: "",
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

// ----------------------------------------------------------------------


const ManageBed = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [beds, setBeds] = useState([]);
    const [selectedDevicestoAssign, setSelectedDevicetoAssign] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [devices, setDevices] = useState([]);
    const [devicesAssgined, setDevicesAssigned] = useState([]);
    const [assignedDeviceParameters, setAssignedDeviceParameters] = useState([]);
    const [selectedBedtoView, setSelectedBedtoView] = useState([]);
    const [selectedDevicetoDeselect, setSelectedDevicetoDeselect] = useState([]);
    const [selectedValueParamId, setSelectedValueParamId] = useState([]);

    const [openPatientBed, setOpenPatientBed] = useState(false);
    const [openNoPatientBed, setOpenNoPatientBed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadDevicesList, setLoadDevicesList] = useState(true);
    const [loadDeviceAssigned, setLoadDeviceAssigned] = useState(true);
    const [loadPatientAssigned, setLoadPatientAssigned] = useState(true);
    const [openAddBed, setOpenAddBed] = useState(false);
    const [openEditBed, setOpenEditBed] = useState(false);
    const [wardError, setWardError] = useState(false);
    const [bedError, setBedError] = useState(false);
    const [addressHide, setAddressHide] = useState(false);
    const [loadPatientsList, setLoadPatientsList] = useState(false);
    const [showValueParams, setShowValueParams] = useState(false);
    const [openAddMoreDevice, setOpenAddMoreDevice] = useState(false);
    const [openHighLowSetting, setOpenHighLowSetting] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);

    const [assignedPatientData, setAssignedPatientData] = useState(initialValues);

    const [patientBedTab, setPatientBedTab] = useState(0);
    const [noPatientBedTab, setNoPatientBedTab] = useState(0);
    const [tabvalue, setTabvalue] = useState(0);


    const [searchTerm, setSearchTerm] = useState('');
    const [wardName, setWardName] = useState('');
    const [bedNo, setBedNo] = useState('');
    const [patientImage, setPatientImage] = useState('');
    const [selectedStreamParamId, setSelectedStreamParamId] = useState('');
    const [selectedLabelDevMacid, setSelectedLabelDevMacid] = useState('');
    const [selectedLabelDeviceId, setSelectedLabelDeviceId] = useState('');
    const [bedToEdit, setBedToEdit] = useState('');
    const [selectedPatientToAssign, setSelectedPatientToAssign] = useState(null);


    const loadBedList = () => {
        let tenantId = secureSessionStorage.getItem("tenantId");
        let companyId = secureSessionStorage.getItem("compId");
        let rolename = secureSessionStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
            companyId = "0";
        } else if (rolename === "Company") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("UUID");
        }
        ApiCalls.getdetails(
            "tenant/" + tenantId + "/company/" + companyId + "/bedstatus?"
        )
            .then((result) => {
                console.log(result.data);
                if (result.data.beds !== undefined) {
                    // Create a map to store unique beds based on bedId
                    const uniqueBedsMap = new Map();

                    // Iterate through beds and update the map with the latest status for each bedId
                    result.data.beds.forEach((bed) => {
                        if (!uniqueBedsMap.has(bed.bedId) || bed.mapStatus === "active") {
                            uniqueBedsMap.set(bed.bedId, bed);
                        }
                    });

                    // Extract the values from the map to get the selected beds
                    const selectedBeds = Array.from(uniqueBedsMap.values());

                    console.log(selectedBeds);

                    setBeds(selectedBeds);
                    setLoading(false);
                } else {
                    setBeds([]);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setBeds([]);
                setLoading(false);
            });
    }

    const loadDeviceList = () => {
        var rolename = secureSessionStorage.getItem("rolename");
        var tenantId = secureSessionStorage.getItem("tenantId");
        var companyId = secureSessionStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureSessionStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("compId");
            physicianId = secureSessionStorage.getItem("UUID");
        }
        ApiCalls.getdetails(
            "PhysicianId/" + tenantId + "/company/" + companyId + "/showdeviceToAssign")
            .then((response) => {
                console.log(response.data);
                if (response.data.device !== undefined) {
                    console.log(response.data.device);
                    for (var i = 0; i < response.data.device.length; i++) {
                        setDevices(response.data.device);
                        setLoadDevicesList(false);
                    }
                } else {
                    setLoadDevicesList(false);
                    setDevices([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoadDevicesList(false);
                setDevices([]);
            })
    };

    useEffect(() => {
        loadBedList();
        loadDeviceList();
    }, []);

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const filteredBeds = beds.filter((bed) =>
        bed.wardNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        bed.bedNo.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleClickBed = (bedDetails) => {
        setSelectedDevicetoAssign([]);
        setSelectedBedtoView(bedDetails);
        if (bedDetails.mapStatus == "active") {
            setOpenPatientBed(true);
            loadPatientDetailsbyBed(bedDetails.patientId);
            loadDeviceDetailsbyBed(bedDetails.bedId);
            loadParameterDetailsbyBed(bedDetails.bedId);
        } else if (bedDetails.mapStatus == "vacant" || bedDetails.mapStatus == "inactive") {
            setOpenNoPatientBed(true);
            loadDeviceDetailsbyBed(bedDetails.bedId);
            loadPatientList();
            loadDeviceList();
        }
    }

    const loadPatientDetailsbyBed = (patientId) => {
        setLoadPatientAssigned(true);
        ApiCalls.getdetails("getpatient/" + patientId + "/patients")
            .then(function (response) {
                console.log(response.data);
                const data = response.data.patient[0];

                // Handle "null" string for email
                const email = data.email && data.email.toLowerCase() !== 'null' ? data.email : '';

                // Set patient data with nested address object
                setAssignedPatientData({
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    email: email,
                    dob: data.dob || '',
                    gender: data.gender || '',
                    phone: data.phone || '',
                    address: {
                        street: data.address.street || '',
                        city: data.address.city || '',
                        state: data.address.state || '',
                        postalcode: data.address.postalcode || '',
                        country: data.address.country || ''
                    }
                });

                setLoadPatientAssigned(false);

                // Hide address if all address fields are empty
                const addressFields = [
                    data.address.street,
                    data.address.postalcode,
                    data.address.city,
                    data.address.state,
                    data.address.country
                ];

                // Check if all address fields are empty or "null"
                const isAddressEmpty = addressFields.every(field =>
                    !field || field.trim() === '' || field.toLowerCase() === 'null'
                );

                setAddressHide(isAddressEmpty);
                setPatientImage(data.image || ''); // Default to an empty string if image is missing
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadDeviceDetailsbyBed = (bedId) => {
        setLoadDeviceAssigned(true);
        ApiCalls.getdetails("bed/" + bedId + "/getdeviceassigned")
            .then(function (response) {
                console.log(response.data);
                const data = response.data.device;
                if (data !== undefined) {
                    setDevicesAssigned(data);
                    setLoadDeviceAssigned(false);
                } else {
                    setDevicesAssigned([]);
                    setLoadDeviceAssigned(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setDevicesAssigned([]);
                setLoadDeviceAssigned(false);
            })
    }

    const loadParameterDetailsbyBed = (bedId) => {
        ApiCalls.getdetails("bed/" + bedId + "/getmeasurementparameter")
            .then(function (response) {
                console.log(response.data);
                if (response.data.beds !== undefined) {
                    const key = "paramId";
                    const uniqueParams = [...new Map(response.data.beds.map(item => [item[key], item])).values()];
                    console.log(uniqueParams);
                    setAssignedDeviceParameters(uniqueParams);
                } else {
                    setAssignedDeviceParameters([]);
                }
            })
            .catch((error) => {
                console.log(error);
                setAssignedDeviceParameters([]);
            });
    }

    const loadPatientList = () => {
        setLoadPatientsList(true);
        let companyId = secureSessionStorage.getItem("compId");
        let tenantId = secureSessionStorage.getItem("tenantId");
        let physicianId = "0";
        const rolename = secureSessionStorage.getItem("rolename");

        switch (rolename) {
            case "Tenant":
                tenantId = secureSessionStorage.getItem("UUID");
                companyId = secureSessionStorage.getItem("compId");
                physicianId = "0";
                break;
            case "Company":
                tenantId = secureSessionStorage.getItem("tenantId");
                companyId = secureSessionStorage.getItem("UUID");
                physicianId = "0";
                break;
            case "Physician":
                tenantId = secureSessionStorage.getItem("tenantId");
                companyId = secureSessionStorage.getItem("companyId");
                physicianId = secureSessionStorage.getItem("UUID");
                break;
            default:
                break;
        }

        ApiCalls.getdetails(
            "organizations/0/tenants/" +
            tenantId +
            "/companies/" +
            companyId +
            "/physicians/" +
            physicianId +
            "/patients?"
        )
            .then((result) => {
                console.log(result.data);
                if (result.data.patient !== undefined) {
                    for (let i = 0; i < result.data.patient.length; i++) {
                        setPatientList(result.data.patient);
                        setLoadPatientsList(false);
                    }
                } else {
                    setPatientList([]);
                    setLoadPatientsList(false);
                }
            })
            .catch((error) => {
                console.log("error: " + error);
                setPatientList([]);
                setLoadPatientsList(false);
            });
    }

    const handleClosePatientBed = () => {
        setOpenPatientBed(false);
        setSelectedValueParamId([]);
        setSelectedBedtoView([]);
        setSelectedPatientToAssign(null);
        setDevicesAssigned([]);
        loadBedList();
    };

    const handleCloseNoPatientBed = () => {
        setOpenNoPatientBed(false);
        setSelectedPatientToAssign(null);
        setSelectedBedtoView([]);
        setDevicesAssigned([]);
        loadBedList();
    };

    const handleChangePatientBedTab = (event, newValue) => {
        setPatientBedTab(newValue);
    };

    const handleChangeNoPatientBedTab = (event, newValue) => {
        setNoPatientBedTab(newValue);
    };

    const handleDeleteBed = (bed) => {
        var bedId = bed.bedId;
        var status = bed.mapStatus;
        console.log(bedId + "**" + status);

        if (status === "active") {
            swal({
                title: `Cannot Delete`,
                text: `This Bed cannot be deleted at the moment because a patient is admitted.`,
                icon: "warning",
                buttons: {
                    ok: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "btn-danger",
                        closeModal: true,
                    },
                },
                dangerMode: true,
            });
        } else {
            swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    ApiCalls.delete("bed/" + bedId + "/deletebed")
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.status.message === "Success") {
                                swal("Deleted Successfully!", {
                                    icon: "success",
                                });
                                loadBedList();
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                }
            });
        }
    }

    const handleOpenEditBed = (bedId) => {
        ApiCalls.getdetails("bed/" + bedId + "/bedById")
            .then((response) => {
                console.log(response.data);
                if (response.data.beds != undefined) {
                    setWardName(response.data.beds[0].wardNo);
                    setBedNo(response.data.beds[0].bedNo);
                }
            })
        setBedToEdit(bedId);
        setOpenEditBed(true);
    }

    const handleCloseEditBed = () => {
        setOpenEditBed(false);
        loadBedList();
    }

    const handleOpenAddBed = () => {
        setOpenAddBed(true);
    }

    const handleCloseAddBed = () => {
        setOpenAddBed(false);
        loadBedList();
        setWardError(false);
        setBedError(false);
    }

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'wardName':
                    setWardError(true);
                    break;
                case 'bedNo':
                    setBedError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'wardName':
                setWardError(false);
                setWardName(event.target.value);
                break;
            case 'bedNo':
                setBedError(false);
                setBedNo(event.target.value);
                break;

            default:
                break;
        }
    };

    const handleSelectDevicetoAssign = (event, devMacId) => {
        const deviceId = event.target.value;
        if (event.target.checked) {
            setSelectedDevicetoAssign([...selectedDevicestoAssign, { deviceId, devMacId }]);
        } else {
            setSelectedDevicetoAssign(selectedDevicestoAssign.filter(device => device.deviceId !== deviceId));
        }
    };

    const handleSelectDevice = (event, id, name) => {
        if (event.target.checked) {
            setSelectedDevicetoAssign([...selectedDevicestoAssign, { id, name }]);
        } else {
            setSelectedDevicetoAssign(selectedDevicestoAssign.filter(item => item.id !== id));
        }
    }

    const handleAddBed = () => {
        const errors = {};

        // Collect validation errors
        if (!wardName) {
            errors.wardError = true;
        }
        if (!bedNo) {
            errors.bedError = true;
        }

        // Set all errors at once if any are found
        if (Object.keys(errors).length > 0) {
            setWardError(errors.wardError || false);
            setBedError(errors.bedError || false);
            return; // Stop further execution if there are errors
        } else {
            let tenantId = secureSessionStorage.getItem("tenantId");
            let companyId = secureSessionStorage.getItem("compId");
            let rolename = secureSessionStorage.getItem("rolename");
            if (rolename === "Tenant") {
                tenantId = secureSessionStorage.getItem("UUID");
                companyId = "0";
            } else if (rolename === "Company") {
                tenantId = secureSessionStorage.getItem("tenantId");
                companyId = secureSessionStorage.getItem("UUID");
            }

            var a = [];
            var list = selectedDevicestoAssign.length;
            for (var i = 0; i < list; i++) {
                var counter = selectedDevicestoAssign[i];
                const deviceuuid = counter.id;
                const devicemacId = counter.macid;
                var s = { deviceuuid: deviceuuid, devmacId: devicemacId }
                a.push(s);
            }
            var data = {
                wardNo: wardName,
                bedNo: bedNo,
                devices: a
            }

            ApiCalls.register("tenant/" + tenantId + "/company/" + companyId + "/beds", data, a)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Bed Successfully Registered", {
                            icon: "success",
                        });
                        setSelectedDevicetoAssign([]);
                        setAssignedPatientData(initialValues);
                        handleCloseAddBed();
                    } else if (response.data.status.details === "Device Already assigned to a bed") {
                        swal("Device Already assigned to a bed", {
                            icon: "info",
                        });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    if (error.message === "Request failed with status code 409") {
                        swal("Bed Already Registered", {
                            icon: "info",
                        });
                    }
                })
        }
    }

    const handleOpenAddMoreDevice = () => {
        setOpenAddMoreDevice(true);
    }

    const handleCloseAddMoreDevice = () => {
        setOpenAddMoreDevice(false);
        loadDeviceDetailsbyBed(selectedBedtoView.bedId);
    }

    const handleSelectPatientToAssign = (event) => {
        const patientId = event.target.value;
        if (patientId === selectedPatientToAssign) {
            // User is unchecking the checkbox
            setSelectedPatientToAssign(null);
        } else {
            // User is checking a different checkbox
            setSelectedPatientToAssign(patientId);
        }
    }

    const handleAssignPatientToBed = () => {

        if (selectedPatientToAssign == " " || selectedPatientToAssign == undefined) {
            swal({
                title: "warning",
                text: "Select A Patient",
                icon: "info"
            })
        } else {
            let data = {
                patientId: selectedPatientToAssign,
                wardNo: selectedBedtoView.wardNo,
                bedNo: selectedBedtoView.bedNo,
            };

            ApiCalls.register("bed/" + selectedBedtoView.bedId + "/patient/assign", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal((secureSessionStorage.getItem("PatientName") + " Assigned"), {
                            icon: "success",
                        });
                        handleCloseNoPatientBed();
                    } else if (response.data.status.details === "A device of the same category is already assigned to this bed") {
                        swal("A " + (secureSessionStorage.getItem("DeviceName") + " of the same category is already assigned to this bed"), {
                            icon: "warning",
                        });
                        handleCloseNoPatientBed();
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    if (error.message === "Request failed with status code 409") {
                        swal((secureSessionStorage.getItem("PatientName") + " Already Assigned"), {
                            icon: "warning",
                        });
                    }
                });
        }
    }

    const handleDischargePatient = () => {
        let bedId = selectedBedtoView.bedId;
        let patientId = selectedBedtoView.patientId;

        swal({
            title: "Are you sure?",
            text: "Do you want to discharge this Patient?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                ApiCalls.delete(
                    "bed/" + bedId + "/patient/" + patientId + "/dischargePatient")
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            setAssignedPatientData(initialValues);
                            swal(secureSessionStorage.getItem("PatientName") + " Discharged!", {
                                icon: "success",
                            });
                            handleClosePatientBed();

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        });

    }

    const handleAssignDeviceToBed = () => {

        let bedId = selectedBedtoView.bedId;
        var a = [];
        var list = selectedDevicestoAssign.length;
        for (var i = 0; i < list; i++) {
            var counter = selectedDevicestoAssign[i];
            const deviceuuid = counter.id;
            const devicemacId = counter.macid;
            var s = { deviceuuid: deviceuuid, devmacId: devicemacId };
            a.push(s);
        }
        var data = {
            devices: a,
        };


        if (selectedDevicestoAssign.length > 0) {
            ApiCalls.register("bed/" + bedId + "/device/assign", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal((secureSessionStorage.getItem("DeviceName") + " Assigned"), {
                            icon: "success",
                        });
                        handleCloseAddMoreDevice(bedId);
                        loadParameterDetailsbyBed(bedId);
                        loadDeviceList();
                    } else if (
                        response.data.status.details === "Device Already Assigned To Bed"
                    ) {
                        swal((secureSessionStorage.getItem("DeviceName") + " Already Assigned"), {
                            icon: "warning",
                        });
                    } else if (response.data.status.details === "A device of the same category is already assigned to this bed") {
                        swal("A " + (secureSessionStorage.getItem("DeviceName") + " of the same category is already assigned to this bed"), {
                            icon: "warning",
                        });
                        handleCloseAddMoreDevice(bedId);
                        loadParameterDetailsbyBed(bedId);
                        loadDeviceList();
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } else {
            swal({
                title: "warning",
                text: "Select A Device",
                icon: "info"
            })
        }
    }

    const handleDeselectDeviceFromBed = () => {
        let bedId = selectedBedtoView.bedId;
        var a = [];
        var list = selectedDevicetoDeselect.length;
        for (var i = 0; i < list; i++) {
            var counter = selectedDevicetoDeselect[i];
            const deviceuuid = counter.id;
            const devicemacId = counter.macid;
            var s = { deviceuuid: deviceuuid, devmacId: devicemacId };
            a.push(s);
        }
        var data = {
            devices: a,
        };

        swal({
            title: "Are you sure?",
            text: "Do you want to Deselect this?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                ApiCalls.edit("bed/" + bedId + "/deselectDevice", data)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Device Deselected!", {
                                icon: "success",
                            });
                            loadDeviceDetailsbyBed(bedId);
                            loadParameterDetailsbyBed(bedId);
                            loadBedList();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }

    const handleExpandLabelParameter = (paramId, deviceId) => {
        setSelectedStreamParamId(prev => prev === paramId ? null : paramId);
        setSelectedLabelDeviceId(deviceId);
    }

    const handlePairParameters = () => {
        let a = [];
        for (let i = 0; i < selectedValueParamId.length; i++) {
            const paramId = selectedValueParamId[i];
            let s = { paramId: paramId };
            a.push(s);
            console.log("s: " + JSON.stringify(s));
        }
        let data = {
            devmacid: selectedLabelDevMacid,
            streamParamId: selectedStreamParamId,
            labelParameters: a,
        };

        console.log(data);
        ApiCalls.register("device/" + selectedLabelDeviceId + "/pairparameters", data)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status.message === "Success") {
                    swal({
                        title: "Success",
                        text: response.data.status.details,
                        icon: "success"
                    })
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const handleOpenSettingHighLow = (parameter) => {
        setOpenHighLowSetting(true);
    }

    const handleToggleEdit = () => {
        setIsEditable(!isEditable);
        setIsEditing(true);
    };

    const handleInputClick = () => {
        if (isEditable) {
            setIsEditing(true);
        }
    };

    const handleBlurHighLow = () => {
        setIsEditing(false);
    };

    const handleUpdateHighLowValue = (parameterData) => {

        console.log(parameterData);

        if (parameterData.minValue === " " || parameterData.maxValue === " ") {
            //
        } else {
            let data = {
                name: parameterData.paramName,
                type: 1,
                min: parameterData.minValue,
                max: parameterData.maxValue,
                valueType: 1
            }
            ApiCalls.edit("paramId/" + parameterData.paramId + "/parameter/" + parameterData.paramName + "/bedId/" + parameterData.bedId + "/patientId/" + parameterData.patientId + "/updateHighLowValue", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Updated Successfully!", {
                            icon: "success",
                        });
                    }
                }).catch((error) => {
                    console.log("Error: " + error.message);
                })
        }

    }

    const handleUpdateBed = () => {
        if (!wardName) {
            setWardError(true);
        } else if (!bedNo) {
            setBedError(true);
        } else {
            const data = {
                wardNo: wardName,
                bedNo: bedNo,
            };

            ApiCalls.edit("bed/" + bedToEdit + "/updatebed", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Bed Successfully Updated", {
                            icon: "success",
                        });
                        handleCloseEditBed();
                    }
                })
        }
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Manage Bed" />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureSessionStorage.getItem("rolename") === "Company" ? "" : "none"
                    }} variant="contained" onMouseDown={handleMouseDown} onClick={handleOpenAddBed} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Bed
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title="Bed List"
                        action={
                            <TextField
                                label="Search Bed"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        <Box sx={{
                            float: 'right',
                        }}>
                            <Typography variant="subtitle2">
                                <Icon icon="tabler:bed-filled" color="#229A16" width="30" /> {" "} Admitted
                            </Typography>
                            <Typography variant="subtitle2">
                                <Icon icon="tabler:bed-off" width="30" />{" "} Vacant
                            </Typography>
                        </Box>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredBeds.length > 0 ? (
                                <Grid container spacing={2}>
                                    {filteredBeds.map((bed) => (
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Card sx={{
                                                maxWidth: 200,
                                                boxshadow: 3,
                                                '&:hover': {
                                                    border: '2px solid #D1E9FC',
                                                    boxShadow: 'none'
                                                },
                                                backgroundColor: bed.mapStatus === 'active' ? "#AFE1AF" : ""
                                            }}>
                                                <CardContent
                                                    sx={{
                                                        cursor: secureSessionStorage.getItem("roleId") == 4 ? "pointer" : "default"
                                                        // cursor: "pointer"
                                                    }}
                                                    // onClick={() => handleClickBed(bed)}
                                                    onClick={() => {
                                                        if (secureSessionStorage.getItem("roleId") == 4) {
                                                            handleClickBed(bed);
                                                        }
                                                    }}

                                                >
                                                    <Box sx={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                        {
                                                            bed.mapStatus === 'active' ?
                                                                <Icon icon="tabler:bed-filled" color="#229A16" width="80" /> :
                                                                <Icon icon="tabler:bed-off" width="70" />
                                                        }
                                                    </Box>
                                                    <Divider />
                                                    <Box sx={{
                                                        mt: 2,
                                                        alignItems: 'center',
                                                    }}>
                                                        <center>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                <Iconify icon={'material-symbols:ward-rounded'} /> {" "} : {" "} {bed.wardNo}
                                                            </Typography>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                <Iconify icon={'icon-park-outline:hospital-bed'} /> {" "} : {" "} {bed.bedNo}
                                                            </Typography>
                                                        </center>
                                                    </Box>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onMouseDown={handleMouseDown}
                                                        onClick={() => {
                                                            if (secureSessionStorage.getItem("roleId") == 4) {
                                                                handleOpenEditBed(bed.bedId);
                                                            }
                                                        }}
                                                        fullWidth
                                                        startIcon={<Edit />}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        size="small"
                                                        onMouseDown={handleMouseDown}
                                                        onClick={() => {
                                                            if (secureSessionStorage.getItem("roleId") == 4) {
                                                                handleDeleteBed(bed);
                                                            }
                                                        }}
                                                        fullWidth
                                                        startIcon={<Delete />}
                                                    >
                                                        Delete
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <center>
                                    No Bed Found
                                </center>
                            )
                        }

                    </CardContent>
                </Card>
            </Container>

            <div>
                <Dialog open={openAddBed} onClose={handleCloseAddBed} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Add Bed
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddBed}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                <TextField
                                    name="wardName"
                                    label={
                                        <span>
                                            Ward Name{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon icon={'material-symbols:room-preferences'} width="30" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={wardError}
                                    helperText={wardError && "Ward Name is required"}
                                />
                                <TextField
                                    name="bedNo"
                                    label={
                                        <span>
                                            Bed No{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon icon={'carbon:hospital-bed'} width="30" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={bedError}
                                    helperText={bedError && "Bed Number is required"}
                                />
                                <Grid container spacing={3} sx={{
                                    overflowY: 'scroll',
                                    maxHeight: 250
                                }}>
                                    {
                                        loadDevicesList ?
                                            <center>
                                                <CircularProgress />
                                            </center>
                                            : devices.length > 0 ?
                                                devices.map((item) => (
                                                    <Grid item xs={3} sm={3} md={4}>
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type="checkbox"
                                                                    name={item.devicename}
                                                                    value={item.deviceuuid}
                                                                    id={item.deviceuuid}
                                                                    onChange={(e) => handleSelectDevice(e, item.deviceuuid, item.decmacid, item.devicename)}
                                                                />
                                                                <label htmlFor={item.deviceuuid} style={{ marginLeft: '10px' }}>
                                                                    <Avatar variant='rounded' alt={item.devicename} src={item.deviceimageName ? item.deviceimageName : DeviceImg}
                                                                        sx={{ width: 100, height: 100, objectFit: "contain" }} />
                                                                </label>
                                                                <span class="checkbox-label font-weight-bold text-muted">{item.devicename}</span>
                                                            </li>
                                                        </ul>
                                                    </Grid>
                                                ))
                                                : <center>
                                                    {"No " + secureSessionStorage.getItem("DeviceName") + " found"}
                                                </center>
                                    }

                                </Grid>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseAddBed} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAddBed} fullWidth >Submit</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openEditBed} onClose={handleCloseEditBed} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Update Bed
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseEditBed}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                <TextField
                                    name="wardName"
                                    label={
                                        <span>
                                            Ward Name{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={wardName}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon icon={'material-symbols:room-preferences'} width="30" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={wardError}
                                    helperText={wardError && "Ward Name is required"}
                                />
                                <TextField
                                    name="bedNo"
                                    label={
                                        <span>
                                            Bed No{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    value={bedNo}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon icon={'carbon:hospital-bed'} width="30" />oo
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={bedError}
                                    helperText={bedError && "Bed Number is required"}
                                />
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseEditBed} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleUpdateBed} fullWidth >Submit</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openPatientBed} onClose={handleClosePatientBed} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 600
                    }}>
                        <CardHeader
                            title={<Tabs
                                value={patientBedTab}
                                onChange={handleChangePatientBedTab}
                                onMouseDown={handleMouseDown}
                                aria-label="icon label tabs example"
                            >
                                <Tab
                                    icon={<ManageAccountsRounded />}
                                    label={secureSessionStorage.getItem("PatientName") + " List"}
                                    sx={{
                                        fontSize: 18,
                                    }}
                                />
                                <Tab
                                    icon={<DevicesOutlined />}
                                    label={secureSessionStorage.getItem("DeviceName") + " Details"}
                                    wrapped
                                    sx={{
                                        fontSize: 18,
                                    }}
                                />
                                <Tab
                                    icon={<MonitorHeartOutlined />}
                                    label="Parameters"
                                    wrapped
                                    sx={{
                                        fontSize: 18,
                                    }}
                                />
                            </Tabs>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePatientBed}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <TabPanel value={patientBedTab} index={0}>
                                {loadPatientAssigned ?
                                    <>
                                        <CardHeader
                                            avatar={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Skeleton
                                                        variant="rounded"
                                                        sx={{
                                                            width: 150,
                                                            height: 150
                                                        }}
                                                    />
                                                    <Box sx={{ ml: 5 }}>
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 120 }}
                                                        />
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 120 }}
                                                        />
                                                    </Box>
                                                </Box>
                                            }
                                        />

                                        <CardContent>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        DOB
                                                    </Typography>
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{ fontSize: "1rem", width: 100 }}
                                                    />
                                                </Box>
                                                <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        Gender
                                                    </Typography>
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{ fontSize: "1rem", width: 100 }}
                                                    />
                                                </Box>
                                                <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        Phone
                                                    </Typography>
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{ fontSize: "1rem", width: 100 }}
                                                    />
                                                </Box>


                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        Location
                                                    </Typography>
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{ fontSize: "1rem", width: 100 }}
                                                    />
                                                </Box>
                                            </Box>

                                        </CardContent>
                                    </> :
                                    <>
                                        <CardHeader
                                            avatar={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Avatar
                                                        variant='rounded'
                                                        alt={`${assignedPatientData?.firstName} ${assignedPatientData?.lastName}`}
                                                        src={patientImage}
                                                        sx={{ width: 150, height: 150 }}
                                                    />
                                                    <Box sx={{ ml: 5 }}>
                                                        <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                                            {assignedPatientData?.firstName && assignedPatientData?.lastName
                                                                ? `${assignedPatientData.firstName} ${assignedPatientData.lastName}`
                                                                : assignedPatientData?.firstName || assignedPatientData?.lastName || 'No Name'}
                                                        </Typography>
                                                        {assignedPatientData.email &&
                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                {`${assignedPatientData?.email}`}
                                                            </Typography>}

                                                    </Box>
                                                </Box>
                                            }
                                        />

                                        <CardContent>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {assignedPatientData?.dob && (
                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            DOB
                                                        </Typography>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                            {new Date(assignedPatientData.dob).toISOString().split('T')[0]}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {assignedPatientData?.gender && (
                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            Gender
                                                        </Typography>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                            {assignedPatientData.gender}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {assignedPatientData?.phone && (
                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            Phone
                                                        </Typography>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                            {assignedPatientData.phone}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        Location
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                                        {assignedPatientData.address && Object.keys(assignedPatientData.address).length > 0 ? (
                                                            <>
                                                                {assignedPatientData.address.city && assignedPatientData.address.state && assignedPatientData.address.country ? (
                                                                    ` ${assignedPatientData.address.state}, ${assignedPatientData.address.country}`
                                                                ) : (
                                                                    <>
                                                                        {assignedPatientData.address.city && assignedPatientData.address.state ? (
                                                                            `${assignedPatientData.address.city}, ${assignedPatientData.address.state}`
                                                                        ) : (
                                                                            <>
                                                                                {assignedPatientData.address.city || ""}
                                                                                {assignedPatientData.address.city && assignedPatientData.address.state && assignedPatientData.address.country ? ", " : ""}
                                                                                {assignedPatientData.address.state || ""}
                                                                                {assignedPatientData.address.state && assignedPatientData.address.country ? ", " : ""}
                                                                                {assignedPatientData.address.country || ""}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            "Not Provided"
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </CardContent>
                                        <CardActions>
                                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleClosePatientBed} fullWidth >Cancel</Button>
                                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleDischargePatient} fullWidth >Discharge</Button>
                                        </CardActions>
                                    </>
                                }
                            </TabPanel>

                            <TabPanel value={patientBedTab} index={1} >
                                <CardContent sx={{
                                    maxHeight: 350,
                                    overflowY: "scroll"
                                }}>
                                    <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                        {
                                            loadDeviceAssigned ?
                                                <>
                                                    <CardHeader
                                                        avatar={
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Skeleton
                                                                    variant="rounded"
                                                                    sx={{
                                                                        width: 150,
                                                                        height: 150
                                                                    }}
                                                                />
                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                            Device Name
                                                                        </Typography>
                                                                        <Skeleton
                                                                            variant="text"
                                                                            sx={{ fontSize: "1rem", width: 100 }}
                                                                        />
                                                                    </Box>
                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                            Device Macid
                                                                        </Typography>
                                                                        <Skeleton
                                                                            variant="text"
                                                                            sx={{ fontSize: "1rem", width: 100 }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        }
                                                    />

                                                    <CardContent>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            maxHeight: 400,
                                                            overflowY: "scroll"
                                                        }}>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Model Name
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Model Number
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Version Number
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </> :
                                                devicesAssgined.length > 0 ?
                                                    devicesAssgined.map((device) => (
                                                        <>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name={device.devicename}
                                                                        value={device.deviceuuid}
                                                                        id={device.deviceuuid}
                                                                        checked={selectedDevicetoDeselect.some(
                                                                            (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                                        )}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            const deviceId = device.deviceuuid;
                                                                            const deviceName = device.devicename;
                                                                            const deviceMacId = device.decmacid;
                                                                            if (checked) {
                                                                                setSelectedDevicetoDeselect([
                                                                                    ...selectedDevicetoDeselect,
                                                                                    { id: deviceId, name: deviceName, macid: deviceMacId },
                                                                                ]);
                                                                            } else {
                                                                                setSelectedDevicetoDeselect((prevState) =>
                                                                                    prevState.filter(
                                                                                        (d) => d.id !== deviceId || d.macid !== deviceMacId
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                label={
                                                                    <>
                                                                        <CardHeader
                                                                            avatar={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Avatar
                                                                                    variant='rounded'
                                                                                    alt={device.devicename}
                                                                                    src={device.deviceimageName ? device.deviceimageName : DeviceImg}
                                                                                    sx={{ width: 120, height: 120, objectFit: "contain" }} />
                                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                            Device Name
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                            {device.devicename}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                            Device Macid
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                            {device.decmacid}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                            } />
                                                                        <CardContent>
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                        Model Name
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                        {device.modelName == undefined
                                                                                            ? " "
                                                                                            : device.modelName}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                        Model Number
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                        {device.modelNumber == undefined
                                                                                            ? "Not Provided"
                                                                                            : device.modelNumber}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                        Version Number
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                        {device.version === undefined
                                                                                            ? " "
                                                                                            : device.version}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </CardContent></>
                                                                }
                                                            />

                                                            <Divider />
                                                        </>
                                                    )) :
                                                    <center>
                                                        {"No " + secureSessionStorage.getItem("DeviceName") + " Assigned"}
                                                    </center>
                                        }
                                    </Stack>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleDeselectDeviceFromBed} disabled={!selectedDevicetoDeselect.length > 0 || !devicesAssgined.length > 0} fullWidth >Remove</Button>
                                    <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleOpenAddMoreDevice} fullWidth >Add More</Button>
                                </CardActions>
                            </TabPanel>

                            <TabPanel value={patientBedTab} index={2}>
                                {assignedDeviceParameters.length > 0 ? (
                                    <>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    maxHeight: 300,
                                                    overflowY: "scroll"
                                                }}
                                            >
                                                {/* Stream Parameters Section */}
                                                <Typography variant="h6" gutterBottom>
                                                    Stream Parameters
                                                </Typography>
                                                {assignedDeviceParameters.filter((parameter) => parameter.measureTypename === "stream").length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        {assignedDeviceParameters
                                                            .filter((parameter) => parameter.measureTypename === "stream")
                                                            .map((labelParameter) => (
                                                                <Grid item xs={12} sm={6} key={labelParameter.paramId}>
                                                                    <ListItem>
                                                                        <ListItemButton sx={{ pl: 3 }}>
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    checked={selectedStreamParamId === labelParameter.paramId}
                                                                                    onChange={() => {
                                                                                        if (selectedStreamParamId === labelParameter.paramId) {
                                                                                            // Deselect if clicked again
                                                                                            setSelectedStreamParamId(null);
                                                                                        } else {
                                                                                            // Select new stream parameter and deselect the previous one
                                                                                            setSelectedStreamParamId(labelParameter.paramId);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={labelParameter.paramName} />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                </Grid>
                                                            ))}
                                                    </Grid>
                                                ) : (
                                                    <Typography variant="body1" color="textSecondary" align="center">
                                                        - -
                                                    </Typography>
                                                )}

                                                {/* Value Parameters Section */}
                                                <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                                                    Vitals Parameters
                                                </Typography>
                                                {assignedDeviceParameters.filter((parameter) => parameter.measureTypename === "value").length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        {assignedDeviceParameters
                                                            .filter((parameter) => parameter.measureTypename === "value")
                                                            .map((valueParameter) => (
                                                                <Grid item xs={12} sm={6} key={valueParameter.paramId}>
                                                                    <ListItem>
                                                                        <ListItemButton sx={{ pl: 3 }}>
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    checked={selectedValueParamId.includes(valueParameter.paramId)}
                                                                                    onChange={() => {
                                                                                        setSelectedLabelDevMacid(valueParameter.devMacId);
                                                                                        if (selectedValueParamId.includes(valueParameter.paramId)) {
                                                                                            setSelectedValueParamId(selectedValueParamId.filter(id => id !== valueParameter.paramId));
                                                                                        } else {
                                                                                            setSelectedValueParamId([...selectedValueParamId, valueParameter.paramId]);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={valueParameter.paramName} />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                </Grid>
                                                            ))}
                                                    </Grid>
                                                ) : (
                                                    <Typography variant="body1" color="textSecondary" align="center">
                                                        - -
                                                    </Typography>
                                                )}
                                            </Box>
                                        </CardContent>

                                        <CardActions>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                fullWidth
                                                onClick={handleClosePatientBed}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                fullWidth
                                                disabled={!selectedStreamParamId || selectedValueParamId.length === 0}
                                                onClick={handlePairParameters}
                                            >
                                                Pair
                                            </Button>
                                        </CardActions>
                                    </>
                                ) : (
                                    <center>No Parameter Found</center>
                                )}
                            </TabPanel>
                        </CardContent>

                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openNoPatientBed} onClose={handleCloseNoPatientBed} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={<Tabs
                                value={noPatientBedTab}
                                onChange={handleChangeNoPatientBedTab}
                                onMouseDown={handleMouseDown}
                                aria-label="icon label tabs example"
                            >
                                <Tab
                                    icon={<ManageAccountsRounded />}
                                    label={secureSessionStorage.getItem("PatientName") + " List"}
                                    sx={{
                                        fontSize: 18,
                                    }}
                                />
                                <Tab
                                    icon={<DevicesOutlined />}
                                    label={secureSessionStorage.getItem("DeviceName") + " Details"}
                                    wrapped
                                    sx={{
                                        fontSize: 18,
                                    }}
                                />
                            </Tabs>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseNoPatientBed}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <TabPanel value={noPatientBedTab} index={0}>
                                {
                                    loadPatientsList ?
                                        <>
                                            <CardHeader
                                                avatar={
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Skeleton
                                                            variant="circular"
                                                            sx={{
                                                                width: 80,
                                                                height: 80
                                                            }}
                                                        />
                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                                />
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                                />
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                                />
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                } />
                                        </> :
                                        patientList.length > 0 ?
                                            <>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    minHeight: 300,
                                                    maxHeight: 350,
                                                    overflowY: "scroll",
                                                    p: 0,
                                                    margin: 0,
                                                }} >
                                                    {patientList
                                                        .map((patient) => (
                                                            <FormControlLabel
                                                                key={patient.patientuuid}
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedPatientToAssign === patient.patientuuid}
                                                                        value={patient.patientuuid}
                                                                        onChange={handleSelectPatientToAssign}
                                                                    />}
                                                                label={<>
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Avatar
                                                                                    alt={patient.firstName + " " + patient.lastName}
                                                                                    src={patient.image}
                                                                                    sx={{ width: 80, height: 80, objectFit: "contain" }}
                                                                                />
                                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                            {(patient.firstName || '') + (patient.lastName ? ` ${patient.lastName}` : '')}
                                                                                        </Typography>
                                                                                        {patient.email && (
                                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                                <Icon icon={'gridicons:mail'} /> {patient.email}
                                                                                            </Typography>
                                                                                        )}
                                                                                        {patient.gender && (
                                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                                <Icon icon={'ph:gender-intersex-fill'} /> {patient.gender}
                                                                                            </Typography>
                                                                                        )}
                                                                                        {patient.phone && (
                                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                                <Icon icon={'ic:baseline-phone'} /> {patient.phone}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        }
                                                                    />
                                                                </>}
                                                            />
                                                        ))}
                                                </Box>
                                            </>
                                            : <center>
                                                No {secureSessionStorage.getItem("PatientName")} Found
                                            </center>
                                }
                                <CardActions sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 2,
                                    mb: 2,
                                    width: '100%',

                                    '@media screen and (max-width: 600px)': {
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'stretch',
                                        mt: 2,
                                        mb: 2,
                                    },
                                }}>
                                    <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseNoPatientBed} fullWidth >Cancel</Button>
                                    <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAssignPatientToBed} fullWidth >Submit</Button>
                                </CardActions>
                            </TabPanel>

                            <TabPanel value={noPatientBedTab} index={1}>
                                <CardContent sx={{
                                    maxHeight: 350,
                                    overflowY: "scroll"
                                }}>
                                    <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                        {
                                            loadDeviceAssigned ?
                                                <>
                                                    <CardHeader
                                                        avatar={
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Skeleton
                                                                    variant="rounded"
                                                                    sx={{
                                                                        width: 150,
                                                                        height: 150
                                                                    }}
                                                                />
                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                            Device Name
                                                                        </Typography>
                                                                        <Skeleton
                                                                            variant="text"
                                                                            sx={{ fontSize: "1rem", width: 100 }}
                                                                        />
                                                                    </Box>
                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                            Device Macid
                                                                        </Typography>
                                                                        <Skeleton
                                                                            variant="text"
                                                                            sx={{ fontSize: "1rem", width: 100 }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        }
                                                    />

                                                    <CardContent>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            maxHeight: 400,
                                                            overflowY: "scroll"
                                                        }}>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Model Name
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Model Number
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Version Number
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </> :
                                                devicesAssgined.length > 0 ?
                                                    devicesAssgined.map((device) => (
                                                        <>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name={device.devicename}
                                                                        value={device.deviceuuid}
                                                                        id={device.deviceuuid}
                                                                        checked={selectedDevicetoDeselect.some(
                                                                            (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                                        )}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            const deviceId = device.deviceuuid;
                                                                            const deviceName = device.devicename;
                                                                            const deviceMacId = device.decmacid;
                                                                            if (checked) {
                                                                                setSelectedDevicetoDeselect([
                                                                                    ...selectedDevicetoDeselect,
                                                                                    { id: deviceId, name: deviceName, macid: deviceMacId },
                                                                                ]);
                                                                            } else {
                                                                                setSelectedDevicetoDeselect((prevState) =>
                                                                                    prevState.filter(
                                                                                        (d) => d.id !== deviceId || d.macid !== deviceMacId
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                label={
                                                                    <>
                                                                        <CardHeader
                                                                            avatar={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Avatar
                                                                                    variant='rounded'
                                                                                    alt={device.devicename}
                                                                                    src={device.deviceimageName ? device.deviceimageName : DeviceImg}
                                                                                    sx={{ width: 120, height: 120, objectFit: "contain" }} />
                                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                            Device Name
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                            {device.devicename}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                            Device Macid
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                            {device.decmacid}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                            } />
                                                                        <CardContent>
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                        Model Name
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                        {device.modelName == undefined
                                                                                            ? " "
                                                                                            : device.modelName}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                        Model Number
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                        {device.modelNumber == undefined
                                                                                            ? "Not Provided"
                                                                                            : device.modelNumber}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                        Version Number
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                        {device.version === undefined
                                                                                            ? " "
                                                                                            : device.version}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </CardContent></>
                                                                }
                                                            />

                                                            <Divider />
                                                        </>
                                                    )) :
                                                    <center>
                                                        {"No " + secureSessionStorage.getItem("DeviceName") + " Assigned"}
                                                    </center>
                                        }
                                    </Stack>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleDeselectDeviceFromBed} disabled={!selectedDevicetoDeselect.length > 0 || !devicesAssgined.length > 0} fullWidth >Remove</Button>
                                    <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleOpenAddMoreDevice} fullWidth >Add More</Button>
                                </CardActions>
                            </TabPanel>
                        </CardContent>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openAddMoreDevice} onClose={handleCloseAddMoreDevice}>
                    <CardHeader
                        title={"Add " + secureSessionStorage.getItem("DeviceName")}
                        action={
                            <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddMoreDevice}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        {
                            loadDevicesList ? <CardHeader
                                avatar={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                width: 80,
                                                height: 80
                                            }}
                                        />
                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem", width: 50 }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                } /> :
                                devices.length > 0 ?
                                    <>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            maxHeight: 400,
                                            overflowY: "scroll"
                                        }} >
                                            {devices
                                                .map((device) => (
                                                    <FormControlLabel
                                                        key={device.deviceuuid}
                                                        control={<Checkbox
                                                            checked={selectedDevicestoAssign.some(
                                                                (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                            )}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                const deviceId = device.deviceuuid;
                                                                const deviceName = device.devicename;
                                                                const deviceMacId = device.decmacid;
                                                                if (checked) {
                                                                    setSelectedDevicetoAssign([
                                                                        ...selectedDevicestoAssign,
                                                                        { id: deviceId, name: deviceName, macid: deviceMacId },
                                                                    ]);
                                                                } else {
                                                                    setSelectedDevicetoAssign((prevState) =>
                                                                        prevState.filter(
                                                                            (d) => d.id !== deviceId || d.macid !== deviceMacId
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                            value={device.deviceuuid}
                                                        />}
                                                        label={<>
                                                            <CardHeader
                                                                avatar={
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Avatar
                                                                            variant='rounded'
                                                                            alt={device.devicename}
                                                                            src={device.deviceimageName ? device.deviceimageName : DeviceImg}
                                                                            sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                    {device.devicename}
                                                                                </Typography>
                                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                    {device.decmacid}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                } />

                                                        </>}
                                                    />
                                                ))}
                                        </Box>
                                    </>
                                    : <center>
                                        No {secureSessionStorage.getItem("DeviceName")} Found
                                    </center>
                        }
                    </CardContent>
                    <CardActions>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onMouseDown={handleMouseDown}
                                    onClick={handleCloseAddMoreDevice}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onMouseDown={handleMouseDown}
                                    onClick={handleAssignDeviceToBed}
                                    disabled={selectedDevicestoAssign.length === 0} // Disable when no device is selected
                                    fullWidth
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>

                </Dialog>
            </div>
        </div >
    )
}

export default ManageBed
