import React from 'react';
import { Link } from 'react-router-dom';

//mui-components
import { Home } from '@mui/icons-material';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { emphasize, styled } from '@mui/material/styles';

//components
import Iconify from '../../components/iconify';
import Preview from '../../layout/Preview';

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

// Define settings options with icons
const settingsOptions = [
  {
    label: "General",
    description: "View and update your store details",
    link: "/settings/general",
    icon: "eva:settings-2-outline", // Icon for General
  },
  {
    label: "Billing Plan",
    description: "View your current plan",
    link: "/settings/billing",
    icon: "eva:credit-card-outline", // Icon for Billing Plan
  },
  {
    label: "Key Settings",  // Changed from "Team" to "Key Settings"
    description: "Manage and view your API keys",
    link: "/settings/key-settings",
    icon: "ion:key", // Updated icon for Key Settings
  },
  {
    label: "SMS Settings",
    description: "View and update your SMS settings",
    link: "/settings/sms",
    icon: "eva:message-circle-outline", // Icon for SMS Settings
  },
  {
    label: "Configuration Settings",
    description: "Manage and view Wizard Configurations",
    link: "/settings/wizard-config",
    icon: "eva:code-outline", // Icon for Web Tracking Installation
  },
];

//-------------------------------------------------------------------------------

const Settings = () => {

  const handleMouseDown = (e) => {
    e.preventDefault();
  }

  return (
    <div style={{ display: "flex" }}>
      <Preview />
      <Container sx={{ marginTop: 10 }}>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard">
              <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                Home
              </Button>
            </Link>

            <StyledBreadcrumb label="Settings" />
          </Breadcrumbs>
        </Stack>

        <Grid container spacing={2} sx={{ marginTop: 3 }}>
          {settingsOptions.map((option, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card variant="outlined" sx={{ textAlign: 'center', p: 2 }}>
                <CardContent>
                  <Iconify icon={option.icon} width={32} height={32} sx={{ mb: 1, color: 'primary.main' }} />
                  <Typography variant="h6" gutterBottom>{option.label}</Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    {option.description}
                  </Typography>
                  <Button
                    variant="contained"
                    component={Link}
                    to={option.link}
                    sx={{ marginTop: 1 }}
                  >
                    Open
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

      </Container>
    </div>
  )
}

export default Settings
