import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import { Close, Delete, Edit, Home } from '@mui/icons-material';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Collapse,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { red } from "@mui/material/colors";
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//internals
import DeviceImg from "../../assets/images/devices.png";

//externals
import swal from 'sweetalert';
import secureSessionStorage from "../../crypto";

//-----------------------------------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}));

//-----------------------------------------------------------------------------------------------------------

const ManageDeviceProfile = () => {

    const [deviceProfile, setDeviceProfile] = useState([]);
    const [selectedProfileDevices, setSelectedProfileDevices] = useState([]);
    const [deviceDetails, setDeviceDetails] = useState([]);

    const [expandedCard, setExpandedCard] = useState(null);

    const [loading, setLoading] = useState(false);
    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loadDevices, setLoadDevices] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    const loadDeviceProfileList = () => {
        setLoading(true);
        var tenantId = secureSessionStorage.getItem("tenantId");
        var companyId = secureSessionStorage.getItem("companyId");

        console.log("-----" + tenantId + "-------" + companyId + "------");
        ApiCalls.getdetails("tenants/" + tenantId + "/companies/0/physicians/0/devicemodels")
            .then((response) => {
                console.log(response.data.deviceMode);
                if (response.data.deviceMode !== undefined) {
                    setDeviceProfile(response.data.deviceMode);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setDeviceProfile([]);
                }
            }).catch((error) => {
                console.log(error);
                setDeviceProfile([]);
                setLoading(false);
            })
    }

    useEffect(() => {
        loadDeviceProfileList();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const filteredDeviceProfiles = deviceProfile.filter((deviceProfile) =>
        deviceProfile.modelName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleExpandClick = (deviceUUid) => {
        setExpandedCard(expandedCard === deviceUUid ? null : deviceUUid);
    };

    const handleDeleteDeviceProfile = (deviceProfileId) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    ApiCalls.delete("deleteModel/" + deviceProfileId)
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.message === "Success") {
                                swal("Deleted Successfully!", {
                                    icon: "success",
                                });
                                loadDeviceProfileList();
                            }
                        });
                }
            });
    }

    const handleEditDeviceProfile = (deviceProfileId) => {
        secureSessionStorage.setItem("devProfileIdtoEdit", deviceProfileId);
        setRedirectToEdit(true);
    }

    const loadDevicesByProfile = (profileId) => {
        setLoadDevices(true);
        ApiCalls.getdetails(`model/${profileId}/getDeviceDetailsByProfile`)
            .then(response => {
                if (response.data.device != undefined) {
                    setDeviceDetails(response.data.device);
                    setLoadDevices(false);
                } else {
                    setDeviceDetails([]);
                    setLoadDevices(false);
                }
            })
            .catch(error => {
                console.error("Error fetching devices by profile:", error);
                setDeviceDetails([]);
                setLoadDevices(false);
            });
    };

    const handleInfoClick = (profileId) => {
        if (!deviceDetails[profileId]) {
            loadDevicesByProfile(profileId);
        }
        setSelectedProfileDevices(deviceDetails[profileId] || []);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDeviceDetails([]);
    };

    if (redirectToForm) {
        return (<Redirect to={'/adddeviceprofile'} />)
    }

    if (redirectToEdit) {
        return (<Redirect to={'/editdeviceprofile'} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={`Manage ${secureSessionStorage.getItem("DeviceName")} Profile`} />
                    </Breadcrumbs>
                    <Button variant="contained" onMouseDown={handleMouseDown} onClick={() => setRedirectToForm(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New {secureSessionStorage.getItem("DeviceName")} Profile
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {secureSessionStorage.getItem("DeviceName")} Profile List
                        </Typography>}
                        action={
                            <TextField
                                label={`Search ${secureSessionStorage.getItem("DeviceName")} Profile`}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredDeviceProfiles.length > 0 ? (
                                <Grid container spacing={2}>
                                    {filteredDeviceProfiles.map((profile) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={profile.deviceUUid}>
                                            <Card sx={{
                                                minHeight: 350, // Set a minimum height for all cards
                                                height: expandedCard === profile.deviceUUid ? 'auto' : '300px', // Expand the height for the selected card
                                                maxHeight: expandedCard === profile.deviceUUid ? 600 : 'none', // Limit the max height for the expanded card
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                boxShadow: 3,
                                                transition: 'height 0.3s ease-in-out',
                                                '&:hover': {
                                                    border: '2px solid #D1E9FC',
                                                    boxShadow: 'none'
                                                }
                                            }}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                            {profile.modelName.charAt(0)}
                                                        </Avatar>
                                                    }
                                                    action={
                                                        <Tooltip title={`${secureSessionStorage.getItem("DeviceName")} Info`} >
                                                            <IconButton color="primary" onMouseDown={handleMouseDown} onClick={() => handleInfoClick(profile.deviceUUid)}>
                                                                <Iconify icon={'fluent:info-12-filled'} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    title={profile.modelName}
                                                    subheader={profile.modelNumber}
                                                />
                                                <Avatar
                                                    variant='rounded'
                                                    alt={profile.modelName}
                                                    src={profile.devImage === "null" ? DeviceImg : profile.devImage}
                                                    sx={{ width: 150, height: 150, objectFit: 'contain', alignSelf: 'center', mt: 2 }} // Center the image
                                                />
                                                <CardActions>
                                                    <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleEditDeviceProfile(profile.deviceUUid)} startIcon={<Edit />}>Edit</Button>
                                                    <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleDeleteDeviceProfile(profile.deviceUUid)} startIcon={<Delete />}>Delete</Button>
                                                    <Tooltip title="View more">
                                                        <ExpandMore
                                                            expand={expandedCard === profile.deviceUUid}
                                                            onClick={() => handleExpandClick(profile.deviceUUid)}
                                                            onMouseDown={handleMouseDown}
                                                            aria-expanded={expandedCard === profile.deviceUUid}
                                                            aria-label="show more"
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <Iconify icon={'ic:round-expand-circle-down'} />
                                                        </ExpandMore>
                                                    </Tooltip>
                                                </CardActions>

                                                <Collapse in={expandedCard === profile.deviceUUid} timeout="auto" unmountOnExit>
                                                    <CardContent sx={{
                                                        maxHeight: 250, // Limit the height of the expanded content
                                                        overflowY: 'auto', // Allow scrolling for the expanded content
                                                    }}>
                                                        {profile.parameter && profile.parameter.map((group) => (
                                                            <div key={group.groupId}>
                                                                <Typography variant="subtitle2" noWrap>
                                                                    Group: {group.groupName}
                                                                </Typography>
                                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                                    {group.parameters && group.parameters.map((param) => (
                                                                        <ListItem key={param.paramId}>
                                                                            <ListItemAvatar>
                                                                                <Iconify icon={'ic:round-dns'} />
                                                                            </ListItemAvatar>
                                                                            <ListItemText primary={param.name} secondary={param.typeName} />
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            </div>
                                                        ))}
                                                    </CardContent>
                                                </Collapse>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>

                            ) : (
                                <center>
                                    No {secureSessionStorage.getItem("DeviceName")} Profile Found
                                </center>
                            )
                        }
                    </CardContent>
                </Card>

                <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {secureSessionStorage.getItem("DeviceName")} in Selected Profile
                        <IconButton aria-label="close" onMouseDown={handleMouseDown} onClick={handleCloseDialog} color="primary">
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers sx={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Fixed max height */}
                        {loadDevices ? <center> < CircularProgress /> </center> :
                            deviceDetails.length > 0 ? (
                                <Grid container spacing={2}>
                                    {deviceDetails.map((device) => (
                                        <Grid item xs={12} sm={6} key={device.deviceuuid}> {/* 2-column layout */}
                                            <Card variant="outlined" sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
                                                <Avatar
                                                    src={device.deviceimageName && device.deviceimageName !== "null" ? device.deviceimageName : DeviceImg}
                                                    alt={device.deviceName}
                                                    sx={{ width: 56, height: 56, marginRight: 2 }}
                                                />
                                                <div>
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        {device.deviceName}
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        ID: {device.decmacid}
                                                    </Typography>
                                                </div>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Typography>No {secureSessionStorage.getItem("DeviceName")} available for this profile.</Typography>
                            )
                        }
                    </DialogContent>
                </Dialog>

            </Container>
        </div >
    )
}

export default ManageDeviceProfile;
