import React, { useEffect, useRef, useState } from "react";

// MUI components
import {
    ArrowBackIos,
    ArrowForwardIos
} from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    Slide,
    Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Internals
import ReportViewerComponent from "./ReportViewerComponent";

// Externals
import { Icon } from "@iconify/react/dist/iconify.js";
import dayjs from "dayjs";
import secureSessionStorage from "../../crypto";

// Services
import ApiCalls from "../../services/try";

// DICOM-viewer imports
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneWADOImageLoader.configure({
    beforeSend: function (xhr) {
        // Add custom headers here (e.g., auth tokens) if needed
    },
});

cornerstoneTools.external.cornerstone = cornerstone;
const ZoomTool = cornerstoneTools.ZoomTool;
cornerstoneTools.addTool(ZoomTool);
cornerstoneTools.setToolActive("Zoom", { mouseButtonMask: 1 });

// Create a theme
const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#00BFFF", // Light blue color for buttons and borders
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    height: "100%", // Full height
                    margin: 0,
                    width: "100%", // Full width
                    borderRadius: 0, // Remove border radius for better fit on all screens
                },
            },
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DiComViewerComponent = ({ open, onClose, imageDetails, patientDetails }) => {
    const [selectedSeries, setSelectedSeries] = useState(0);
    const elementRefs = useRef([]);
    const [thumbnails, setThumbnails] = useState([]);
    const [showReport, setShowReport] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showPreviousImage, setShowPreviousImage] = useState(false);
    const [noPreviousImage, setNoPreviousImage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const previousImageRef = useRef(null);
    const mainElementRef = useRef(null);
    const [previousImage, setPreviousImage] = useState(null);
    const [previousImageDate, setPreviousImageDate] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFilePath, setSelectedFilePath] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const onCloseDialog = () => {
        setShowPreviousImage(false);
        setPreviousImage(null);
        setNoPreviousImage(false);
        if (previousImageRef.current) {
            cornerstone.disable(previousImageRef.current);
        }
        onClose();
    };

    const handleGenerateReport = () => {
        if (mainElementRef.current) {
            const canvas = mainElementRef.current.querySelector("canvas");
            const imageSrc = canvas ? canvas.toDataURL("image/png") : null;
            setSelectedImage(imageSrc);
        }
        setShowReport(true);
    };

    const handleCloseReport = () => {
        setShowReport(false);
    };

    const handleSeriesClick = (index) => {
        setSelectedSeries(index);
        setShowPreviousImage(false);
        setPreviousImage(null);
        setNoPreviousImage(false);
        if (previousImageRef.current) {
            cornerstone.disable(previousImageRef.current);
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const handleZoomIn = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.scale += 0.1;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleZoomOut = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.scale -= 0.1;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleRotateLeft = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.rotation -= 90;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleRotateRight = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.rotation += 90;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleResetView = () => {
        const image = cornerstone.getImage(mainElementRef.current);
        const defaultViewport = cornerstone.getDefaultViewportForImage(mainElementRef.current, image);
        cornerstone.setViewport(mainElementRef.current, {
            ...defaultViewport,
            voi: {
                windowWidth: image.windowWidth,
                windowCenter: image.windowCenter,
            },
            invert: false,
        });
    };

    const handleFlipHorizontal = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.hflip = !viewport.hflip;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleFlipVertical = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.vflip = !viewport.vflip;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        console.log(patientDetails);
    }, []);

    useEffect(() => {
        if (open) {
            setSelectedSeries(0);
            const newThumbnails = imageDetails.map((detail, index) => {
                const blobUrl = URL.createObjectURL(detail.imageBlob);
                const imageId = `wadouri:${blobUrl}`;
                const filePath = detail.filePath;
                const fileName = detail.fileName;
                setSelectedFileName(fileName);
                setSelectedFilePath(filePath);

                return new Promise((resolve) => {
                    cornerstone
                        .loadImage(imageId)
                        .then((image) => {
                            const canvas = document.createElement("canvas");
                            canvas.width = image.width;
                            canvas.height = image.height;
                            const context = canvas.getContext("2d");
                            cornerstone.renderToCanvas(canvas, image);
                            const thumbnailUrl = canvas.toDataURL("image/png");
                            resolve(thumbnailUrl);
                        })
                        .catch((error) => {
                            console.error(`Error loading DICOM image for thumbnail (index ${index}):`, error);
                            resolve(null);
                        })
                        .finally(() => {
                            URL.revokeObjectURL(blobUrl);
                        });
                });
            });

            Promise.all(newThumbnails).then((urls) => {
                setThumbnails(urls.filter((url) => url !== null));
            });
        }
    }, [open, imageDetails]);

    useEffect(() => {
        if (open) {
            thumbnails.forEach((thumbnail, index) => {
                if (thumbnail && elementRefs.current[index]) {
                    const detail = imageDetails[index];
                    const blobUrl = URL.createObjectURL(detail.imageBlob);
                    const imageId = `wadouri:${blobUrl}`;

                    cornerstone.enable(elementRefs.current[index]);
                    cornerstone
                        .loadImage(imageId)
                        .then((image) => {
                            cornerstone.displayImage(elementRefs.current[index], image);
                        })
                        .catch((error) => {
                            console.error(`Error loading DICOM image in sidebar (index ${index}):`, error);
                        });
                }
            });

            if (imageDetails[0] && mainElementRef.current) {
                const blobUrl = URL.createObjectURL(imageDetails[0].imageBlob);
                const imageId = `wadouri:${blobUrl}`;

                cornerstone.enable(mainElementRef.current);
                cornerstone
                    .loadImage(imageId)
                    .then((image) => {
                        cornerstone.displayImage(mainElementRef.current, image);
                    })
                    .catch((error) => {
                        console.error("Error loading DICOM image:", error);
                    });
            }
        }
    }, [open, thumbnails, imageDetails]);

    useEffect(() => {
        if (open && imageDetails[selectedSeries] && mainElementRef.current) {
            const blobUrl = URL.createObjectURL(imageDetails[selectedSeries].imageBlob);
            const imageId = `wadouri:${blobUrl}`;

            cornerstone.enable(mainElementRef.current);
            cornerstone
                .loadImage(imageId)
                .then((image) => {
                    cornerstone.displayImage(mainElementRef.current, image);
                })
                .catch((error) => {
                    console.error("Error loading DICOM image:", error);
                });
        }
    }, [open, selectedSeries, imageDetails]);

    useEffect(() => {
        if (!open) {
            setThumbnails([]);
            elementRefs.current.forEach((ref) => {
                if (ref) {
                    cornerstone.disable(ref);
                }
            });
            if (mainElementRef.current) {
                cornerstone.disable(mainElementRef.current);
            }
            if (previousImageRef.current) {
                cornerstone.disable(previousImageRef.current);
            }
        }
    }, [open]);

    useEffect(() => {
        if (showPreviousImage && previousImageRef.current) {
            cornerstone.enable(previousImageRef.current);
            if (previousImage) {
                cornerstone
                    .loadImage(`wadouri:${previousImage}`)
                    .then((image) => {
                        cornerstone.displayImage(previousImageRef.current, image);
                    })
                    .catch((error) => {
                        console.error("Error displaying previous DICOM image:", error);
                    });
            }
        }
    }, [showPreviousImage, previousImage]);

    const handleCheckboxChange = (event) => {
        setShowPreviousImage(event.target.checked);
        setIsLoading(true);

        if (event.target.checked) {
            const currentImage = imageDetails[selectedSeries];

            if (!currentImage || !currentImage.details) {
                console.error("No image details or current image found for the selected series.");
                setIsLoading(false);
                return;
            }

            const scanType =
                currentImage.details.scanType === null ||
                    currentImage.details.scanType === undefined ||
                    currentImage.details.scanType === "Unknown Scan"
                    ? ""
                    : currentImage.details.scanType.trim();
            const bodySite =
                currentImage.details.bodyPart === null ||
                    currentImage.details.bodyPart === undefined
                    ? "Unknown Body Site"
                    : currentImage.details.bodyPart.trim();
            const imageId =
                currentImage.details.imageId === null || currentImage.details.imageId === undefined
                    ? ""
                    : currentImage.details.imageId.trim();
            const patientId = secureSessionStorage.getItem("PatientToView");

            if (!imageId) {
                console.error("Image ID is missing.");
                setIsLoading(false);
                return;
            }

            const params = {
                scanType: scanType,
                bodySite: bodySite,
                currentImageId: imageId,
                patientId: patientId,
            };

            ApiCalls.getContents("display", params)
                .then((response) => {
                    if (response && response.data && response.data.diagnosticImage && response.data.diagnosticImage.length > 0) {
                        const previousImageData = response.data.diagnosticImage[0];
                        const filePath = previousImageData.filePath;
                        const fileName = previousImageData.fileName;
                        const imageDate = previousImageData.scanDate;

                        if (filePath) {
                            ApiCalls.viewFile("viewDicomAsImage", { filePath: filePath + "/" + fileName })
                                .then((response) => {
                                    if (response.data instanceof Blob) {
                                        const url = window.URL.createObjectURL(response.data);
                                        setPreviousImage(url);
                                        setPreviousImageDate(imageDate);
                                        setNoPreviousImage(false);
                                        setIsLoading(false);
                                    } else {
                                        console.error("Unexpected response data type:", typeof response.data);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error fetching previous DICOM image:", error);
                                    setPreviousImage(null);
                                    setNoPreviousImage(true);
                                    setIsLoading(false);
                                });
                        } else {
                            console.error("No valid file path returned for the previous image.");
                            setPreviousImage(null);
                            setNoPreviousImage(true);
                            setIsLoading(false);
                        }
                    } else {
                        console.log("No previous image found.");
                        setPreviousImage(null);
                        setNoPreviousImage(true);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching previous image details:", error.response ? error.response.data : error.message);
                    setPreviousImage(null);
                    setNoPreviousImage(true);
                    setIsLoading(false);
                });
        } else {
            setPreviousImage(null);
            setNoPreviousImage(false);
            if (previousImageRef.current) {
                cornerstone.disable(previousImageRef.current);
            }
            setIsLoading(false);
        }
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Dialog
                fullScreen
                open={open}
                onClose={onCloseDialog}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        height: "100%", // Full height dialog
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                    },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        overflow: "hidden", // Prevent overflow causing scrollbars
                        backgroundColor: "#080F18",
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        padding: "12px",
                    }}
                >
                    {/* Header Section */}
                    <Box
                        sx={{
                            flex: "0 0 auto", // Auto height for the header
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0 10px",
                            backgroundColor: "#0a1a2b",
                            borderRadius: "12px",
                        }}
                    >
                        {/* Left Section: Typography Components */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "white",
                                    fontFamily: "Martel Sans",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    marginBottom: 1,
                                    marginTop: 1,
                                }}
                            >
                                {`${secureSessionStorage.getItem("PatientName")} Name`}:&nbsp;
                                <Box component="span" sx={{ color: "yellow" }}>
                                    {patientDetails.firstName} {patientDetails.lastName && ` ${patientDetails.lastName}`}
                                </Box>
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    color: "white",
                                    fontFamily: "Martel Sans",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                }}
                            >
                                {`${secureSessionStorage.getItem("PatientName")} ID`}:
                                <Box component="span" sx={{ color: "yellow" }}> {patientDetails.patientId} </Box>
                            </Typography>
                        </Box>

                        {/* Right Section: Buttons */}
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#054A68",
                                    color: "white",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                }}
                                onMouseDown={handleMouseDown}
                                onClick={handleGenerateReport}
                                startIcon={<Icon icon={"heroicons-solid:document-report"} />}
                            >
                                Generate Report
                            </Button>
                            <Button
                                color="error"
                                variant="contained"
                                onClick={onClose}
                                onMouseDown={handleMouseDown}
                                aria-label="close"
                            >
                                Exit
                            </Button>
                        </Box>
                    </Box>

                    {/* Main View Section */}
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            gap: "12px",
                            padding: "0 12px",
                            overflow: "hidden", // Prevent overflow causing scrollbars
                            position: "relative", // Allow absolute positioning of children
                            maxHeight: "100vh", // Ensure height does not exceed viewport
                            maxWidth: "100%", // Ensure width does not exceed container
                        }}
                    >
                        {/* Left Section (Thumbnails) */}
                        <Box
                            sx={{
                                flex: "0 0 15%",
                                padding: "10px",
                                backgroundColor: "#0a1a2b",
                                borderRadius: "12px",
                                overflowY: "auto",
                                maxHeight: "100vh",
                                scrollbarWidth: "none",  // For Firefox
                                "&::-webkit-scrollbar": {
                                    display: "none",  // For Chrome, Safari, and Edge
                                },
                            }}
                        >
                            {/* List of Thumbnails */}
                            <List>
                                {thumbnails.map((thumbnail, index) => (
                                    <ListItem
                                        button
                                        key={index}
                                        selected={index === selectedSeries}
                                        onClick={() => handleSeriesClick(index)}
                                    >
                                        <Box
                                            ref={(el) => (elementRefs.current[index] = el)}
                                            sx={{
                                                width: "100%",
                                                height: "100px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {/* Each sidebar item will display a DICOM image here */}
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>

                        {/* Main Image and Details Container */}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                padding: "10px",
                                borderRadius: "12px",
                                overflow: "hidden",
                                position: "relative", // Relative positioning to contain absolute positioned elements
                            }}
                        >
                            {/* Main Image and Drawer Toggle Section */}
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "12px",
                                    padding: "10px",
                                    borderRadius: "12px",
                                    overflow: "hidden",
                                }}
                            >
                                {/* Main DICOM Image Area */}
                                <Box
                                    sx={{
                                        flex: 1,
                                        padding: "10px",
                                        backgroundColor: "#0c1d2e",
                                        borderRadius: "12px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden", // Prevent overflow causing scrollbars
                                        position: "relative",
                                    }}
                                >
                                    {/* Image Display */}
                                    {imageDetails && imageDetails.length > 0 ? (
                                        <Box
                                            ref={mainElementRef}
                                            sx={{
                                                width: "90%",
                                                height: "calc(100% - 120px)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                overflow: "hidden",
                                                position: "relative",
                                            }}
                                        >
                                            {/* The selected DICOM image will be displayed here */}
                                        </Box>
                                    ) : (
                                        <Typography variant="h6" sx={{ color: "white", textAlign: "center" }}>
                                            No DICOM images available to display
                                        </Typography>
                                    )}
                                </Box>

                                {/* Previous Image Section */}
                                {showPreviousImage && (
                                    <Box
                                        sx={{
                                            flex: 1,
                                            padding: "10px",
                                            backgroundColor: "#0c1d2e",
                                            borderRadius: "12px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            position: "relative",
                                            width: "50%",  // Set width to half of the container
                                            overflow: "hidden",  // Prevent overflow causing scrollbars
                                        }}
                                    >
                                        {isLoading ? (
                                            <Box
                                                sx={{
                                                    width: "90%",
                                                    height: "calc(100% - 120px)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    mb: 2,
                                                    maxWidth: "90%",
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    boxSizing: "border-box",
                                                    objectFit: "contain",
                                                }}
                                            >
                                                <Icon icon={"eos-icons:three-dots-loading"} width="100" />
                                            </Box>
                                        ) : noPreviousImage ? (
                                            <Box
                                                sx={{
                                                    width: "90%",
                                                    height: "calc(100% - 120px)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    mb: 2,
                                                    maxWidth: "90%",
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    boxSizing: "border-box",
                                                    objectFit: "contain",
                                                }}
                                            >
                                                <Icon icon={"ic:baseline-image-not-supported"} style={{ fontSize: "4rem", color: "white" }} />
                                                <Typography variant="h6" sx={{ color: "white", textAlign: "center" }}>
                                                    No Previous Image
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: "90%",  // Full width of the container
                                                    height: "90%",  // Full height of the container
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                {/* Typography component for the date */}
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Martel Sans",
                                                        fontSize: "16px",
                                                        fontWeight: 700,
                                                        lineHeight: "27.36px",
                                                        width: "90%",  // Full width for date text
                                                        color: "white",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        position: "absolute",
                                                        bottom: 0.5,
                                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    }}
                                                >
                                                    Previous Date: {formatDate(previousImageDate)}
                                                </Typography>

                                                {/* Image Component */}
                                                <img
                                                    src={previousImage}
                                                    alt="Previous DICOM"
                                                    onLoad={handleImageLoad}
                                                    style={{
                                                        maxWidth: "100%",  // Make sure the image fits within the width of the container
                                                        maxHeight: "100%",  // Make sure the image fits within the height of the container
                                                        objectFit: "contain",  // Maintain aspect ratio and contain the image within the box
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                {/* Image Details Drawer */}
                                <Box
                                    sx={{
                                        position: "absolute", // Absolutely position the drawer to prevent resizing
                                        top: 0,
                                        right: drawerOpen ? 0 : "-200px", // Slide the drawer out of view when closed
                                        width: "200px", // Fixed width for the drawer
                                        height: "100%", // Full height
                                        backgroundColor: "#0c1d2e",
                                        borderRadius: "12px 0 0 12px",
                                        transition: "right 0.3s ease", // Smooth transition for opening/closing
                                        overflowY: "auto",
                                        padding: drawerOpen ? "10px" : "0px",
                                    }}
                                >
                                    {drawerOpen && (
                                        <>
                                            {imageDetails?.[selectedSeries]?.details && Object.keys(imageDetails[selectedSeries].details).length > 0 ? (
                                                <Box
                                                    sx={{
                                                        maxHeight: "700px",
                                                        overflowY: "auto",
                                                        minWidth: "15%",
                                                        marginRight: "20px",
                                                        scrollbarWidth: "none",  // For Firefox
                                                        "&::-webkit-scrollbar": {
                                                            display: "none",  // For Chrome, Safari, and Edge
                                                        },
                                                    }}
                                                >
                                                    <Typography variant="h6" sx={{ color: "white", mb: 4 }}>Image Details</Typography>
                                                    <Grid container spacing={2}>
                                                        {Object.entries(imageDetails[selectedSeries].details).map(([key, value]) =>
                                                            value && (
                                                                <React.Fragment key={key}>
                                                                    <Grid item xs={6}>
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{
                                                                                color: "white",
                                                                                fontWeight: "bold",
                                                                                wordWrap: "break-word",
                                                                                overflowWrap: "break-word",
                                                                            }}
                                                                        >
                                                                            {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{
                                                                                color: "lightgreen",
                                                                                fontWeight: "bold",
                                                                                wordWrap: "break-word",
                                                                                overflowWrap: "break-word",
                                                                            }}
                                                                        >
                                                                            {key === "dateAndTime"
                                                                                ? dayjs(Number(value)).format("YYYY-MM-DD HH:mm")
                                                                                : value}
                                                                        </Typography>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                    </Grid>
                                                </Box>
                                            ) : (
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: "white",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    No details available
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </Box>

                                {/* Toggle Button for Drawer Open/Close */}
                                {!drawerOpen ? (
                                    <IconButton
                                        onClick={toggleDrawer}
                                        onMouseDown={handleMouseDown}
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            right: "20px",
                                            transform: "translateY(-50%)",
                                            backgroundColor: "#0c1d2e",
                                            borderRadius: "50%",
                                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
                                        }}
                                    >
                                        <ArrowForwardIos />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={toggleDrawer}
                                        onMouseDown={handleMouseDown}
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            right: "15%",
                                            transform: "translateY(-50%)",
                                            backgroundColor: "#0c1d2e",
                                            borderRadius: "50%",
                                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
                                        }}
                                    >
                                        <ArrowBackIos />
                                    </IconButton>
                                )}
                            </Box>

                            {/* Bottom Toolbar Section */}
                            <Box
                                sx={{
                                    flex: "0 0 auto",
                                    padding: "10px",
                                    backgroundColor: "#0c1d2e",
                                    borderRadius: "12px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    maxHeight: "75px",
                                }}
                            >
                                {/* Toolbar buttons (Zoom, Rotate, Flip, Reset) */}
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleZoomIn}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={"tabler:zoom-in"} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Zoom In</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleZoomOut}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={"tabler:zoom-out"} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Zoom Out</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleRotateLeft}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={"ic:outline-rotate-left"} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Left</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleRotateRight}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={"ic:outline-rotate-right"} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Right</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleFlipHorizontal}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={"mdi:flip-horizontal"} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Flip Horizontal</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleFlipVertical}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={"mdi:flip-vertical"} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Flip Vertical</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleResetView}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={"bx:reset"} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Reset View</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 1 }}>
                                    <Checkbox
                                        checked={showPreviousImage}
                                        onChange={handleCheckboxChange}
                                    />
                                    <Typography variant="caption" sx={{ color: "white" }}>View Previous Image </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>

            {
                showReport && (
                    <ReportViewerComponent
                        open={showReport}
                        onClose={handleCloseReport}
                        dicomImage={selectedImage}
                        patientDetails={patientDetails}
                        selectedImageDetails={imageDetails[selectedSeries]} // Pass the selected image details as props
                        selectedFilePath={selectedFilePath}
                        selectedFileName={selectedFileName}
                    />
                )
            }

        </ThemeProvider >
    );
};

export default DiComViewerComponent;
