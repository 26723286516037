import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//internals
import Iconify from '../../components/iconify/Iconify';
import Preview from "../../layout/Preview";

//mui-components
import { Home } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    ListItemIcon,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureSessionStorage from "../../crypto";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//-----------------------------------------------------------------------------

const EditSensor = () => {

    const [sensorMacId, setSensorMacId] = useState('');
    const [sensorName, setSensorName] = useState('');
    const [sensorType, setSensorType] = useState('');
    const [fileError, setFileError] = useState(false);
    const [fileMessage, setFileMessage] = useState('')

    const [csvFile, setCsvFile] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    const [nameError, setNameError] = useState(false);
    const [macIdError, setMacIdError] = useState(false);
    const [deviceError, setDeviceError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        var sensorId = secureSessionStorage.getItem("sensorId")
        ApiCalls.getdetails("sensor/" + sensorId + "/getsensordetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.sensor != undefined) {
                    setSensorName(response.data.sensor[0].sensorName);
                    setSensorMacId(response.data.sensor[0].sensormacid);
                    setSensorType(response.data.sensor[0].sensorType);
                    setUploadedImage(response.data.sensor[0].sensorImg);
                } else {
                    setSensorName('');
                    setSensorMacId('');
                    setSensorType('');
                    setUploadedImage(null);
                }
            })
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleCancel = () => {
        setRedirectToList(true);
    }

    const onChangeX = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setUploadedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBlur = (field) => {
        switch (field) {
            case 'sensorName':
                setNameError(!sensorName.trim());
                break;
            case 'sensorMacId':
                setMacIdError(!sensorMacId.trim());
                break;
            case 'sensorType':
                setDeviceError(!sensorType);
                break;
            default:
                break;
        }
    };

    const handleUpdate = () => {
        // Reset all error states
        setNameError(false);
        setMacIdError(false);
        setDeviceError(false);
        setFileError(false);

        // Check if either both text fields or the CSV file is provided
        const isTextFieldsEmpty = !sensorName.trim() && !sensorMacId.trim() && !sensorType;
        const isFileEmpty = !csvFile;

        if (isTextFieldsEmpty && isFileEmpty) {
            // Set errors for both text fields and the CSV file
            setNameError(true);
            setMacIdError(true);
            setDeviceError(true);
            setFileError(true);
            return;
        }

        // Validate text fields if provided
        if (!sensorName.trim()) {
            setNameError(true);
        }
        if (!sensorMacId.trim()) {
            setMacIdError(true);
        }
        if (!sensorType) {
            setDeviceError(true);
        }

        // Validate CSV file if provided
        if (isFileEmpty) {
            setFileError(true);
        }

        // Continue with your registration logic if at least one set of fields is valid
        if ((sensorName.trim() && sensorMacId.trim() && sensorType) || !isFileEmpty) {

            const data = {
                sensorName: sensorName,
                sensormacid: sensorMacId,
                sensorType: sensorType,
                sensorImg: uploadedImage == null ? "" : uploadedImage
            }
            var sensorId = secureSessionStorage.getItem("sensorId")
            ApiCalls.edit("sensor/" + sensorId + "/editsensordetails", data)
                .then((response) => {
                    console.log(response.data);
                    setRedirectToList(true);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    if (redirectToList) {
        return (<Redirect to={"/managesensor"} />)
    }


    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managesensor">
                            <Button variant="text" startIcon={<Icon icon={'tabler:devices-plus'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Manage Sensor
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Edit Sensor" />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Edit Sensor"}
                        </Typography>}
                    />
                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ ml: 10, mt: 3 }}>
                                            <Avatar
                                                alt="Image"
                                                variant="rounded"
                                                src={uploadedImage}
                                                sx={{
                                                    marginLeft: 5,
                                                    width: 200,
                                                    height: 200,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        transform: 'scale(1.1)',
                                                    },
                                                }}
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                onClick={() => document.getElementById('avatar-input').click()}
                                            >
                                                <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                                    {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="material-symbols:door-sensor-outline" color="#4481eb" width="100" />}
                                                    <div className="text-container">
                                                        <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                                    </div>
                                                </div>
                                            </Avatar>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="avatar-input"
                                                onChange={onChangeX}
                                                style={{ display: 'none', }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 6">
                                    <Item>
                                        <Stack direction="column" spacing={2} justifyContent="space-between">

                                            <TextField
                                                label={
                                                    <span>
                                                        Sensor Name{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={sensorName}
                                                onBlur={() => handleBlur('sensorName')}
                                                onChange={(e) => setSensorName(e.target.value)}
                                                error={nameError}
                                                helperText={nameError && "Please enter a valid Sensor Name"}
                                            />
                                            <TextField
                                                label={
                                                    <span>
                                                        Sensor Mac ID{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={sensorMacId}
                                                onBlur={() => handleBlur('sensorMacId')}
                                                onChange={(e) => setSensorMacId(e.target.value)}
                                                error={macIdError}
                                                helperText={macIdError && "Please enter a valid Sensor Mac ID"}
                                            />

                                            {/* Select for SensorType */}
                                            <TextField
                                                select
                                                label={
                                                    <span>
                                                        Sensor Type{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={sensorType}
                                                onBlur={() => handleBlur('sensorType')}
                                                onChange={(e) => setSensorType(e.target.value)}
                                                error={deviceError}
                                                helperText={deviceError && "Please select a Sensor Type"}
                                            >
                                                <MenuItem value="Chest">
                                                    <ListItemIcon>
                                                        <Icon icon={'material-symbols:sensor-door-outline-rounded'} />
                                                    </ListItemIcon>
                                                    Chest Sensor
                                                </MenuItem>
                                                <MenuItem value="Limb">
                                                    <ListItemIcon>
                                                        <Icon icon={'material-symbols:sensor-door-outline-rounded'} />
                                                    </ListItemIcon>
                                                    Limb Sensor
                                                </MenuItem>
                                                <MenuItem value="BP">
                                                    <ListItemIcon>
                                                        <Icon icon={'material-symbols:sensor-door-outline-rounded'} />
                                                    </ListItemIcon>
                                                    BP Sensor
                                                </MenuItem>
                                            </TextField>

                                            <Stack direction="row" spacing={2} justifyContent="space-around" >
                                                <Button
                                                    fullWidth
                                                    size="small"
                                                    type="submit"
                                                    color="error"
                                                    variant="outlined"
                                                    onMouseDown={handleMouseDown}
                                                    onClick={handleCancel}
                                                    sx={{
                                                        borderRadius: "12px",
                                                    }}
                                                    startIcon={
                                                        <Iconify icon={'material-symbols:cancel-presentation'} />
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    size="small"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={handleUpdate}
                                                    onMouseDown={handleMouseDown}
                                                    sx={{
                                                        borderRadius: "12px",
                                                    }}
                                                    endIcon={
                                                        <Iconify icon={'mdi:content-save-check'} />
                                                    }
                                                >
                                                    Update
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container>

        </div>
    );
}

export default EditSensor;
