import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
let bufferPos = 0;
const LiveLineChart = ({ liveData, parameterName, parameterId, onParamValueChange }) => {
  const maxDataPoints = parameterId == 10000 ? 125 : 62;
  const chartData = useRef({
    labels: [],
    datasets: [
      {
        label: parameterName,
        data: [...Array(maxDataPoints).fill(0)],
        fill: false,
        // borderColor: "rgba(75,192,192,1)",
        borderColor: parameterId == 10002 ? "rgb(75, 192, 192)" : parameterId == 10000 ? "rgba(39, 245, 41, 0.8)" : "rgba(255, 247, 0, 0.8)",
        tension: 0.4,
        borderWidth: 2,
        showLine: true,
        pointRadius: 0,
      },
    ],
  });
  
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: parameterName,
        data: [...Array(maxDataPoints).fill(0)], // Add an empty array for waveform data
        fill: false,
        // borderColor: "rgba(75,192,192,1)",
        borderColor: "rgb(75, 192, 192)",
        tension: 0.4,
        borderWidth: 2,
        showLine: true,
        pointRadius: 0,
      },
    ],
  });


  useEffect(() => {
    const intervalId = setTimeout(() => {
      setData({ ...chartData.current });
    }, 1000 / maxDataPoints);
    return () => clearTimeout(intervalId);
  }, [data])

  const chartOptions = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        min: 0,
        max: 250
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },

  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setData({ ...chartData.current });
    }, 1000 / maxDataPoints);

    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    console.log(liveData, 'liveData');

    if (liveData != null) {
      liveData.forEach((point) => {
        setTimeout(() => {
          addData(point)
        }, 1000 / maxDataPoints)
      })
    }
  }, [liveData])

  const addData = (point) => {
    if (bufferPos >= maxDataPoints * 10) {
      bufferPos = 0;
    }

    chartData.current.datasets[0].data[bufferPos] = point;
    chartData.current.labels[bufferPos] = bufferPos;

    [...Array(parameterId == 10000 ? 10 : 5)].map((item, index) => {
      chartData.current.datasets[0].data[bufferPos + index + 1] = null;
      chartData.current.labels[bufferPos + 1] = bufferPos + index + 1;
    })

    if (parameterId == 10002) console.log(point, "datapnt");
    console.log(bufferPos, parameterName, "writePointer");
    bufferPos++

  }

  return (
    <div style={{ flex: 1 }}>
      <Line type="line" data={data} options={chartOptions} />
    </div>
  );
};

export default LiveLineChart;
