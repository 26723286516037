import React from 'react';
import Alarm from "../../assets/images/Alarm.png";
import styled from 'styled-components';
import './index.css';

const formatNIBP = (value) => (value < 0 ? '0' : value);

function BottomContainer({ nibpsys, nibpdia, nibpmean, t1, t2, pr }) {
    return (
        <Container>
            <Card color="#1ABC9C">
                <Header>
                    <ContentText>NIBP</ContentText>
                    <ContentText>mmHg</ContentText>
                </Header>
                <ValueContainer>
                    <LargeText>{formatNIBP(nibpsys)} / {formatNIBP(nibpdia)} ({formatNIBP(nibpmean)})</LargeText>
                </ValueContainer>
            </Card>
            <Card color="#E74C3C">
                <Header>
                    <ContentText>T1</ContentText>
                    <ContentText>°C</ContentText>
                </Header>
                <ValueContainer>
                    <LargeText>{t1 || '-'}</LargeText>
                </ValueContainer>
            </Card>
            <Card color="#F5A623">
                <Header>
                    <ContentText>T2</ContentText>
                    <ContentText>°C</ContentText>
                </Header>
                <ValueContainer>
                    <LargeText>{t2 || '-'}</LargeText>
                </ValueContainer>
            </Card>
            <Card color="#A020F0">
                <Header>
                    <ContentText>PR</ContentText>
                    <ContentText>/min</ContentText>
                </Header>
                <ValueContainer>
                    <LargeText>{pr || '-'}</LargeText>
                </ValueContainer>
            </Card>
            <Card color="#AC1100">
                <Header>
                    <ContentText>Alarm</ContentText>
                </Header>
                <ValueContainer>
                    <img src={Alarm} alt="Alarm" className="alarm-image" />
                </ValueContainer>
            </Card>
        </Container>
    );
}

export default BottomContainer;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 20vh;
    padding: 10px;
`;

const Card = styled.div`
    background-color: #1D252E;
    color: ${(props) => props.color};
    flex: 1 1 15%;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 150px; /* Set a minimum width for smaller screens */
    max-width: 250px; /* Limit max width for larger screens */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const ValueContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
`;

const LargeText = styled.p`
    font-size: 2rem; /* Adjusted for better responsiveness */
`;

const ContentText = styled.p`
    font-size: 1.2rem; /* Adjusted for better responsiveness */
`;

const AlarmImage = styled.img`
    width: 60px;
    height: 50px;
    object-fit: contain;
`;
