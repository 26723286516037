import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './index.css';

function ChartVitalDisplay({ index, colour, vitalvalue, decodedpatient, slideInfo, slide }) {
    const [borderColor, setBorderColor] = useState('transparent');

    useEffect(() => {
        // Ensure the borderColor updates based on vitalvalue and slide range
        //console.log("The vital value ", vitalvalue, slide.min, slide.max);
        if (vitalvalue === -100 || vitalvalue == null) {
            setBorderColor('transparent');
        } else if (vitalvalue < slide.min || vitalvalue > slide.max) {
            setBorderColor('red');
        } else {
            setBorderColor('transparent');
        }
    }, [vitalvalue, slide]); // Added `slide` to dependencies

    return (
        <div style={{ width: '100%', display: 'flex', border: `2px solid ${borderColor}`, padding: 0, margin: 0 }}>
            <div style={{ width: '30px' }}>
                <p style={{ color: colour, fontSize: '1.5rem', textAlign: 'end' }}>{slideInfo[index].tag}</p>
                <p style={{ color: colour, fontSize: '1.5rem', textAlign: 'end' }}>{slideInfo[index].range.max}</p>
                <p style={{ color: colour, fontSize: '1.5rem', textAlign: 'end' }}>{slideInfo[index].range.min}</p>
                
            </div>
            <div style={{ width: '114px' }}>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ color: colour, fontSize: '3.5rem', paddingLeft: 65 }}>{(vitalvalue > 0) ? vitalvalue : '--'}</p>
                </div>
            </div>
            <div style={{
                position: 'relative',
                flex: 1,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end'
            }}>
                <div style={{
                    bottom: 0,
                    float: 'right',
                }}>
                    <p style={{ color: colour, fontSize: '1.5rem' }}>{slideInfo[index].unit}</p>
                </div>
            </div>
        </div>
    );
}

export default ChartVitalDisplay;

const styles = {
    dialog: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        height: '300px', // Fixed height
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    }
};

const LargeText = styled.p`
  font-size: 3rem;
`;
