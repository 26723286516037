import CryptoJS from 'crypto-js';

const secureSessionStorage = {
    setItem: (key, value) => {
        const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), 'your-secret-key').toString();
        sessionStorage.setItem(key, encryptedValue);
    },
    getItem: (key) => {
        const encryptedValue = sessionStorage.getItem(key);
        if (!encryptedValue) return null;

        try {
            const bytes = CryptoJS.AES.decrypt(encryptedValue, 'your-secret-key');
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (e) {
            console.error("Decryption error:", e);
            return null;
        }
    },
    removeItem: (key) => {
        sessionStorage.removeItem(key);
    },
    clear: () => {
        sessionStorage.clear();
    }
};

export default secureSessionStorage;
