import React, { useEffect, useRef, useState } from 'react';

//components
import DoctorImg from '../../../assets/illustrations/Doctor-pana.svg';
import BLoodPressure from "../../../assets/images/bp.png";
import DeviceImg from "../../../assets/images/devices.png";
import HeartRate from "../../../assets/images/hr.png";
import SPO2 from "../../../assets/images/spo2.png";
import Thermometer from "../../../assets/images/thermometer.png";
import Preview from '../../../layout/Preview';
import MeasurementList from './MeasurementList';

//mui-components
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Container,
    Grid,
    MenuItem,
    Popover,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

//externals
import Chart from 'chart.js/auto';
import moment from 'moment';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import secureSessionStorage from "../../../crypto";

//services
import Iconify from '../../../components/iconify';
import ApiCalls from "../../../services/try";

// Import and register the line controller
import { CategoryScale, LineController, LineElement, LinearScale, PointElement } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

// ----------------------------------------------------------------------

const MaterialArrow = ({ className, onClick, direction }) => {
    const arrowStyle = {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        [direction]: direction === "prev" ? "-35px" : "auto",
        right: direction === "next" ? "0" : "10px"
    };

    return (
        <div className={className} onClick={onClick} style={arrowStyle}>
            {direction === "prev" ? (
                <ChevronLeftRounded color="primary" fontSize="large" />
            ) : (
                <ChevronRightRounded color="primary" fontSize="large" />
            )}
        </div>
    );
};

const CHART_HEIGHT = 270;
const LEGEND_HEIGHT = 65;

const StyledCard = styled("div")(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(2),
    "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
    "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
        overflow: "visible",
    },
    "& .apexcharts-legend": {
        height: LEGEND_HEIGHT,
        alignContent: "center",
        position: "relative !important",
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

const StyledHRIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '30%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    backgroundColor: "#FDEDEC",
}));
const StyledTempIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '30%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    backgroundColor: "#D6EAF8 ",
}));

const StyledBPIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '30%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    backgroundColor: "#FDEDEC",
}));

const StyledGlucoIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '30%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    backgroundColor: "#FCF3CF",
}));



// ----------------------------------------------------------------------

const PatientDashboard = () => {

    const [loadDevice, setLoadDevice] = useState(false);

    const [measureParameters, setMeasureParameters] = useState([]);
    const [device, setDevice] = useState([]);

    const [filterOptions, setFilterOptions] = useState([
        {
            value: 'today',
            label: 'Today',
        },
        {
            value: 'week',
            label: 'One Week',
        },
        {
            value: 'month',
            label: 'One Month',
        },
        {
            value: 'all',
            label: 'All',
        },
        // {
        //   value: 'date',
        //   label: 'Selected Dates',
        // },
        // {
        //   value: 'specificdate',
        //   label: 'Date',
        // },
    ]);
    const [parameters, setParameters] = useState([]);
    const [selectedDateFilter, setSelectedDateFilter] = useState(filterOptions[0]);
    const [selectedParamFilter, setSelectedParamFilter] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [averageValue, setAverageValue] = useState([]);

    const [openDateFilter, setOpenDateFilter] = useState(null);
    const [openParamFilter, setOpenParamFilter] = useState(null);

    const [deviceCount, setDeviceCount] = useState(0);

    const [paramName, setParamName] = useState('');
    const [pidforChart, setPidForChart] = useState('');

    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    const loadMeasurementParameters = (patientId) => {
        ApiCalls.getdetails("patientId/" + patientId + "/getmeasurementparameter")
            .then((res) => {
                console.log(res.data);
                if (res.data.measure !== undefined) {
                    setMeasureParameters(res.data.measure[0].paramName)
                }
            })
    }

    useEffect(() => {

        var patientId = secureSessionStorage.getItem("UUID");
        console.log(patientId);
        setPidForChart(patientId);

        loadMeasurementParameters(patientId);
        getParameterName(patientId);
        loadMeasurements(patientId);
        getAssignedDevice()
    }, [])

    const handleOpenParamFilters = (e) => {
        setOpenParamFilter(e.currentTarget);
    };

    const handleCloseParamFilters = () => {
        setOpenParamFilter(null);
    };

    const handleOpenDateFilters = (e) => {
        setOpenDateFilter(e.currentTarget);
    }

    const handleCloseDateFilters = () => {
        setOpenDateFilter(null);
    }

    const handleDateFilterChange = (filter) => {
        setSelectedDateFilter(filter);
        chartMeasurements(pidforChart, selectedParamFilter.label, filter.value)
        handleCloseDateFilters();
    }

    const handleParamFilterChange = (filter) => {
        setSelectedParamFilter(filter);
        chartMeasurements(pidforChart, filter.label, selectedDateFilter.value)
        handleCloseParamFilters();
    }

    const sliderRef = useRef(null);

    {/* -----------------------Slider Settings----------------------------------------------------------------------------------- */ }


    const settings = {
        // sets custom arrow components
        prevArrow: <MaterialArrow direction="prev" />,
        nextArrow: <MaterialArrow direction="next" />,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    {/* -----------------------Parameter Details Listing API----------------------------------------------------------------------------------- */ }

    const getParameterName = (patientId) => {
        ApiCalls.getdetails("patientId/" + patientId + "/getmeasurementparameter")
            .then((res) => {
                console.log(res.data);
                if (res.data.measure !== undefined) {
                    setParamName(res.data.measure[0].paramName)
                    const options = res.data.measure.map(d => ({
                        "label": d.paramName,
                        "value": d.paramid
                    }))
                    var dateFilter = 'today';
                    chartMeasurements(patientId, res.data.measure[0].paramName, dateFilter);
                    setParameters(options);
                    setSelectedParamFilter(options[0])
                } else {
                    setParamName('');
                    setParameters([]);
                    chartMeasurements(patientId, res.data.measure[0].paramName, dateFilter);
                }
            })

    }

    {/* -----------------------Measurement Details Listing API----------------------------------------------------------------------------------- */ }


    const loadMeasurements = (patientId) => {
        var parameterName = 'all';
        ApiCalls.getdetails("patient/" + patientId + "/parameter/" + parameterName + "/getmeasurementdetails")
            .then((res) => {
                if (res.data.measurement !== undefined) {
                    setMeasurements(res.data.measurement);
                    const measurements = res.data.measurement;
                    const averageMeasurements = {};

                    measurements.forEach((measurement) => {
                        const paramName = measurement.paramName;
                        const readingValue = parseFloat(measurement.readingValues);

                        if (!isNaN(readingValue)) {
                            if (!averageMeasurements[paramName]) {
                                averageMeasurements[paramName] = {
                                    total: readingValue,
                                    count: 1,
                                };
                            } else {
                                averageMeasurements[paramName].total += readingValue;
                                averageMeasurements[paramName].count++;
                            }
                        }
                    });

                    const calculatedAverages = {};
                    for (const paramName in averageMeasurements) {
                        calculatedAverages[paramName] =
                            averageMeasurements[paramName].total / averageMeasurements[paramName].count;
                    }

                    // Now calculatedAverages object contains the average measurement for each parameter
                    console.log(calculatedAverages);
                    setAverageValue(calculatedAverages);
                } else {
                    setMeasurements([]);
                    setAverageValue(0);
                }
            })
            .catch((error) => {
                setMeasurements([]);
                setAverageValue(0);
            })
    }

    const averageBPM = averageValue['BPM'];
    const averageSPO2 = averageValue['oxygen_level'];
    const averageTemp = averageValue['Temperature'] || averageValue['Temperature1'];
    const averageBP = averageValue['BP'];

    {/* -----------------------Chart Data Listing API----------------------------------------------------------------------------------- */ }

    const chartMeasurements = async (patientId, parameterName, dateFilter) => {
        const canvas = canvasRef.current;

        if (!canvas) {
            return;
        }

        let labelData = [];
        let chartData = [];

        try {
            const res = await ApiCalls.getdetails(`patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/0`);

            if (!res.data.measurement) {
                chartData = [];
            } else {
                for (const dataObj of res.data.measurement) {
                    labelData.push(moment.utc(Date.parse(dataObj.dateOfReading)).format('MMM-DD hh:mm A'));
                    chartData.push(dataObj.readingValues);
                }
            }
        } catch (err) {
            console.error(err);
            chartData = [];
            labelData = [];
        }

        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new Chart(canvas, {
            type: 'line',
            data: {
                labels: labelData.length > 0 ? labelData : ['No data available'],
                datasets: chartData.length > 0 ? [
                    {
                        label: parameterName + ' () Reading Level',
                        data: chartData,
                        borderColor: '#76B0F1',
                        backgroundColor: 'RGBA(118, 176, 241, 0.5)',
                        fill: true,
                        borderWidth: 0.5,
                        tension: 0.5,
                    },
                ] : [
                    {
                        label: parameterName + ' () Reading Level',
                        data: [0],
                        borderColor: '#76B0F1',
                        backgroundColor: 'RGBA(118, 176, 241, 0.5)',
                        fill: true,
                        borderWidth: 0.5,
                        tension: 0.5,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
        });
    };

    {/* -----------------------Device Details Listing API----------------------------------------------------------------------------------- */ }

    const getAssignedDevice = () => {
        setLoadDevice(true);
        var PatientId = secureSessionStorage.getItem("patientId");
        ApiCalls.getdetails("patient/" + PatientId + "/getdevices?")
            .then((result) => {
                if (result.data.device !== undefined) {
                    setDevice(result.data.device);
                    setLoadDevice(false);
                    setDeviceCount(result.data.device.length)
                }
                else {
                    setDevice([]);
                    setLoadDevice(false);
                    setDeviceCount(0)
                }
            }).catch((error) => {
                setLoadDevice(false);
                setDevice([]);
                setDeviceCount(0)
            });
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container maxWidth="xl" sx={{ marginTop: 15 }} >
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Card
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '200px', // set a fixed height for the card
                                position: 'relative', // set position to relative
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '0',
                                    top: '0',
                                    zIndex: '1', // set z-index to bring the image to the front
                                }}
                            >
                                <img
                                    src={DoctorImg}
                                    alt="Doctor"
                                    width="250px"
                                    height="200px"
                                    style={{ objectFit: 'cover' }}
                                />
                            </Box>
                            <Box sx={{
                                marginLeft: "20%"
                            }}>
                                <Typography variant="h3" gutterBottom>
                                    Hello,{" "}
                                    <span style={{
                                        // color: "#D4AC0D",
                                        color: "#407bff"
                                    }}>
                                        {secureSessionStorage.getItem('LoginUserName') == null
                                            ? ' '
                                            : secureSessionStorage.getItem('LoginUserName')}
                                    </span>
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    Have a nice day and don't forget to take care of your health!
                                </Typography>
                            </Box>
                        </Card>

                    </Grid>
                    <Grid item xs={6} sm={3} md={3} >
                        <Card
                            sx={{
                                py: 5,
                                px: 5,
                                boxShadow: 5,
                                textAlign: 'center',
                                borderRadius: "25px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <StyledHRIcon>
                                    <Avatar src={HeartRate} width={45} height={45} />
                                </StyledHRIcon>
                                <Typography variant="h6" gutterBottom color="error" sx={{ marginLeft: 1 }}>
                                    {averageBPM == undefined ? "--" : averageBPM.toFixed(2)} bpm

                                </Typography>
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                Heart Rate
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Pulse is the most important physiological indicator
                            </Typography>
                        </Card>

                    </Grid>
                    <Grid item xs={6} sm={3} md={3} >
                        <Card
                            sx={{
                                py: 5,
                                px: 5,
                                boxShadow: 5,
                                textAlign: 'center',
                                borderRadius: "25px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <StyledTempIcon>
                                    <Avatar src={Thermometer} width={45} height={45} />
                                </StyledTempIcon>
                                <Typography variant="h6" gutterBottom color="primary" sx={{ marginLeft: 1 }}>
                                    {averageTemp == undefined ? "--" : averageTemp.toFixed(2)} °C
                                </Typography>
                            </Box>

                            <Typography variant="h6" gutterBottom>
                                Temperature
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Temperatures below 35°C (95°F) indicates a serious illness
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} >
                        <Card
                            sx={{
                                py: 5,
                                px: 5,
                                boxShadow: 5,
                                textAlign: 'center',
                                borderRadius: "25px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#2e3192",
                                color: "#fff",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <StyledBPIcon>
                                    <Avatar src={BLoodPressure} width={40} height={40} />
                                </StyledBPIcon>
                                <Typography variant="h6" gutterBottom sx={{ marginLeft: 1 }}>
                                    {averageBP == undefined ? "--" : averageBP}
                                </Typography>
                            </Box>

                            <Typography variant="h6" gutterBottom>
                                Blood Pressure
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Blood pressure can rise and fall several times a day
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} >
                        <Card
                            sx={{
                                py: 5,
                                px: 5,
                                boxShadow: 5,
                                textAlign: 'center',
                                borderRadius: "25px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <StyledGlucoIcon>
                                    <Avatar src={SPO2} width={45} height={45} />
                                </StyledGlucoIcon>
                                <Typography variant="h6" gutterBottom color="#D4AC0D" sx={{ marginLeft: 1 }}>
                                    {averageSPO2 == undefined ? "--" : averageSPO2} %
                                </Typography>
                            </Box>

                            <Typography variant="h6" gutterBottom>
                                SpO2
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                For a healthy individual, the normal SpO2 should be between 96% to 99%
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card
                            sx={{
                                px: 5,
                                boxShadow: 5,
                                textAlign: 'left',
                            }}
                        >
                            <CardHeader title="Graphical Display" action={
                                <>
                                    <Tooltip title="Filter Parameter">
                                        <Button color='primary' variant='contained' onClick={handleOpenParamFilters} endIcon={<Iconify icon="material-symbols:filter-alt" />}>
                                            {selectedParamFilter.label}
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Filter Date">
                                        <Button className='ml-2' color='primary' variant='contained' onClick={handleOpenDateFilters} endIcon={<Iconify icon="material-symbols:filter-list-rounded" />}>
                                            {selectedDateFilter.label}
                                        </Button>
                                    </Tooltip>
                                </>
                            } />
                            <CardContent>
                                <Box sx={{ p: 3, pb: 1, height: 350, borderRadius: "10px" }} dir="ltr" style={{ backgroundColor: '#27293d' }}>
                                    <canvas ref={canvasRef} />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Popover
                        open={Boolean(openDateFilter)}
                        anchorEl={openDateFilter}
                        onClose={handleCloseDateFilters}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        PaperProps={{
                            sx: {
                                p: 1,
                                mt: 1.5,
                                ml: 0.75,
                                width: 180,
                                '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                },
                            },
                        }}
                    >
                        <Stack spacing={0.75}>
                            {filterOptions.map((option) => (
                                <MenuItem key={option.value} selected={option.value === selectedDateFilter.value} onClick={() => handleDateFilterChange(option)}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Stack>
                    </Popover>

                    <Popover
                        open={Boolean(openParamFilter)}
                        anchorEl={openParamFilter}
                        onClose={handleCloseParamFilters}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        PaperProps={{
                            sx: {
                                p: 1,
                                mt: 1.5,
                                ml: 0.75,
                                width: 180,
                                '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                },
                            },
                        }}
                    >
                        <Stack spacing={0.75}>
                            {parameters.map((option) => (
                                <MenuItem key={option.value} selected={option.value === selectedParamFilter.value} onClick={() => handleParamFilterChange(option)}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Stack>
                    </Popover>

                    <Grid item xs={12} sm={12} md={12} >
                        <MeasurementList
                            title="Measurement List"
                            measurements={measurements}
                            setMeasurements={setMeasurements}
                            patientId={pidforChart}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card>
                            <CardHeader title={secureSessionStorage.getItem("DeviceName") + " List"} />
                            <CardContent>
                                {
                                    device.length > 0 ? (
                                        <Grid container spacing={2}>
                                            {device.map((devices) => (
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <Card sx={{
                                                        maxWidth: 300,
                                                        boxShadow: 3,
                                                        '&:hover': {
                                                            border: '2px solid #D1E9FC',
                                                            boxShadow: 'none'
                                                        }
                                                    }}>
                                                        <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title={devices.devicename} />
                                                        <CardMedia
                                                            component="img"
                                                            height="150"
                                                            image={devices.deviceimageName === "null" ? DeviceImg : devices.deviceimageName}
                                                            alt={devices.devicename}
                                                            sx={{ objectFit: 'contain' }}
                                                        />
                                                        <CardContent>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                Device Id : {devices.decmacid}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : (
                                        <center>
                                            No {secureSessionStorage.getItem("DeviceName")} Assigned
                                        </center>
                                    )
                                }

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <div style={{
                    height: "50px"
                }} />
            </Container>
        </div>
    )
}

export default PatientDashboard
