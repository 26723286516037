import React, { useEffect, useState } from 'react';

//mui-components
import { Check, ManageAccounts, SupervisedUserCircle, Widgets } from '@mui/icons-material';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import {
    AppBar,
    Box,
    Button,
    Grid,
    Stack,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    Toolbar,
    Typography
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { makeStyles, withStyles } from '@mui/styles';
//externals
import clsx from "clsx";
import PropTypes from "prop-types";

//internals
import Mastermenu from "./Mastermenu";
import OrganisationStructure from "./OrganisationStructure";
import PatientPrivilege from './PatientPrivilege';
import Rolemenu from "./Rolemenu";

//utils
import AccountPopover from '../../layout/Preview/AccountPopover';
import NotificationsPopover from '../../layout/Preview/NotificationsPopover';
import Searchbar from '../../layout/Preview/SearchBar';
import { bgBlur } from "../../utils/cssStyles";

//components
import Logo from "../../components/logo";

//services
import secureSessionStorage from "../../crypto";
import ApiCalls from "../../services/try";
import EditRolemenu from './EditRolemenu';

//------------------------------------------------------------------------------

const NAV_WIDTH = 0;

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const StyledHeadRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.grey[200] }),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));


const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center"
    },
    active: {
        color: "#784af4"
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    },
    completed: {
        color: "#784af4",
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        "& $line": {
            backgroundImage:
                "linear-gradient(265deg, rgba(30,111,154,1) 0%, rgba(66,134,169,1) 100%)"
        }
    },
    completed: {
        "& $line": {
            backgroundImage:
                "linear-gradient(0deg, rgba(245,67,67,1) 0%, rgba(38,243,50,1) 100%);"
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    active: {
        backgroundImage:
            "radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(0,158,250,1) 35%, rgba(0,212,255,1) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage:
            "linear-gradient(-145deg, rgba(30,111,154,1) 0%, rgba(243,149,38,1) 100%)"
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SupervisedUserCircle />,
        2: <Widgets />,
        3: <ManageAccounts />,
        4: <EditLocationIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

//------------------------------------------------------------------------------

const getSteps = (isMedicalDomainsChecked) => {
    console.log(isMedicalDomainsChecked)
    if (isMedicalDomainsChecked) {
        // If isMedicalDomainsChecked is true, return 3 steps
        return ["Role Selection", "Master Menu", "Role Based Menu", "Patient Privilege"];
    } else {
        // If isMedicalDomainsChecked is false, return 4 steps
        return ["Role Selection", "Master Menu", "Role Based Menu", "Edit Based Menu"];
    }
};

const Wizard = () => {

    const [account, setAccount] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [showNextButton, setShowNextButton] = useState(true);
    const [isMedicalDomainsChecked, setIsMedicalDomainsChecked] = useState(true);

    const steps = getSteps(isMedicalDomainsChecked);



    useEffect(() => {
        let roleId = secureSessionStorage.getItem("roleId");
        console.log("roleId", roleId);

        let Id = secureSessionStorage.getItem("UUID");
        let userId = secureSessionStorage.getItem("userid");
        ApiCalls.getdetails(+ roleId + "/" + Id + "/getdettt")
            .then((response) => {
                console.log("Account details")
                console.log(response.data.userSchemas[0]);
                setAccount(response.data.userSchemas[0]);
            });
    }, []);

    const isStepOptional = (step) => {
        if (step == 5) {
            return step === 5;
        }
        else if (step == 4) {
            return step === 4;
        }
        else if (step == 3) {
            return step === 3;
        }

    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };


    function getStepContent(step) {

        console.log("isMedicalDomainsChecked:", isMedicalDomainsChecked);
        switch (step) {
            case 0:
                return <OrganisationStructure
                    activeStep={activeStep}
                    steps={steps}
                    setActiveStep={setActiveStep}
                    isMedicalDomainsChecked={isMedicalDomainsChecked}
                    setIsMedicalDomainsChecked={setIsMedicalDomainsChecked}
                />;
            case 1:
                return <Mastermenu
                    activeStep={activeStep}
                    steps={steps}
                    setActiveStep={setActiveStep}
                />;
            case 2:
                return <Rolemenu
                    activeStep={activeStep}
                    steps={steps}
                    setActiveStep={setActiveStep}
                    isMedicalDomainsChecked={isMedicalDomainsChecked}
                    setShowNextButton={setShowNextButton}
                />;

            case 3:
                // Conditionally render EditRolemenu based on isMedicalDomainsChecked
                return !isMedicalDomainsChecked ? (
                    <EditRolemenu
                        activeStep={activeStep}
                        steps={steps}
                        setActiveStep={setActiveStep}
                    />
                ) : <PatientPrivilege
                    activeStep={activeStep}
                    steps={steps}
                    setActiveStep={setActiveStep}
                />;

            default:
                return "Unknown Step";
        }
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div style={{ display: "flex" }}>
            <StyledRoot>
                <StyledHeadRoot>
                    <StyledToolbar>
                        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                            <Logo />
                        </Box>
                        <Searchbar />

                        <Box sx={{ flexGrow: 1 }} />

                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={{
                                xs: 0.5,
                                sm: 1,
                            }}
                        >
                            {/* <LanguagePopover /> */}
                            <NotificationsPopover />
                            <AccountPopover account={account} />
                        </Stack>

                    </StyledToolbar>
                </StyledHeadRoot>
            </StyledRoot>


            <Box sx={{
                width: "100%",
                marginTop: 15
            }}>
                {/* <Topbar /> */}
                <div style={{ height: "80%" }}>
                    <Typography variant="h4" sx={{ mb: 5, ml: 3 }}>
                        {secureSessionStorage.getItem("LoginUserName") == null ? "Welcome " : "Welcome " + secureSessionStorage.getItem("LoginUserName")}
                    </Typography>                    <Stepper connector={<ColorlibConnector />} activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption">*Optional</Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps} >
                                    <StepLabel className="font-weight-bold text-muted" StepIconComponent={ColorlibStepIcon} {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Button onClick={handleReset}>
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <div>
                                {
                                    (activeStep == 0) || (activeStep == 1) || (activeStep == 2) || (activeStep == 3)
                                        ? <div>

                                            <Typography style={{ height: "100%", minHeight: "420px" }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs></Grid><Grid item xs >
                                                        {getStepContent(activeStep)}
                                                    </Grid>
                                                    <Grid item xs></Grid>
                                                </Grid>
                                            </Typography>
                                        </div> : <div>
                                            {(activeStep == 5) || (activeStep == 6) ? (<div>
                                                <Typography style={{ marginLeft: "5%", marginRight: "5%", height: "100%" }}>

                                                    {getStepContent(activeStep)}
                                                </Typography>
                                            </div>) : (<div>
                                                <Typography style={{ marginLeft: "25%", height: "100%" }}>

                                                    {getStepContent(activeStep)}
                                                </Typography>
                                            </div>
                                            )}
                                        </div>
                                }
                                <div />
                            </div>
                        )}
                    </div>
                </div>
                {/* <Footer /> */}
            </Box>
        </div>
    )
}

export default Wizard
