import { Box, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';

const BulletPointTextField = ({ value = [], onChange, minrows }) => {
    const textFieldRef = useRef(null); // Reference to the TextField component

    // Initialize with bullet points if the value array is not empty
    const [text, setText] = useState(value.map((item) => `● ${item}`).join("\n") || "● ");
    const [currentMinRows, setCurrentMinRows] = useState(minrows); // State to handle dynamic minRows

    useEffect(() => {
        // When value prop changes, update the text state ensuring bullets
        const newText = value.length ? value.map((item) => `● ${item}`).join("\n") : "● ";
        setText(newText);

        // Dynamically adjust the minRows based on content length
        setCurrentMinRows(value.length ? Math.min(value.length, minrows) : 1);
    }, [value, minrows]);

    // Handle input changes
    const handleInputChange = (e) => {
        const newText = e.target.value;
        setText(newText);

        // Convert back to an array and pass to the parent without trimming spaces inside sentences
        const newValue = newText.split("\n").map(line => line.replace(/^●\s*/, '')).filter(line => line); // Remove bullet points but keep spaces
        onChange(newValue);

        // Adjust minRows dynamically based on content length
        setCurrentMinRows(newValue.length ? Math.min(newValue.length, minrows) : 1);
    };

    // Handle key down event to manage "Enter" key behavior
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default Enter key behavior

            // Calculate the current caret position
            const caretPosition = e.target.selectionStart;
            const beforeCaret = text.substring(0, caretPosition); // Text before the caret
            const afterCaret = text.substring(caretPosition); // Text after the caret

            // Create a new line with a bullet point and update the state
            const newText = `${beforeCaret}\n● ${afterCaret}`;
            setText(newText);

            // Use a callback to ensure the text state is updated before setting the caret
            setTimeout(() => {
                const newCaretPosition = caretPosition + 3; // Adjust position after "● "
                if (textFieldRef.current) {
                    textFieldRef.current.focus(); // Ensure focus before setting caret
                    textFieldRef.current.setSelectionRange(newCaretPosition, newCaretPosition);
                }
            }, 0);
        }
    };

    return (
        <Box sx={{ width: '100%', backgroundColor: '#091423', padding: 1, borderRadius: 2 }}>
            <TextField
                multiline
                variant="outlined"
                fullWidth
                placeholder="Enter bullet points..."
                value={text}
                minRows={currentMinRows}
                maxRows={10} // Set a reasonable maximum number of rows
                inputRef={textFieldRef} // Attach the ref to the TextField
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        padding: 1,
                        color: 'white',
                        fontFamily: 'Martel Sans',
                        fontSize: '14px',
                        lineHeight: '1.6', // Adjust line height for better spacing
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#009FE3',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00BFFF',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00BFFF',
                        },
                    },
                }}
                sx={{ width: '100%' }}
            />
        </Box>
    );
};

export default BulletPointTextField;
