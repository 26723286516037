import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

//mui-components
import { Close } from '@mui/icons-material';
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';

//externals
import { Icon } from '@iconify/react';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled from 'styled-components';
import secureSessionStorage from "../../crypto";

//services
import ApiCalls from "../../services/try";

//internals
import Iconify from '../../components/iconify/Iconify';
import LiveLineChart from './LiveLineChart';

//-------------------------------------------------------------------------------------------

const Container = styled.div`
  background-color: #1F253D;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

// Updated styles for the App bar
const StyledAppBar = styled(AppBar)`
  background-color: #1F253D;
  border-radius: 8;
  z-index: 1000; // Adjust the value as needed
`;


// Updated styles for the DeviceGroup and ChartItem
const DeviceGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1F253D;  // Use the same color as the App bar
  padding: 20px;
  border-radius: 0;  // Remove border-radius
  color: white;
  width: 100%;
  overflow: hidden;
  margin-top: ${(props) => (props.isTop ? '65px' : '5px')};
`;

const ChartItem = styled.div`
  border-radius: 12px;  // Remove border-radius
  padding: 10px;
  margin: 10px;
  color: white;
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
`;

const ParameterItem = styled.div`
  flex: 4;
  margin: 5px;
  height: 200px;
  border-radius: 8px;
  padding: 10px;
  background-color: #394264;
  color: white;
`;

const StyledLabelDisplayItem = styled.div`
flex: 1;
margin: 5px;
height: 200px;
border-radius: 8px;
padding: 10px;
background-color: #394264;
color: white;
`;

//---------------------------------------------------------------------------------------------

const PatientCMS = () => {

    const location = useLocation();

    const [cmsJSON, setCmsJSON] = useState(null);
    const [open, setOpen] = useState(null);

    const [patientId, setPatientId] = useState('')

    const [redirectToView, setRedirectToView] = useState(false);
    const [loadPatientDetails, setLoadPatientDetails] = useState(true);
    const [cmsDetailsLoaded, setCmsDetailsLoaded] = useState(false);
    const [apiStatus, setApiStatus] = useState(false);

    const [patientDetails, setPatientDetails] = useState([]);
    const [cmsDetails, setCmsDetails] = useState([]);
    const [paramValues, setParamValues] = useState({});

    const LabelDisplayItem = ({ paramValues, display }) => {
        console.log(paramValues);
        console.log(display);

        const paramName1 = paramValues.paramName;
        const paramName2 = display.LabelDisplay[0].ParamName;
        const paramValue = paramValues.value;

        console.log(paramName1 + "--" + paramName2);

        return (
            <StyledLabelDisplayItem className="label-box">
                {display.LabelDisplay ? (
                    display.LabelDisplay.map((label, labelIndex) => (
                        <div key={labelIndex}>
                            <Typography variant="subtitle2">
                                {label.ParamName}:
                            </Typography>
                            <Typography variant="h1" sx={{
                                color: "rgba(75,192,192,1)",
                                textAlign: 'center', // Center the content
                            }}>
                                {paramName1 == paramName2 ? paramValue : "-?-"}
                            </Typography>
                        </div>
                    ))
                ) : (
                    <div>
                        <Typography variant="subtitle2">--</Typography>
                    </div>
                )}
            </StyledLabelDisplayItem>
        );
    };


    const loadPatientDetailsById = (patientId) => {
        setLoadPatientDetails(true);
        ApiCalls.getdetails("getpatient/" + patientId + "/patients")
            .then((response) => {
                console.log(response.data);
                if (response.data.patient != undefined) {
                    setPatientDetails(response.data.patient[0]);
                    setLoadPatientDetails(false);
                } else {
                    setLoadPatientDetails(false);
                    setPatientDetails([]);
                }
            })
    }

    const loadCmsDetailsById = (patientId) => {
        ApiCalls.getdetails("patient/" + patientId + "/getCmsDetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.cms != undefined) {
                    setCmsDetails(response.data.cms);
                    setCmsDetailsLoaded(true);
                } else {
                    setCmsDetails([]);
                    setCmsDetailsLoaded(false);
                }
            }).catch((err) => {
                console.log(err);
                setCmsDetails([]);
                setCmsDetailsLoaded(false);
            })
    }

    useEffect(() => {
        const patientid = secureSessionStorage.getItem("PatientToView")
        setPatientId(patientid)
        loadPatientDetailsById(patientid);
        loadCmsDetailsById(patientid)
    }, [])


    const handleSettingsClick = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleExitPatientCMS = () => {
        setRedirectToView(true);
    }

    const handleSelectCms = (cms) => {
        ApiCalls.getdetails("cmsId/" + cms.cmsDevId + "/patientId/" + patientId + "/getcmsjson")
            .then((response) => {
                console.log(response.data);
                if (response.data !== undefined) {
                    setCmsJSON(response.data);
                    setCmsDetailsLoaded(false);
                    setApiStatus(true);
                } else {
                    setCmsJSON([]);
                    setApiStatus(false);
                }
            })
            .catch((err) => {
                console.error(err);
                setCmsJSON([]);
                setApiStatus(false);
            });
    }

    // Group the beds based on device name
    const groupedBeds = {};
    if (cmsJSON && cmsJSON.BEDS) {
        cmsJSON.BEDS.forEach((bed) => {
            const deviceName = bed.Display[0].DevName;
            if (!groupedBeds[deviceName]) {
                groupedBeds[deviceName] = {
                    beds: [],
                    deviceName: deviceName,
                };
            }
            groupedBeds[deviceName].beds.push(bed);
        });
    }

    const handleCloseDialog = () => {
        setRedirectToView(true);
    }

    if (redirectToView) {
        return <Redirect to={'/managepatient'} />;
    }

    return (
        <Container>
            <StyledAppBar position="absolute">
                <Toolbar>
                    {loadPatientDetails ? (
                        <Iconify icon={'eos-icons:three-dots-loading'} />
                    ) : (
                        <Typography variant="h5" sx={{ flexGrow: 1 }}>
                            <b>{patientDetails?.firstName?.toUpperCase()} {patientDetails?.lastName?.toUpperCase()}</b>
                        </Typography>
                    )}


                    <IconButton onClick={handleSettingsClick} onMouseDown={(e) => e.preventDefault()}>
                        <Icon icon={'solar:monitor-smartphone-bold'} width="40" color="#ECF0F1" />
                    </IconButton>
                </Toolbar>
            </StyledAppBar>

            {cmsDetailsLoaded && cmsDetails.length === 0 && (
                <Typography variant="h6" sx={{ color: 'white', marginTop: 2 }}>
                    No CMS connected
                </Typography>
            )}

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem sx={{ m: 1 }}>
                    <ListItemIcon>
                        <Iconify icon={'eva:settings-2-fill'} />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={handleExitPatientCMS} sx={{ m: 1 }}>
                    <ListItemIcon>
                        <Iconify icon={'majesticons:logout'} />
                    </ListItemIcon>
                    Exit
                </MenuItem>
            </Popover>

            {cmsDetailsLoaded && cmsDetails.length > 0 && (
                <Dialog
                    open={true}
                    maxWidth="sm"
                    PaperProps={{
                        style: {
                            backgroundColor: 'rgba(31, 37, 61, 0.8)',
                        },
                    }}
                >
                    <DialogTitle sx={{ color: 'white' }}>
                        <span>{cmsDetails.length > 0 ? 'Select CMS' : 'No CMS available'}</span>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onMouseDown={(e) => e.preventDefault()}
                            sx={{
                                float: 'right'
                            }}
                            onClick={() => handleCloseDialog()} // Add a function to handle closing the dialog
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent sx={{ textAlign: 'center', color: 'white' }}>
                        {cmsDetails.length > 0 ? (
                            <List>
                                {cmsDetails.map((cms, index) => (
                                    <ListItem button key={index} onClick={() => handleSelectCms(cms)}>
                                        <ListItemIcon>
                                            <Icon icon={'ci:list-add'} color="white" />
                                        </ListItemIcon>
                                        <ListItemText primary={cms.cmsName} />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body1">
                                {`There are no CMS details available for the patient.`}
                            </Typography>
                        )}
                    </DialogContent>
                </Dialog>
            )}


            {apiStatus && cmsJSON.BEDS.length === 0 && (
                <Typography variant="h6" sx={{ color: 'white', marginTop: 2 }}>
                    Patient not admitted in Bed
                </Typography>
            )}

            {apiStatus && cmsJSON.BEDS.length === 0 && (
                <Typography variant="h6" sx={{ color: 'white', marginTop: 2 }}>
                    Patient not admitted in Bed
                </Typography>
            )}

            {apiStatus && cmsJSON.BEDS.length > 0 &&
                Object.keys(groupedBeds).map((deviceName, index) => (
                    <DeviceGroup key={index} isTop={index === 0}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Typography variant="subtitle1" component="div" sx={{ marginRight: 5 }}>
                                <b>Device Name: {deviceName}</b>
                            </Typography>
                            <Typography variant="subtitle1" component="div" sx={{ marginRight: 5 }}>
                                <b>Ward No: {groupedBeds[deviceName].beds[0].WardNo}</b>
                            </Typography>
                            <Typography variant="subtitle1" component="div">
                                <b>Bed No: {groupedBeds[deviceName].beds[0].BedNo}</b>
                            </Typography>
                        </Stack>

                        <ChartItem>
                            <div className="box-container">
                                {groupedBeds[deviceName].beds.map((bed, bedIndex) => (
                                    <div key={bedIndex} className="box">
                                        {bed.Display.map((display, displayIndex) => (
                                            <Stack key={displayIndex} sx={{ flexDirection: 'row' }}>
                                                <ParameterItem className="parameter-box">
                                                    <Typography variant="subtitle2">{display.ParamName}</Typography>
                                                    <LiveLineChart
                                                        parameterName={display.ParamName}
                                                        onParamValueChange={(updatedValues) => {
                                                            setParamValues((prevValues) => ({ ...prevValues, ...updatedValues }));
                                                        }}
                                                    />
                                                </ParameterItem>
                                                <LabelDisplayItem paramValues={paramValues} display={display} />

                                            </Stack>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </ChartItem>
                    </DeviceGroup>
                ))
            }
        </Container>
    );
}

export default PatientCMS;
