import React, { useEffect, useState } from 'react';
// import Apicalls from '../../services/try';
import { Dialog } from '@mui/material';
import downarrow from "../../assets/images/downarrow.png";
import uparrow from "../../assets/images/uparrow.png";
import AlertBox from './AlertBox';

function DialogBox({ index, openDialog, setSlide, slide, setOpenDialog, setIndex }) {

    // const [GraphTypeSelected, setGraphTypeSelected] = useState(0);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState('');
    console.log("openDialog", openDialog);
    console.log("slide in Dialog", slide);

    const [slide_copy, setSlide_Copy] = useState([]);
    useEffect(() => {
        setSlide_Copy(JSON.parse(JSON.stringify(slide)));
    }, [slide]);
    console.log("slide_copy123", slide_copy, slide);

    useEffect(() => {
        // setGraphTypeSelected(index);
        setIndex(index);
    }, [index]);

    const limit = [
        { min: 30, max: 295 },
        { min: 70, max: 100 },
        { min: 8, max: 35 }
    ];
    const slideInfo = [
        {
            color: '#7CFC00',
            range: {
                min: slide[0].min, max: slide[0].max
            },
            tag: 'Ecg',
            value: 'HR',
            unit: 'bpm'

        },
        {
            color: '#009FE3',
            range: {
                min: slide[1].min, max: slide[1].max
            },
            tag: 'SpO₂',
            value: 'SPO2',
            unit: '%'
        },
        {
            color: '#F2B809',
            range: {
                min: slide[2].min, max: slide[2].max
            },
            tag: 'RR',
            value: 'RR',
            unit: 'rpm'
        },
    ];

    const handleminChange = (e, index) => {
        const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
        if (newValue === '' || (!isNaN(newValue) && newValue >= 0 && newValue <= 295)) {
            setSlide_Copy(prevSlide => prevSlide.map((item, idx) =>
                idx === index ? { ...item, min: newValue } : item
            ));
        }
    };

    const handlemaxChange = (e, index) => {
        const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
        if (newValue === '' || (!isNaN(newValue) && newValue >= 0 && newValue <= 295)) {
            setSlide_Copy(prevSlide => prevSlide.map((item, idx) =>
                idx === index ? { ...item, max: newValue } : item
            ));
        }
    };

    const increment = (type) => {
        setSlide_Copy(prevSlide => prevSlide.map((item, idx) => {
            if (idx !== index) return item;
            const newValue = type === 'min' ? item.min + 1 : item.max + 1;
            const limitedValue = newValue > limit[idx].max ? limit[idx].max : newValue;
            return { ...item, [type]: limitedValue };
        }));
    };

    const decrement = (type) => {
        setSlide_Copy(prevSlide => prevSlide.map((item, idx) => {
            if (idx !== index) return item;
            const newValue = type === 'min' ? item.min - 1 : item.max - 1;
            const limitedValue = newValue < limit[idx].min ? limit[idx].min : newValue;
            return { ...item, [type]: limitedValue };
        }));
    };


    const savetoDB = () => {
        slideInfo.map((item, index) => {
            console.log("Save to database", slideInfo[index].value);
            // setOpenDialog(false);
            if (slide_copy[index].min < limit[index].min || slide_copy[index].min > limit[index].max) {
                // alert(`${slideInfo[index].value} Values Entered not within Range`);
                setMessage(`${slideInfo[index].value} Values Entered not within Range`);
                setOpenAlert(true);
                let oldobject = slide_copy;
                oldobject[index].min = limit[index].min;
                setSlide_Copy(oldobject);
                setOpenDialog(true);
            } else if (slide_copy[index].max < limit[index].min || slide_copy[index].max > limit[index].max) {
                // alert(`${slideInfo[index].value} Values Entered not within Range`);
                setMessage(`${slideInfo[index].value} Values Entered not within Range`);
                setOpenAlert(true);
                let oldobject = slide_copy;
                oldobject[index].max = limit[index].max;
                setSlide_Copy(oldobject);
                setOpenDialog(true);
            }
            else {
                if (slide_copy[0].min < slide_copy[0].max && slide_copy[1].min < slide_copy[1].max && slide_copy[2].min < slide_copy[2].max) {
                    console.log("IF condition")
                    const thresholddata = {
                        "min": slide_copy[index].min,
                        "max": slide_copy[index].max
                    }
                    // savetoDatabase(thresholddata, index);
                    setOpenDialog(false);
                    let datatosend = { thresholddata, "index": index }
                    // sendMessageToReactNative();
                    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(datatosend));
                        // alert("old: " + JSON.stringify(slide));
                        setSlide(prevSlide => prevSlide.map((item, idx) => {
                            if (idx !== index) {
                                return item;
                            } else {
                                const thresholddata = {
                                    "min": slide_copy[index].min,
                                    "max": slide_copy[index].max
                                };
                                return { ...item, ...thresholddata };
                            }
                        }));
                    } else {
                        console.error("ReactNativeWebView is not defined");
                    }
                }
                else {
                    if (index == 0 && slide_copy[0].min >= slide_copy[0].max)
                        setMessage(`Min value of HR cannot be greater than or equal to Max value`);
                    else if (index == 1 && slide_copy[1].min >= slide_copy[1].max)
                        setMessage(`Min value of SpO₂ cannot be greater than or equal to Max value`);
                    else if (index == 2 && slide_copy[2].min >= slide_copy[2].max)
                        setMessage(`Min value of RR cannot be greater than or equal to Max value`);
                    setOpenAlert(true);
                    setOpenDialog(true);
                }
            }
        });
    }

    return (
        <>
            {/* <Dialog open={openDialog} onClose={() => { setOpenDialog(false); savetoDB(); }}> */}
            <Dialog open={openDialog} onClose={() => { savetoDB(); }}>
                {
                    slide_copy.length > 0 && (
                        <div style={{ backgroundColor: '#080f18' }}>
                            <div style={{ display: 'flex', paddingTop: '20px' }}>
                                <button onClick={() => { setIndex(0) }} style={{ ...styles.button, backgroundColor: (index == 0 ? "rgba(59, 224, 65, 1)" : 'grey') }}>
                                    HR
                                </button>
                                <button onClick={() => { setIndex(1) }} style={{ ...styles.button, backgroundColor: (index == 1 ? "rgba(0, 159, 227, 1)" : 'grey') }}>
                                    SPO2
                                </button>
                                <button onClick={() => { setIndex(2) }} style={{ ...styles.button, backgroundColor: (index == 2 ? "rgba(242, 184, 9, 1)" : 'grey') }}>
                                    RR
                                </button>
                            </div>
                            <div style={styles.dialog}>
                                <div style={{ alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', color: 'white' }}><p>Min Value</p></div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button onClick={() => decrement('min')} disabled={slide_copy[index].min <= limit[index].min} style={{ ...styles.smallbutton, paddingTop: '1px' }}>
                                            <img src={downarrow} class="image" alt="downarrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                        </button>
                                        <input
                                            type="text"
                                            value={slide_copy[index].min}
                                            onChange={(e) => handleminChange(e, index)}
                                            style={{ textAlign: 'center', width: '50px', backgroundColor: '#1D252E', color: 'white', borderColor: 'transparent', border: 'none', background: 'none', outline: 'none' }}
                                        />
                                        <button onClick={() => increment('min')} disabled={slide_copy[index].min >= limit[index].max} style={{ ...styles.smallbutton, paddingBottom: '4px' }}>
                                            <img src={uparrow} class="image" alt="uparrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                        </button>
                                    </div>
                                    <div style={{ textAlign: 'center', color: 'white', paddingTop: 10 }}><p>Max Value</p></div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button onClick={() => decrement('max')} disabled={slide_copy[index].max <= limit[index].min} style={{ ...styles.smallbutton, paddingTop: '1px' }}>
                                            <img src={downarrow} class="image" alt="downarrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                        </button>
                                        <input
                                            type="text"
                                            value={slide_copy[index].max}
                                            onChange={(e) => handlemaxChange(e, index)}
                                            style={{ textAlign: 'center', width: '50px', backgroundColor: '#1D252E', color: 'white', borderColor: 'transparent', border: 'none', background: 'none', outline: 'none' }}
                                        />
                                        <button onClick={() => increment('max')} disabled={slide_copy[index].max >= limit[index].max} style={{ ...styles.smallbutton, paddingBottom: '4px' }}>
                                            <img src={uparrow} class="image" alt="uparrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '25px' }}>
                                        <button onClick={() => { savetoDB() }} style={{ border: 'none', background: 'none', outline: 'none', borderRadius: '12px', backgroundColor: "#009FE3", width: '100px', marginLeft: '20px' }}>
                                            SAVE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Dialog >
            <AlertBox openAlert={openAlert} message={message} setOpenAlert={setOpenAlert} />
        </>
    )
}

export default DialogBox;

const styles = {
    dialog: {
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        height: '300px', // Fixed height
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        borderRadius: '10px', border: 'none', background: 'none', marginLeft: '10px', width: '120px', outline: 'none'
    },
    smallbutton: {
        border: 'none', background: 'none', outline: 'none', margin: '2px',
        backgroundColor: '#009FE3', marginLeft: '10px', marginRight: '10px', height: '26px', width: '26px',
        paddingLeft: '4px', paddingRight: '4px',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'center'
    }
};