import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

//internals
import DeviceImg from "../../assets/images/devices.png";
import Preview from "../../layout/Preview";

//mui-components
import {
    FirstPage,
    Home,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { blue } from '@mui/material/colors';
import { emphasize, styled, useTheme } from "@mui/material/styles";

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import secureSessionStorage from "../../crypto";
// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        "&:hover, &:focus": {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const CHART_HEIGHT = 270;
const LEGEND_HEIGHT = 65;

// ----------------------------------------------------------------------

const DeviceHistory = () => {
    const sliderRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [loadPatients, setLoadPatients] = useState(false);
    const [totalLogs, setTotalLogs] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [searchTerm, setSearchTerm] = useState('');
    const [deviceId, setDeviceId] = useState("")
    const [expandedRow, setExpandedRow] = useState(null); // State to track the expanded row

    const [assignedPatients, setAssignedPatients] = useState([]);
    const [deviceHistory, setDeviceHistory] = useState([]);
    const [deviceLogs, setDeviceLogs] = useState([]);

    const loadDeviceAsignedPatients = (deviceId) => {
        setLoadPatients(true);
        ApiCalls.getdetails("device/" + deviceId + "/getpatientsassigned").then(
            (response) => {
                console.log(response.data);
                if (response.data.patient !== undefined) {
                    setAssignedPatients(response.data.patient);
                    setLoadPatients(false);
                } else {
                    setAssignedPatients([]);
                    setLoadPatients(false);
                }
            }
        );
    };

    const loadDeviceHistory = (deviceId) => {
        var tenantId = secureSessionStorage.getItem("tenantId");

        ApiCalls.getdetails("tenant/" + tenantId + "/deviceid/" + deviceId + "/showdevicehistory")
            .then((response) => {
                console.log(response.data.device[0]);
                if (response.data.device !== undefined) {
                    setDeviceHistory(response.data.device[0]);
                    setDeviceId(response.data.device[0].deviceUUId)
                } else {
                    setDeviceHistory([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching device details:", error);
                setDeviceHistory([]);
            });
    }

    const loadDeviceLogs = () => {
        const deviceId = secureSessionStorage.getItem("deviceIdToEdit");
        setLoading(true);
        ApiCalls.getdetails(`device/${deviceId}/viewVitalsImageLogs?page=${page}&size=${rowsPerPage}`)
            .then((response) => {
                console.log(response.data.content);
                if (response.data.content != undefined) {
                    setDeviceLogs(response.data.content);
                    setTotalLogs(response.data.totalElements); // for pagination
                    setLoading(false);
                } else {
                    setDeviceLogs([]);
                    setTotalLogs(0); // for pagination
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error("Error fetching device logs:", error);
                setDeviceLogs([]);
                setTotalLogs(0); // for pagination
                setLoading(false);
            });
    };

    useEffect(() => {
        var deviceId = secureSessionStorage.getItem("deviceIdToEdit");
        loadDeviceLogs(deviceId);
    }, [page, rowsPerPage]);

    const handleExpandClick = (logId) => {
        setExpandedRow(expandedRow === logId ? null : logId); // Toggle expanded state
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        var deviceId = secureSessionStorage.getItem("deviceIdToEdit");
        var deviceUUId = secureSessionStorage.getItem("deviceIdSelected");
        loadDeviceAsignedPatients(deviceId);
        loadDeviceHistory(deviceUUId);
    }, []);

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button
                                variant="text"
                                startIcon={<Home fontSize="small" />}
                                onMouseDown={handleMouseDown}
                                sx={{ mr: 1 }}
                            >
                                Home
                            </Button>
                        </Link>

                        <Link to="/managedevice">
                            <Button
                                variant="text"
                                startIcon={
                                    <Icon icon={"tabler:devices-plus"} fontSize="small" />
                                }
                                onMouseDown={handleMouseDown}
                                sx={{ mr: 1 }}
                            >
                                {"Manage " + secureSessionStorage.getItem("DeviceName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb
                            label={secureSessionStorage.getItem("DeviceName") + " History"}
                        />
                    </Breadcrumbs>
                </Stack>

                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h5" gutterBottom>
                                {secureSessionStorage.getItem("DeviceName") + " History"}
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Avatar
                                    variant="rounded"
                                    alt={deviceHistory.deviceName}
                                    src={
                                        deviceHistory.deviceimageName == undefined ||
                                            deviceHistory.deviceimageName === "null"
                                            ? DeviceImg
                                            : deviceHistory.deviceimageName
                                    }
                                    sx={{ width: "40%", height: "auto", objectFit: "contain" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        {deviceHistory.deviceName && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Device Name :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.deviceName.toUpperCase()}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.devmacid && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Device MacId :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.devmacid}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.osVersion && deviceHistory.osVersion !== "NULL" && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    OS Version :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.osVersion}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {deviceHistory.createdOn && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Registered On :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {new Date(parseInt(deviceHistory.createdOn)).toISOString().split('T')[0]}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.companyName && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Enrolled To :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.companyName === "NULL" ? "Not Enrolled" : deviceHistory.companyName}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.enrolledDate && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Enrolled On :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.enrolledDate !== "NULL" ? deviceHistory.enrolledDate.split(' ')[0] : "--"}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card sx={{
                    minWidth: 300,
                    marginTop: 2,
                }}>
                    <CardHeader
                        title={<Typography variant="h5" gutterBottom>
                            {"Assigned " + secureSessionStorage.getItem("PatientName")}
                        </Typography>}
                    />

                    <CardContent sx={{ maxHeight: 300 }}>
                        {loadPatients ? (
                            <center>
                                <CircularProgress />
                            </center>
                        ) : (
                            <Grid container spacing={2}>
                                {
                                    assignedPatients.length > 0 ?
                                        assignedPatients.map((patient) => (
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Card sx={{
                                                    position: 'relative',
                                                    boxShadow: 3,
                                                    '&:hover .delete-icon': {
                                                        display: 'block',
                                                    },
                                                }}>
                                                    <CardHeader
                                                        avatar={
                                                            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                                                                <Avatar
                                                                    alt={patient.firstName + " " + patient.lastName}
                                                                    src={patient.image}
                                                                    sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                        {patient.firstName && patient.lastName && (
                                                                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                {patient.firstName + " " + patient.lastName}
                                                                            </Typography>
                                                                        )}
                                                                        {patient.email !== "null" && (
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                <Icon icon={'gridicons:mail'} /> {patient.email}
                                                                            </Typography>
                                                                        )}

                                                                        {patient.phone && (
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                <Icon icon={'ic:baseline-phone'} /> {patient.phone}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        } />
                                                </Card>
                                            </Grid>
                                        )) :
                                        <CardHeader
                                            avatar={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {"No " + secureSessionStorage.getItem("PatientName") + " Assigned"}
                                                </Box>
                                            } />
                                }
                            </Grid>
                        )}
                    </CardContent>
                </Card>

                <Card sx={{ mt: 1, mb: 5 }}>
                    <CardHeader
                        title={<Typography variant="h5" gutterBottom>Logs</Typography>}
                        action={
                            <TextField
                                color='primary'
                                label={"Search Measurement Id"}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer component={Paper}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>#</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Date TIme</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Response</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Prompt</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>File</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}></TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {deviceLogs.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Typography variant="body1">No logs available</Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            deviceLogs.map((log) => {
                                                const logDateTime = new Date(log.timestamp).toLocaleString(undefined, {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true,
                                                });

                                                const isExpanded = expandedRow === log.id;
                                                const jsonContent = log.jsonResponse ? (
                                                    <pre style={{ whiteSpace: 'pre-wrap', textAlign: 'left', maxHeight: isExpanded ? 'none' : '50px', overflow: 'hidden' }}>
                                                        {JSON.stringify(JSON.parse(log.jsonResponse), null, 2)}
                                                    </pre>
                                                ) : '-/-';

                                                return (
                                                    <React.Fragment key={log.id}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: "center" }}>{log.id}</TableCell>
                                                            <TableCell sx={{ textAlign: "center" }}>{logDateTime}</TableCell>
                                                            <TableCell sx={{ textAlign: "center", maxWidth: 220, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                {log.jsonResponse || '-/-'}
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: "center", maxWidth: 220, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                {log.prompt || "- -"}
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: "center" }}>
                                                                {log.fileName || "N/A"}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip title={isExpanded ? 'Collapse' : 'Expand'}>
                                                                    <IconButton color="primary" onMouseDown={handleMouseDown} onClick={() => handleExpandClick(log.id)}>
                                                                        <Icon icon={isExpanded ? "material-symbols:expand-circle-up-outline-rounded" : "material-symbols:expand-circle-down-outline"} width="25" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                        {isExpanded && (
                                                            <TableRow>
                                                                <TableCell colSpan={4}>
                                                                    <Box sx={{ padding: 2, backgroundColor: "#f9f9f9" }}>
                                                                        <Typography variant="subtitle1">JSON Response:</Typography>
                                                                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                                            {JSON.stringify(JSON.parse(log.jsonResponse), null, 2)}
                                                                        </pre>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell colSpan={6}>
                                                                    <Box sx={{ padding: 2, backgroundColor: "#f9f9f9" }}>
                                                                        <Typography variant="subtitle1">Prompt:</Typography>
                                                                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                                            {log.prompt || '-/-'}
                                                                        </pre>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    sx={{
                                        '.MuiButtonBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                            '&:active': {
                                                outline: 'none',
                                                boxShadow: 'none',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        },
                                    }}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={totalLogs}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        )}
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};

export default DeviceHistory;
