import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//internals
import HospitalImg from "../../assets/images/avatars/hospital.png";
import Preview from '../../layout/Preview';

//mui-components
import { Cancel, Edit, Home } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//-----------------------------------------------------------------------------

const EditCompany = () => {

    const [image, setImage] = useState(HospitalImg)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(2);
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [showIcon, setShowIcon] = useState(false);
    const [streetError, setStreetError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [postalError, setPostalError] = useState(false);

    const loadCompanyDetailsById = (companyId) => {
        var tenantId = secureSessionStorage.getItem('tenantId');
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/getCompanyDetails")
            .then((response) => {
                console.log(response.data.companies)
                const data = response.data.companies[0];
                setName(data.name);
                setEmail(data.email);
                setPhone(data.phone);
                setImage(data.image);
                setStreet(data.address.street);
                setCity(data.address.city);
                setState(data.address.state);
                setPostalCode(data.address.postalcode);
                setCountry(data.address.country);
            })
    }

    useEffect(() => {
        const companyId = secureSessionStorage.getItem("companyIdToEdit");
        console.log(companyId)

        loadCompanyDetailsById(companyId);

    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'name':
                    setNameError(true);
                    break;
                case 'email':
                    setEmailError(true);
                    break;
                case 'phone':
                    setPhoneError(true);
                    break;
                case 'street':
                    setStreetError(true);
                    break;
                case 'city':
                    setCityError(true);
                    break;
                case 'postalCode':
                    setPostalError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'name':
                setNameError(false);
                setName(event.target.value);
                break;
            case 'email':
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhoneError(false);
                setPhone(event.target.value);
                break;
            case 'street':
                setStreet(event.target.value);
                setStreetError(false);
                break;
            case 'city':
                setCity(event.target.value);
                setCityError(false);
                break;
            case 'state':
                setState(event.target.value);
                break;
            case 'country':
                setCountry(event.target.value);
                break;
            case 'postalCode':
                setPostalCode(event.target.value);
                setPostalError(false);
                break;
            default:
                break;
        }
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdate = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        let hasError = false;

        if (!name) {
            setNameError(true);
            hasError = true;
        } else {
            setNameError(false);
        }

        if (!email || !emailRegex.test(email)) {
            setEmailError(true);
            hasError = true;
        } else {
            setEmailError(false);
        }

        if (!phone || !phoneRegex.test(phone)) {
            setPhoneError(true);
            hasError = true;
        } else {
            setPhoneError(false);
        }

        if (!street) {
            setStreetError(true);
            hasError = true;
        } else {
            setStreetError(false);
        }

        if (!city) {
            setCityError(true);
            hasError = true;
        } else {
            setCityError(false);
        }

        if (!postalCode) {
            setPostalError(true);
            hasError = true;
        } else {
            setPostalError(false);
        }

        // If there are no errors, proceed with the API call
        if (!hasError) {
            const tenantId = secureSessionStorage.getItem("tenantId");
            const companyId = secureSessionStorage.getItem("companyIdToEdit");
            const address = {
                street: street,
                postalcode: postalCode,
                city: city,
                state: state,
                country: country
            };
            const data = {
                tenantId: tenantId,
                name: name,
                email: email,
                phone: phone,
                address: address,
                image: image
            };

            ApiCalls.edit("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/UpdateDetails", data)
                .then((response) => {
                    console.log(companyId, "company id found");
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal(secureSessionStorage.getItem("CompanyName") + " Successfully Updated", {
                            icon: "success",
                        });
                        setRedirectToList(true);
                    } else {
                        setRedirectToList(true);
                    }
                });
        }
    };

    if (redirectToList) {
        return (
            <Redirect to={'/managecustomer'} />
        )
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managecustomer">
                            <Button variant="text" startIcon={<Icon icon={'tabler:building-hospital'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureSessionStorage.getItem("CompanyName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Edit " + secureSessionStorage.getItem("CompanyName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Edit " + secureSessionStorage.getItem("CompanyName")}
                        </Typography>}
                    />
                    <CardContent>

                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={image}
                                            variant="rounded"
                                            sx={{
                                                marginLeft: 5,
                                                width: 150,
                                                height: 150,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    transform: 'scale(1.1)',
                                                },
                                            }}
                                            onClick={() => document.getElementById('avatar-input').click()}
                                            onMouseEnter={() => setShowIcon(true)}
                                            onMouseLeave={() => setShowIcon(false)}
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="avatar-input"
                                            onChange={handleImageUpload}
                                            style={{ display: 'none', }}
                                        />
                                    </Item>
                                </Box>
                                <Box gridColumn="span 8">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                            <TextField
                                                name="name"
                                                label={
                                                    <span>
                                                        {secureSessionStorage.getItem("CompanyName") + " Name"}{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                value={name}
                                                onChange={handleChange}
                                                fullWidth
                                                error={nameError}
                                                helperText={nameError && "Name is required"}
                                            />
                                            <TextField
                                                name="email"
                                                label={
                                                    <span>
                                                        Email{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                fullWidth
                                                value={email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={emailError}
                                                helperText={emailError && "Email address is required"}
                                            />
                                            <TextField
                                                fullWidth
                                                name="phone"
                                                value={phone}
                                                label={
                                                    <span>
                                                        Phone{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={phoneError}
                                                helperText={phoneError && "Phone Number is required"}
                                            />
                                        </Stack>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="street"
                                                value={street}
                                                label={
                                                    <span>
                                                        Street Address{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onChange={handleChange}
                                                fullWidth
                                                error={streetError}
                                                helperText={streetError && "Street Address is required"}
                                            />
                                            <TextField
                                                name="city"
                                                value={city}
                                                label={
                                                    <span>
                                                        City{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                fullWidth
                                                onChange={handleChange}
                                                error={cityError}
                                                helperText={cityError && "City is required"}
                                            />
                                            <TextField
                                                name="state"
                                                value={state}
                                                label="State"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="postalCode"
                                                value={postalCode}
                                                label={
                                                    <span>
                                                        Postal Code{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onChange={handleChange}
                                                fullWidth
                                                error={postalError}
                                                helperText={postalError && "Postal Code is required"}
                                            />
                                            <TextField
                                                name="country"
                                                value={country}
                                                label="Country"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="error"
                                                onClick={() => setRedirectToList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                    height: 50
                                                }}
                                                startIcon={<Cancel />}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onClick={handleUpdate}
                                                sx={{
                                                    borderRadius: "12px",
                                                    height: 50
                                                }}
                                                startIcon={<Edit />}
                                            >
                                                Update
                                            </Button>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>

            </Container>
        </div>
    )
}

export default EditCompany
