import React, { useEffect, useRef, useState } from "react";

//mui-components
import {
    Box,
    CardContent,
    IconButton,
    Typography
} from "@mui/material";

//externals
import { Icon } from "@iconify/react/dist/iconify.js";

//DiCom-Viewer import
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const DicomViewer = ({ file }) => {
    const elementRef = useRef(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (file && elementRef.current) {
            const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
            cornerstone.enable(elementRef.current);
            cornerstone
                .loadImage(imageId)
                .then((loadedImage) => {
                    setImage(loadedImage);
                    cornerstone.displayImage(elementRef.current, loadedImage);
                })
                .catch((error) => {
                    console.error("Error loading DICOM image:", error);
                });
        }
    }, [file]);

    const zoomIn = () => {
        if (elementRef.current) {
            const viewport = cornerstone.getViewport(elementRef.current);
            viewport.scale += 0.1;
            cornerstone.setViewport(elementRef.current, viewport);
        }
    };

    const zoomOut = () => {
        if (elementRef.current) {
            const viewport = cornerstone.getViewport(elementRef.current);
            viewport.scale -= 0.1;
            cornerstone.setViewport(elementRef.current, viewport);
        }
    };

    const rotateLeft = () => {
        if (elementRef.current) {
            const viewport = cornerstone.getViewport(elementRef.current);
            viewport.rotation -= 90; // Rotate left by 90 degrees
            cornerstone.setViewport(elementRef.current, viewport);
        }
    };

    const rotateRight = () => {
        if (elementRef.current) {
            const viewport = cornerstone.getViewport(elementRef.current);
            viewport.rotation += 90; // Rotate right by 90 degrees
            cornerstone.setViewport(elementRef.current, viewport);
        }
    };

    const flipHorizontal = () => {
        if (elementRef.current) {
            const viewport = cornerstone.getViewport(elementRef.current);
            viewport.hflip = !viewport.hflip;
            cornerstone.setViewport(elementRef.current, viewport);
        }
    };

    const flipVertical = () => {
        if (elementRef.current) {
            const viewport = cornerstone.getViewport(elementRef.current);
            viewport.vflip = !viewport.vflip;
            cornerstone.setViewport(elementRef.current, viewport);
        }
    };

    const resetViewport = () => {
        if (elementRef.current && image) {
            cornerstone.reset(elementRef.current);
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    return (
        <Box sx={{ position: "relative", width: "100%", height: "650px" }}>
            <CardContent>
                <Box
                    sx={{
                        position: "relative",
                        width: "800px",
                        height: "550px",
                        margin: "0 auto",
                    }}>
                    <div
                        ref={elementRef}
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                            color: "white",
                            borderRadius: '8px'
                        }}
                    />
                </Box>
            </CardContent>
            <Box
                sx={{
                    width: "800px",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "black",
                    py: 1,
                    minHeight: '60px',
                    borderRadius: '8px'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                        onMouseDown={handleMouseDown}
                        onClick={zoomIn}
                        sx={{ color: "white" }}
                    >
                        <Icon icon={'tabler:zoom-in'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Zoom In</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                        onMouseDown={handleMouseDown}
                        onClick={zoomOut}
                        sx={{ color: "white" }}
                    >
                        <Icon icon={'tabler:zoom-out'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Zoom Out</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                        onMouseDown={handleMouseDown}
                        onClick={rotateLeft}
                        sx={{ color: "white" }}
                    >
                        <Icon icon={'ic:outline-rotate-left'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Left</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                        onMouseDown={handleMouseDown}
                        onClick={rotateRight}
                        sx={{ color: "white" }}
                    >
                        <Icon icon={'ic:outline-rotate-right'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Right</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                        onMouseDown={handleMouseDown}
                        onClick={flipHorizontal}
                        sx={{ color: "white" }}
                    >
                        <Icon icon={'mdi:flip-horizontal'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Flip Horizontal</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                        onMouseDown={handleMouseDown}
                        onClick={flipVertical}
                        sx={{ color: "white" }}
                    >
                        <Icon icon={'mdi:flip-vertical'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Flip Vertical</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                        onMouseDown={handleMouseDown}
                        onClick={resetViewport}
                        sx={{ color: "white" }}
                    >
                        <Icon icon={'bx:reset'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Reset View</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default DicomViewer;
