import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//internals
import DeviceImg from "../../assets/images/devices.png";
import Iconify from '../../components/iconify/Iconify';
import Preview from "../../layout/Preview";
import './QRCodeScanner.css';

//mui-components
import { Close, Home, PostAdd } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import uniqueId from 'lodash/uniqueId';
import QRCode from 'qrcode.react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { QrReader } from 'react-qr-reader';
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const StyledPre = styled('pre')({
    // your styles here
});

//-----------------------------------------------------------------------------

const AddDevice = () => {

    const [companies, setCompanies] = useState([]);
    const [deviceProfiles, setDeviceProfiles] = useState([]);
    const [patientstoAssign, setPatientstoAssign] = useState([]);

    const [id] = useState(uniqueId('prefix-'));
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedDeviceProfile, setSelectedDeviceProfile] = useState('');
    const [name, setName] = useState('');
    const [macId, setMacId] = useState('');
    const [devImg, setDevImg] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [savedDeviceId, setSavedDeviceId] = useState('');

    const [selectedPatientToAssign, setSelectedPatientToAssign] = useState(null);
    const [authenticationStatus, setAuthenticationStatus] = useState(null);

    const [openPopup, setOpenPopup] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [deviceProfileError, setDeviceProfileError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [macIdError, setMacIdError] = useState(false);
    const [showing, setShowing] = useState(false);
    const [generateBtnEnable, setGenerateBtnEnable] = useState(true);
    const [assignBtnEnable, setAssignBtnEnable] = useState(true);
    const [cancelBtnEnable, setCancelBtnEnable] = useState(true);
    const [redirectToList, setRedirectToList] = useState(false);
    const [openPatientList, setOpenPatientList] = useState(false);
    const [loadPatientList, setLoadPatientsList] = useState(false);
    const [hideCompany, setHideCompany] = useState(true);
    const [hidePhysician, setHidePhysician] = useState(true);
    const [showScanner, setShowScanner] = useState(secureSessionStorage.getItem("roleId") === "3" || secureSessionStorage.getItem("roleId") == 3);
    const [androidIdError, setAndroidIdError] = useState(false);
    const [osVersionError, setOsVersionError] = useState(false);
    const [deviceError, setDeviceError] = useState(false);
    const [saveImageLogs, setSaveImageLogs] = useState(false);

    const [qrData, setQrData] = useState(null);
    const [androidId, setAndroidId] = useState('');
    const [osVersion, setOsVersion] = useState('');
    const [device, setDevice] = useState('');

    const [openDialog, setOpenDialog] = useState(false);

    const registerButtonRef = useRef(null);
    const [roleId, setRoleId] = useState(null)

    const handleScan = (data) => {
        var tenantId = secureSessionStorage.getItem("UUID");
        if (data) {
            // Sample input: "Android ID: eca0ba264eebecd6\nOS Version: 13\nDevice: samsung SM-T570"
            const qrDataText = data.text;
            // Extract Android ID using regular expression
            const androidIdMatch = qrDataText.match(/Android ID: (\S+)/);
            const androidId = androidIdMatch ? androidIdMatch[1] : '';

            ApiCalls.getdetails("tenant/" + tenantId + "/device/" + androidId + "/checkForExistingDevices")
                .then((response) => {
                    console.log(response.data.details);
                    if (response.data.details === "Device Already Enrolled") {
                        // Show error popup
                        showErrorPopup(secureSessionStorage.getItem("DeviceName") + "   already enrolled at another hospital");
                    } else if (response.data.details === "Device Already Registered") {
                        showErrorPopup(secureSessionStorage.getItem("DeviceName") + "  Already Registered");
                    } else {
                        setQrData(data);
                        setOpenDialog(true);
                    }
                })

        }
    };

    const showErrorPopup = (message) => {
        // Implement your popup component or dialog box to show the error message
        swal(message, {
            icon: "warning",
            timer: 1500,
        });
    };

    useEffect(() => {
        // Your logic to fetch data using qrData
        if (qrData) {
            console.log('Scanned QR Code:', qrData);
            // Add your data fetching logic here
        }
    }, [qrData]);

    const handleError = (err) => {
        console.error('Error:', err);
    };

    const handleCloseDialog = () => {
        setQrData(null); // Clear the qrData when closing the dialog
        setOpenDialog(false);
    };

    const handleConfirm = () => {
        // Sample input: "Android ID: eca0ba264eebecd6\nOS Version: 13\nDevice: samsung SM-T570"
        const qrDataText = qrData.text;

        // Extract Android ID using regular expression
        const androidIdMatch = qrDataText.match(/Android ID: (\S+)/);
        const androidId = androidIdMatch ? androidIdMatch[1] : '';

        // Extract OS Version using regular expression
        const osVersionMatch = qrDataText.match(/OS Version: (\S+)/);
        const osVersion = osVersionMatch ? osVersionMatch[1] : '';

        // Extract Device using regular expression
        const deviceMatch = qrDataText.match(/Device: (.+)/);
        const device = deviceMatch ? deviceMatch[1] : '';

        console.log("Android ID:", androidId);
        console.log("OS Version:", osVersion);
        console.log("Device:", device);

        setAndroidId(androidId);
        setOsVersion(osVersion);
        setDevice(device);

        if (!selectedDeviceProfile) {
            setDeviceProfileError(true);
        }
        if (!androidId) {
            setAndroidIdError(true);
        }
        if (!osVersion) {
            setOsVersionError(true);
        }
        if (!device) {
            setDeviceError(true);
        }

        // Continue with your registration logic if all fields are valid
        if (
            selectedDeviceProfile &&
            androidId &&
            osVersion &&
            device
        ) {
            const data = {
                devicename: device,
                modelid: selectedDeviceProfile,
                devmacid: androidId,
                osversion: osVersion,
                tenantid: secureSessionStorage.getItem("tenantId")
            };
            ApiCalls.register("registeribsmdevice", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Success", {
                            icon: "success",
                            timer: 1500,
                        });
                        setOpenDialog(false);
                        setAndroidId('');
                        setOsVersion('');
                        setDevice('');
                        setQrData(null);
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && openDialog) {
                handleConfirm();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [openDialog, handleConfirm]);



    const handleRegister = () => {
        // // Reset all error states
        if (!selectedDeviceProfile) {
            setDeviceProfileError(true);
        }
        if (!androidId) {
            setAndroidIdError(true);
        }
        if (!osVersion) {
            setOsVersionError(true);
        }
        if (!device) {
            setDeviceError(true);
        }

        // Continue with your registration logic if all fields are valid
        if (
            selectedDeviceProfile &&
            androidId &&
            osVersion &&
            device
        ) {
            const data = {
                devicename: device,
                modelid: selectedDeviceProfile,
                devmacid: androidId,
                osversion: osVersion,
                tenantid: secureSessionStorage.getItem("tenantId")
            };
            ApiCalls.register("registeribsmdevice", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Success", {
                            icon: "success",
                            timer: 1500,
                        });
                        setOpenDialog(false);
                        setAndroidId('');
                        setOsVersion('');
                        setDevice('');
                        setQrData(null);
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    };

    useEffect(() => {
        var tenanitid = secureSessionStorage.getItem("tenantId");
        ApiCalls.getdetails("organizations/0/tenants/" + tenanitid + "/companies")
            .then((response) => {
                console.log(response.data);
                const options = response.data.companies.map(d => ({
                    "value": d.companyuuid,
                    "label": d.name
                }))
                setCompanies(options);
            })

        const roleId = secureSessionStorage.getItem("roleId");
        console.log(roleId);
        setRoleId(roleId)
        const userId = secureSessionStorage.getItem("tenantMailId");
        ApiCalls.getRoleBasedMenu(userId, roleId)
            .then(function (response) {
                console.log(response.data);
                const menuArray = response.data;

                const hasManageCompany = menuArray.some(item => item.url === '/managecustomer');
                const hasManagePhysician = menuArray.some(item => item.url === '/managetherapist');

                if (!hasManageCompany) {
                    setHideCompany(true);
                } else {
                    setHideCompany(false);
                }

                if (!hasManagePhysician) {
                    setHidePhysician(true);
                } else {
                    setHidePhysician(false);
                }

            })

        getDeviceProfileList();
        getPatienttoAssignList();
    }, [])

    const getPatienttoAssignList = () => {
        let tenantId = secureSessionStorage.getItem("tenantId");
        let companyId = secureSessionStorage.getItem("compId");
        let physicianId = "";
        let rolename = secureSessionStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = "0";
            companyId = secureSessionStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = "0";
            companyId = "0";
            physicianId = secureSessionStorage.getItem("UUID");
        }
        ApiCalls.getdetails(
            "organizations/0/tenants/" +
            tenantId +
            "/companies/" +
            companyId +
            "/physicians/" +
            physicianId +
            "/patients?"
        )
            .then((result) => {
                console.log(result.data);
                if (result.data.patient !== undefined) {
                    for (let i = 0; i < result.data.patient.length; i++) {
                        setPatientstoAssign(result.data.patient);
                        setLoadPatientsList(false);
                    }
                } else {
                    setPatientstoAssign([]);
                    setLoadPatientsList(false);
                }
            })
            .catch((error) => {
                console.log("error: " + error);
                setPatientstoAssign([]);
                setLoadPatientsList(false);
            });
    }

    const getDeviceProfileList = () => {
        var tenantId = secureSessionStorage.getItem("tenantId");
        ApiCalls.getdetails("tenants/" + tenantId + "/companies/0/physicians/0/devicemodelslist")
            .then(function (response) {
                console.log(response.data);
                const options = response.data.deviceMode.map(d => ({
                    "value": d.deviceUUid,
                    "label": d.modelName,
                    "qrenabled": d.qrEnabled
                }))
                setDeviceProfiles(options);
                console.log(options);
                const activeProfile = options.find(profile => profile.qrenabled === "active");
                console.log(activeProfile);

                // If an active profile is found, set its value as selectedDeviceProfile
                if (activeProfile) {
                    setSelectedDeviceProfile(activeProfile.value);
                    // Call the function to check authentication status
                    checkAuthenticationStatus(activeProfile.value);
                }
            })
    }

    const checkAuthenticationStatus = (modelId) => {
        const apiUrl = `modelid/${modelId}/checkauthenticationenabled`;

        ApiCalls.getdetails(apiUrl)
            .then((response) => {
                console.log(`Authentication status for model ${modelId}:`, response.data);
                setAuthenticationStatus(response.data);

                // If authentication is successful, show the QR code scanner
                if (response.data && (secureSessionStorage.getItem("roleId") === "4" || secureSessionStorage.getItem("roleId") == 4)) {
                    // Call the function to check authentication status
                    checkAuthenticationStatus(selectedDeviceProfile);

                    // Open the popup
                    setOpenPopup(true);
                }

            })
            .catch((error) => {
                console.error(`Error checking authentication status for model ${modelId}:`, error);
                // Handle the error as needed
            });
    };

    const handleBlur = (event) => {
        if (!event.target.value && !hideCompany) {
            switch (event.target.name) {
                case 'company':
                    setCompanyError(true);
                    break;
                case 'profile':
                    setDeviceProfileError(true);
                    break;
                case 'name':
                    setNameError(true);
                    break;
                case 'macId':
                    setMacIdError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleClosePopup = () => {
        // Close the popup when needed (e.g., when the user clicks outside the popup)
        setOpenPopup(false);
        setRedirectToList(true);
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'name':
                setNameError(false);
                setName(event.target.value);
                break;
            case 'macId':
                setMacIdError(false);
                setMacId(event.target.value);
                break;
            default:
                break;
        }
    };

    //qr code generation 

    const moveUp = keyframes`
     0% {
       transform: translateY(0);
     }
     30% {
       transform: translateY(-10px);
     }
     100% {
       transform: translateY(0);
     }
   `;

    // Styled component with animation
    const AnimatedPre = styled('pre')({
        color: 'blue',
        fontWeight: 'bold',
        fontSize: '18px',
        marginTop: '50px',
        animation: `${moveUp} 2s ease-in-out infinite`,
    });

    const getDeviceProfileByName = (deviceProfileId) => {
        console.log(deviceProfileId);
        ApiCalls.getdetails("getdevicemodelsbyid/" + deviceProfileId)
            .then((response) => {
                console.log("Model Details");
                console.log("**********************");
                console.log(response.data);
                console.log("Model Count =" + response.data.deviceMode.length);
                console.log("Model Id=" + response.data.deviceMode[0].deviceUUid);
                secureSessionStorage.setItem("modelId", response.data.deviceMode[0].deviceUUid);
                setShowing(true);
                setGenerateBtnEnable(false);
                setDeviceId({ id });
                setDevImg(response.data.deviceMode[0].devImage);
            })
    }

    const handleGenerateMacId = () => {
        var idForGenerating = 0;
        ApiCalls.getdetails("getthelatestdeviceid")
            .then((response) => {
                console.log(response.data);
                idForGenerating = response.data;

                var input = idForGenerating;
                console.log(input);
                console.log(input.slice(-12));

                var deviceuniqueid = input.slice(-12);
                console.log("GeneratedId=" + deviceuniqueid);
                setMacId(deviceuniqueid);
            })
    }

    const handleClick = () => {
        let hasError = false; // Initialize error flag

        if (!selectedCompany && !hideCompany) {
            setCompanyError(true);
            hasError = true;
        }

        if (!selectedDeviceProfile) {
            setDeviceProfileError(true);
            hasError = true;
        }

        if (!name) {
            setNameError(true);
            hasError = true;
        }

        if (!macId) {
            setMacIdError(true);
            hasError = true;
        }

        // Stop further execution if there is any error
        if (hasError) {
            return; // Exit function if any errors were found
        } else {

            var rolename = secureSessionStorage.getItem("rolename");
            var tenantid = "";
            var companyid = "";
            if (rolename === "Tenant") {
                tenantid = secureSessionStorage.getItem("UUID");
                companyid = selectedCompany;
            }
            if (rolename === "Company") {
                tenantid = secureSessionStorage.getItem("tenantId");
                companyid = secureSessionStorage.getItem("compId");
            }
            const data = {
                companyid: companyid,
                physicianId: tenantid,
                devicename: name,
                modelid: selectedDeviceProfile,
                bleid: "",
                devmacid: macId,
                deviceimagename: devImg === null || devImg === undefined ? "" : devImg,
                saveImageLogs: saveImageLogs // Include the saveImageLogs checkbox state
            }

            ApiCalls.register("createdevices", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal(secureSessionStorage.getItem("DeviceName") + " Successfully Registered", {
                            icon: "success",
                        });
                        setSavedDeviceId(response.data.data.uuid)
                        setAssignBtnEnable(false);
                        setCancelBtnEnable(false);
                    }
                }).catch((err) => {
                    console.log(err.message);
                    if (err.message === "Request failed with status code 409") {
                        swal(secureSessionStorage.getItem("DeviceName") + " Already Registered", {
                            icon: "info",
                        });
                    }
                })
        }
    }

    const handleClosePatientList = () => {
        setOpenPatientList(false);
    }

    const handleSelectPatientToAssign = (event) => {
        const patientId = event.target.value;
        if (patientId === selectedPatientToAssign) {
            // User is unchecking the checkbox
            setSelectedPatientToAssign(null);
        } else {
            // User is checking a different checkbox
            setSelectedPatientToAssign(patientId);
        }
    }

    const handleAssignDevicetoPatient = () => {
        console.log(selectedPatientToAssign);
        console.log(savedDeviceId);

        var data = {
            patients: [
                {
                    patientuuid: selectedPatientToAssign
                }
            ]
        }

        ApiCalls.register("device/" + savedDeviceId + "/patient/assign", data)
            .then((response => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal((secureSessionStorage.getItem("PatientName") + "Assigned"), {
                        icon: "success",
                    });
                    handleClosePatientList();
                    setRedirectToList(true);
                } else if (response.data.status.details === "Device Already Assigned To The Patient") {
                    swal((secureSessionStorage.getItem("PatientName") + " Already Assigned"), {
                        icon: "warning",
                    });
                }
            })).catch((error) => {
                console.log(error.message);
            });
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const QrCodeScannerComponent = () => {
        const [apiResponse, setApiResponse] = useState(null);
        const [scanMessage, setScanMessage] = useState('');
        const [tempScanResponse, setTempScanResponse] = useState(null);
        const [authenticate, setAuthenticate] = useState(false);
        const [showLinearProgress, setShowLinearProgress] = useState(true);
        const [showSuccessMessage, setShowSuccessMessage] = useState(false);
        const [showFailMessage, setShowFailMessage] = useState(false);
        const [devicemacid, setDevicemacid] = useState('')
        const [verificationInProgress, setVerificationInProgress] = useState(false);
        const [qrCodeDetailsFetched, setQrCodeDetailsFetched] = useState(false);

        useEffect(() => {
            const roleId = secureSessionStorage.getItem("roleId");
            if (roleId == 4 || roleId === "4") {
                if (!qrCodeDetailsFetched) {
                    fetchDetailsForQrCode();
                    setQrCodeDetailsFetched(true); // Set the flag to true after fetching details for the first time
                }
                const intervalId = setInterval(fetchCheckForTempScan, 100); // Call fetchCheckForTempScan every second
                return () => clearInterval(intervalId);
            }
        }, [selectedDeviceProfile, qrCodeDetailsFetched]);

        const fetchDetailsForQrCode = () => {
            ApiCalls.getdetails(`modelid/${selectedDeviceProfile}/getdetailsforqrcode`)
                .then((response) => {
                    console.log(response.data);
                    console.log(secureSessionStorage.getItem("UUID"));
                    setApiResponse({
                        ...response.data,
                        compId: secureSessionStorage.getItem("UUID"),
                        tenantId: secureSessionStorage.getItem("tenantId"),
                    });
                })
                .catch((error) => {
                    console.error('Error fetching details for QR code:', error);
                });
        };


        const fetchCheckForTempScan = () => {
            console.log("inside tempscan******************--------");

            ApiCalls.getdetails('checkfortempscan')
                .then((response) => {
                    console.log(Object.keys(response.data).length);
                    if (Object.keys(response.data).length > 0) {
                        console.log("SUCCESS!!!!!!!!!!!");
                        setTempScanResponse(response.data); // Update tempScanResponse with the response data
                        setVerificationInProgress(true);
                        // Call fetchcheckforauthentication when there's a response from checkfortempscan
                        fetchcheckforauthentication(response.data.decmacid);
                    } else {
                        console.log("FAILED!!!!!!!!!!!")
                        // No response data, so reset tempScanResponse and set verificationInProgress to false
                        setTempScanResponse(null);
                        setVerificationInProgress(false);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data from checkfortempscan:', error);
                    // If there's an error, also reset tempScanResponse and set verificationInProgress to false
                    setTempScanResponse(null);
                    setVerificationInProgress(false);
                });
        };

        const fetchcheckforauthentication = (devicemacid) => {
            console.log("************inside checkforauthentication*********");
            var rolename = secureSessionStorage.getItem("rolename");
            var compId = "";
            if (rolename === "Company") {
                compId = secureSessionStorage.getItem("UUID");
            }
            ApiCalls.getdetails(`devicemacid/${devicemacid}/companyId/${compId}/checkforauthentication`)
                .then((response) => {
                    console.log('****RESPONSE******');
                    console.log(response.data);
                    if (response.data.device != undefined) {
                        if (response.data.device[0].devcompstatus === "Success") {
                            setShowSuccessMessage(true);
                            setShowLinearProgress(false);
                            setShowFailMessage(false);
                        } else if (response.data.device[0].devcompstatus === "Already Exists") {
                            setShowFailMessage(true);
                            setShowSuccessMessage(false);
                            setShowLinearProgress(false);
                        } else if (response.data.device[0].devcompstatus === "Failed") {
                            setShowFailMessage(true);
                            setShowSuccessMessage(false);
                            setShowLinearProgress(false);
                        }
                    } else {
                        console.log("Device Enrolment Failed!!!");
                        setShowSuccessMessage(false);
                        setShowLinearProgress(true);
                        setShowFailMessage(false)
                    }
                })
                .catch((error) => {
                    console.error('Error fetching details for QR code:', error);
                    setShowSuccessMessage(false);
                    setShowLinearProgress(true);
                });
        };


        return (
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box>
                    <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box textAlign="center" marginBottom="10px">
                                <QRCode value={JSON.stringify(apiResponse)} size={220} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                            <Grid container spacing={2} direction="column">
                                <Box textAlign="left" maxWidth="400px">
                                    <Typography variant="h4" mt={2} mb={3}>
                                        Scan QR Code
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                                        1. Open iBsm App on your Device
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                                        2. Point your Device to QR code on the Screen
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                                        3. Scan QR code to Register the Device for device registration
                                    </Typography>

                                    <Typography variant="body1" sx={{ color: 'green' }}>
                                        {/* Additional content */}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {verificationInProgress && showLinearProgress && (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <LinearProgress sx={{ color: "blue", width: '100%', height: '20px' }} />
                            <Typography variant="h6">
                                {tempScanResponse && tempScanResponse.devicename && `${JSON.stringify(tempScanResponse.devicename, null, 2)} is connected and verification in progress`}
                            </Typography>
                        </Box>
                    )}

                    {/* Add conditional rendering for success message */}
                    {showSuccessMessage && (
                        <Typography variant="h6">
                            {/* Check if DeviceName exists in secureSessionStorage before rendering  */}
                            {`${secureSessionStorage.getItem("DeviceName")} Registered Successfully!!!!!!!!!`}
                        </Typography>
                    )}

                    {showFailMessage && (
                        <Typography variant="h6">
                            {/* Check if DeviceName exists in secureSessionStorage before rendering  */}
                            {`${secureSessionStorage.getItem("DeviceName")} Already Enrolled !!!!!`}
                        </Typography>
                    )}
                </Box>
            </Box>
        )
    }

    if (redirectToList) {
        return (<Redirect to={"/managedevice"} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managedevice">
                            <Button variant="text" startIcon={<Icon icon={'tabler:devices-plus'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureSessionStorage.getItem("DeviceName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Add " + secureSessionStorage.getItem("DeviceName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Add " + secureSessionStorage.getItem("DeviceName")}
                        </Typography>}
                    />
                    <CardContent>

                        {showScanner ? (

                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                                <Box sx={{ width: { xs: 1, md: 1 / 2 } }}>
                                    <div className="qr-scanner-container">
                                        <div className="qr-scanner-window">
                                            <QrReader onResult={handleScan} onError={handleError} />
                                        </div>
                                    </div>
                                </Box>


                                <Box sx={{ textAlign: 'center', margin: "auto", minWidth: 500, marginTop: 12 }}>
                                    <Stack spacing={2} mb={2}>
                                        <FormControl >
                                            <InputLabel id="demo-simple-select-label">Select Device Profile</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="profile"
                                                label="Select Device Profile"
                                                value={selectedDeviceProfile}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setSelectedDeviceProfile(selectedValue);
                                                    setDeviceProfileError(false);
                                                    getDeviceProfileByName(selectedValue);

                                                    // Call the function to check authentication status
                                                    checkAuthenticationStatus(selectedValue);
                                                }}
                                                error={deviceProfileError}
                                            >
                                                {deviceProfiles.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                            <FormHelperText error={deviceProfileError} style={{ color: deviceProfileError ? 'red' : '' }}>
                                                {deviceProfileError && "Select a Device Profile"}
                                            </FormHelperText>
                                        </FormControl>
                                        <TextField
                                            label="Android ID"
                                            variant="outlined"
                                            fullWidth
                                            value={androidId}
                                            onChange={(e) => {
                                                setAndroidId(e.target.value);
                                                setAndroidIdError(false);
                                            }}
                                            onBlur={() => setAndroidIdError(!androidId)}
                                            error={androidIdError}
                                        />
                                        {androidIdError && (
                                            <FormHelperText style={{ color: "red" }}>
                                                Enter Android ID
                                            </FormHelperText>
                                        )}

                                        <TextField
                                            label="OS Version"
                                            variant="outlined"
                                            fullWidth
                                            value={osVersion}
                                            onChange={(e) => {
                                                setOsVersion(e.target.value);
                                                setOsVersionError(false);
                                            }}
                                            onBlur={() => setOsVersionError(!osVersion)}
                                            error={osVersionError}
                                        />
                                        {osVersionError && (
                                            <FormHelperText style={{ color: "red" }}>
                                                Enter OS Version
                                            </FormHelperText>
                                        )}

                                        <TextField
                                            label="Device Name"
                                            variant="outlined"
                                            fullWidth
                                            value={device}
                                            onChange={(e) => {
                                                setDevice(e.target.value);
                                                setDeviceError(false);
                                            }}
                                            onBlur={() => setDeviceError(!device)}
                                            error={deviceError}
                                        />
                                        {deviceError && (
                                            <FormHelperText style={{ color: "red" }}>
                                                Enter Device Name
                                            </FormHelperText>
                                        )}
                                    </Stack>

                                    <Button variant="contained"
                                        color="primary"
                                        sx={{ borderRadius: '50px' }}
                                        onClick={handleRegister}
                                        startIcon={<Icon icon={'material-symbols-light:data-saver-on'} />}
                                    >
                                        Register
                                    </Button>
                                </Box>
                            </Box>

                        ) : (<Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 6">
                                    <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2, display: hideCompany ? "none" : "block" }} sx={{}}>
                                        <FormControl sx={{
                                            display: hideCompany ? "none" : "block"
                                        }}>
                                            <InputLabel id="demo-simple-select-label">{"Select " + secureSessionStorage.getItem("CompanyName")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="company"
                                                label={"Select " + secureSessionStorage.getItem("CompanyName")}
                                                value={selectedCompany}
                                                onBlur={handleBlur}
                                                fullWidth
                                                onChange={(e) => {
                                                    setSelectedCompany(e.target.value)
                                                    setCompanyError(false)
                                                }}
                                                error={companyError}
                                            >
                                                {companies.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error={companyError} style={{ color: companyError ? 'red' : '' }}>
                                                {companyError && "Select a " + secureSessionStorage.getItem("CompanyName")}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl >
                                            <InputLabel id="demo-simple-select-label"><span>
                                                Select Device Profile{' '}
                                                <span style={{ color: 'red' }}>*</span>
                                            </span></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="profile"
                                                label={
                                                    <span>
                                                        Select Device Profile{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                value={selectedDeviceProfile}
                                                onBlur={handleBlur}
                                                fullWidth
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setSelectedDeviceProfile(selectedValue);
                                                    setDeviceProfileError(false);
                                                    getDeviceProfileByName(selectedValue);

                                                    // Assuming roleId is a property of the selected device profile
                                                    const roleId = secureSessionStorage.getItem("roleId"); // Adjust this based on your actual data structure
                                                    console.log(roleId, "**");
                                                    // Check if the roleId is 4 or 5 before calling checkAuthenticationStatus
                                                    if (roleId === "4" || roleId === "5") {
                                                        checkAuthenticationStatus(selectedValue);
                                                    }
                                                }}

                                                error={deviceProfileError}
                                            >
                                                {deviceProfiles.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                            {authenticationStatus !== null && (
                                                <div>
                                                    {/* <p>Authentication Status: {authenticationStatus.toString()}</p> */}
                                                </div>
                                            )}

                                            <FormHelperText error={deviceProfileError} style={{ color: deviceProfileError ? 'red' : '' }}>
                                                {deviceProfileError && "Select a Device Profile"}
                                            </FormHelperText>
                                        </FormControl>
                                        <TextField
                                            name="name"
                                            label={
                                                <span>
                                                    Device Name{' '}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            value={name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={nameError}
                                            helperText={nameError && "Device Name is required"}
                                        />
                                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 2 }}>
                                            <TextField
                                                name="macId"
                                                id="macId"
                                                label={
                                                    <span>
                                                        Device MacId{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                value={macId}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={macIdError}
                                                helperText={macIdError && "Device MacId is required"}
                                                sx={{ width: '70%' }} // Set width to 70%
                                            />
                                            <FormControl>
                                                <InputLabel htmlFor="macId">Device MacId</InputLabel>
                                            </FormControl>
                                            <Button
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onClick={handleGenerateMacId}
                                                disabled={generateBtnEnable}
                                                sx={{
                                                    borderRadius: "12px",
                                                    height: 55,
                                                    width: '30%',
                                                    fontSize: '15px'
                                                }}
                                                startIcon={<PostAdd />}
                                            >
                                                Generate
                                            </Button>
                                        </Stack>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={saveImageLogs}
                                                    onChange={(event) => setSaveImageLogs(event.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            label="Save Image Logs"
                                            name="saveImageLogs"
                                        />
                                    </Stack>
                                </Box>
                                <Box gridColumn="span 6" sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}>
                                    {showing ?
                                        devImg === "null" ? <CardMedia
                                            sx={{
                                                height: 200,
                                                width: 200,
                                                objectFit: "contain"
                                            }}
                                            image={DeviceImg}
                                        /> : <CardMedia
                                            sx={{
                                                height: 200,
                                                width: 200,
                                                objectFit: "contain"
                                            }}
                                            image={devImg}
                                        />
                                        : ""}
                                </Box>
                                <Box gridColumn="span 6">
                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                        {
                                            assignBtnEnable ? <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                color="error"
                                                variant="contained"
                                                onClick={() => setRedirectToList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={
                                                    <Iconify icon={'material-symbols:cancel-presentation'} />
                                                }
                                            >
                                                Cancel
                                            </Button> : <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onClick={() => setOpenPatientList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={
                                                    <Iconify icon={'pajamas:assignee'} />
                                                }
                                            >   {"Assign to " + secureSessionStorage.getItem("PatientName")}

                                            </Button>
                                        }
                                        {
                                            cancelBtnEnable ? <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onClick={handleClick}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                            >
                                                {"Save " + secureSessionStorage.getItem("DeviceName")}

                                            </Button> : <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                color="error"
                                                variant="contained"
                                                onClick={() => setRedirectToList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={
                                                    <Iconify icon={'material-symbols:cancel-presentation'} />
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        }
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>)
                        }

                    </CardContent>
                </Card>
            </Container>

            <div>
                <Dialog open={openPatientList} onClose={handleClosePatientList}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={secureSessionStorage.getItem("PatientName") + " List"}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePatientList}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {
                                loadPatientList ?
                                    <>
                                        <CardHeader
                                            avatar={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Skeleton
                                                        variant="circular"
                                                        sx={{
                                                            width: 80,
                                                            height: 80
                                                        }}
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                        <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            } />
                                    </> :
                                    patientstoAssign.length > 0 ?
                                        <>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                minHeight: 300,
                                                maxHeight: 500,
                                                overflowY: "scroll",
                                                p: 0,
                                                margin: 0,
                                            }} >
                                                {patientstoAssign
                                                    .map((patient) => (
                                                        <FormControlLabel
                                                            key={patient.patientuuid}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedPatientToAssign === patient.patientuuid}
                                                                    value={patient.patientuuid}
                                                                    onChange={handleSelectPatientToAssign}
                                                                />}
                                                            label={<>
                                                                <CardHeader
                                                                    avatar={
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Avatar
                                                                                alt={patient.firstName + " " + patient.lastName}
                                                                                src={patient.image}
                                                                                sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                        {(patient.firstName || '') + (patient.lastName ? ` ${patient.lastName}` : '')}
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                        <Icon icon={'gridicons:mail'} />  {patient.email && patient.email.toLowerCase() !== 'null' ? patient.email : ''}
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                        <Icon icon={'ph:gender-intersex-fill'} /> {patient.gender}
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                        <Icon icon={'ic:baseline-phone'} /> {patient.phone}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    } />
                                                            </>}
                                                        />
                                                    ))}
                                            </Box>
                                        </>
                                        : <center>
                                            No {secureSessionStorage.getItem("PatientName")} Found
                                        </center>
                            }
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleClosePatientList} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAssignDevicetoPatient} fullWidth >Submit</Button>
                        </CardActions>
                    </Card>
                </Dialog>

                <Dialog
                    open={openPopup}
                    onClose={handleClosePopup}
                    maxWidth="md"
                    fullWidth
                    sx={{ width: '90%' }}
                >
                    <DialogContent>
                        {authenticationStatus && <QrCodeScannerComponent onClose={handleClosePopup} />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog to display scanned data */}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    PaperProps={{
                        sx: {
                            position: 'relative',
                            borderRadius: '16px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            overflow: 'hidden',
                        },
                    }}
                >
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            minWidth: 300,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        {/* Wave Shape Pseudo-element */}
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                height: '250px',
                                background: 'linear-gradient(to bottom, #5C9CFE, #FFFFFF)',
                                zIndex: -1,
                                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
                                transform: 'scaleY(-1)', // Flip the background shape vertically
                            }}
                        />
                        <CardHeader
                            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                            action={
                                <IconButton color="primary" onClick={handleCloseDialog}>
                                    <Icon icon={"mingcute:close-fill"} width="30" />
                                </IconButton>
                            }
                        />

                        {/* Image */}
                        <div style={{ margin: '16px', marginTop: '15px', borderRadius: '8px', padding: '8px' }}>
                            <Icon
                                icon={'fluent:phone-tablet-24-filled'}
                                width="120"
                                style={{
                                    color: '#5C9CFE', // Set the background color
                                    // border: '2px solid #808080', // Set the border color and style
                                    borderRadius: '8px', // Add border radius for rounded corners
                                    padding: '8px', // Add padding for better visual appearance
                                }}
                            />

                        </div>


                        {/* Details */}
                        <CardContent>
                            <Typography variant="subtitle2" paragraph>
                                {qrData != null && qrData.text?.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </Typography>
                        </CardContent>

                        {/* Register/Enroll Button */}
                        <CardActions sx={{
                            marginBottom: "20px"
                        }}>
                            <Button
                                onClick={handleConfirm}
                                variant="contained"
                                color="primary"
                                startIcon={<Icon icon={'line-md:confirm'} />}
                                sx={{ borderRadius: '50px' }}
                            >
                                Confirm
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>
        </div>
    )
}

export default AddDevice