import React from 'react';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

//externals
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

//internals
import './App.css';
import Routes from './Routes';
import UserContext from './context/UserContext';

// theme
import ThemeProvider from './theme';

const browserHistory = createBrowserHistory();

function App() {
  return (
    <div>
      <UserContext>
        <ThemeProvider>
          <HashRouter history={browserHistory}>
            <Routes />
          </HashRouter>
        </ThemeProvider>
      </UserContext>
    </div>
  );
}

export default App;
