import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";
import React, { useEffect, useRef } from "react";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const DicomViewer = ({ file }) => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (file && elementRef.current) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const arrayBuffer = e.target.result;
                const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
                cornerstone.enable(elementRef.current);
                cornerstone.loadImage(imageId).then((image) => {
                    cornerstone.displayImage(elementRef.current, image);
                });
            };
            reader.readAsArrayBuffer(file);
        }
    }, [file]);

    return (
        <div>
            <div
                ref={elementRef}
                style={{
                    width: 350,
                    height: 300,
                    position: "relative",
                    color: "white",
                }}
            />
        </div>
    );
};

export default DicomViewer;