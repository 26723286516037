import React from 'react';
import '../styles/Footer.css';

const Footer = ({ pageNumber, totalPages }) => (
  <footer className="report-footer">
    <div className="footer-right">
      <p>Page {pageNumber} of {totalPages}</p>
    </div>
  </footer>
);

export default Footer;
