import React from "react";
import "../styles/PatientReport.css";

const ReportContent = ({ patientData, signatureUrl, dicomImage }) => {
  const { doctorname } = patientData;

  return (
    <section className="report-content">
      <div className="patient-details">
        <div className="column">
          <p>
            <strong>Name:</strong> {patientData.patientname}
          </p>
          <p>
            <strong>Age:</strong>{" "}
            {patientData.patientage
              ? `${patientData.patientage} Years`
              : " - - "}
          </p>

          <p>
            <strong>Sex:</strong>{" "}
            {patientData.patientgender
              ? `${patientData.patientgender}`
              : " - - "}
          </p>
        </div>
        <div className="column">
          <p>
            <strong>Report #:</strong> {patientData.reportno}
          </p>
          <p>
            <strong>Ref By:</strong> {patientData.doctorname}
          </p>
        </div>
        <div className="column">
          <p>
            <strong>Registered on:</strong>{" "}
            {new Date(patientData.patientdob).toLocaleDateString()}
          </p>
          <p>
            <strong>Reported on:</strong> {patientData.reportdate}
          </p>
        </div>
      </div>

      <hr className="dark-separator" />

      {/* Center-align the report type */}
      <h4 className="report-type">{patientData.reporttype}</h4>

      <div className="view-details">
        <h5>Views</h5>
        <ul>
          {patientData.views?.map((view, index) => (
            <li key={index}>{view.viewentry}</li>
          ))}
        </ul>
      </div>

      <div className="impression">
        <h5>Impressions</h5>
        <ul>
          {patientData.impressions?.map((impression, index) => (
            <li key={index}>{impression.reportimpressions}</li>
          ))}
        </ul>
      </div>

      <div className="dicom-images">
        {dicomImage ? (
          <div className="xray-images">
            <div className="xray-view">
              <img src={dicomImage} alt="AP View" className="xray-pic" />
            </div>
          </div>
        ) : (
          <p>Loading DICOM image...</p>
        )}
      </div>

      <div className="recommendation">
        <h5>Recommendations</h5>
        <ul>
          {patientData.recommendations?.map((recommendation, index) => (
            <li key={index}>{recommendation.recommendations}</li>
          ))}
        </ul>
      </div>

      <div className="notes">
        <h5>Notes</h5>
        <ul>
          {patientData.notes?.map((note, index) => (
            <li key={index}>{note.notes}</li>
          ))}
        </ul>
      </div>

      <hr className="section-divider" />

      <div className="technologists">
        <h5 className="section-heading">Radiologic Technologists</h5>{" "}
        {/* Reuse the same class */}
        <div>
          <img src={signatureUrl} alt="Signature" className="signature-img" />
          <p>{doctorname}</p>
        </div>
      </div>
    </section>
  );
};

export default ReportContent;
