import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import DeviceImg from "../../assets/images/devices.png";
import Preview from '../../layout/Preview';

//mui-components
import {
    Add,
    Cancel,
    Close,
    Done,
    Edit,
    ExpandMore,
    Home,
    PlaylistRemoveRounded
} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Container,
    Dialog,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack, TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { blue, pink } from '@mui/material/colors';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


//-----------------------------------------------------------------------------

const EditPatient = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [image, setImage] = useState('');
    const [hmrNo, setHmrNo] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('Female');
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedPatientId, setSelectedPatientId] = useState('');
    const [patientInfo, setPatientInfo] = useState('false');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [dobErrorMessage, setDobErrorMessage] = useState('');
    const [tooltipDeviceId, setTooltipDeviceId] = useState(null); // State to track tooltip visibility for specific device
    const [tooltipMessage, setTooltipMessage] = useState("");
    const [bloodGroup, setBloodGroup] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [allergyDetails, setAllergyDetails] = useState('');
    const [pastComplicationsDetails, setPastComplicationsDetails] = useState('');

    const [companies, setcompanies] = useState([]);
    const [assignedDevices, setAssignedDevices] = useState([]);
    const [selectedDevicetoDeselect, setSelectedDevicetoDeselect] = useState([]);
    const [selectedDevicestoAssign, setSelectedDevicetoAssign] = useState([]);
    const [device, setDevice] = useState([]);
    const [physicians, setPhysicians] = useState([]);
    const [physicianDetails, setPhysicianDetails] = useState([]);
    const [selectedPhysicians, setSelectedPhysicians] = useState([]);
    const tagOptions = [
        "Valve Disorder",
        "Angioplasty",
        // Add other existing tag options here
    ];
    const [selectedTags, setSelectedTags] = useState('');
    const [hasAllergies, setHasAllergies] = useState('No');
    const [hasPastComplications, setHasPastComplications] = useState('No');

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [hover, setHover] = useState(false);
    const [openAddMoreDevice, setOpenAddMoreDevice] = useState(false);
    const [hmrNoError, setHmrNoError] = useState(false);
    const [allergyDetailsError, setAllergyDetailsError] = useState(false);
    const [pastComplicationsDetailsError, setPastComplicationsDetailsError] = useState(false);
    const [openPhysicianList, setOpenPhysicianList] = useState(false);

    const handleAllergyChange = (event) => {
        setHasAllergies(event.target.value);
        if (event.target.value === 'No') {
            setAllergyDetails('');
        }
    };

    const handleCompliChange = (event) => {
        setHasPastComplications(event.target.value);
        if (event.target.value === 'No') {
            setPastComplicationsDetails('');
        }
    };

    const loadPatientDetailsById = (patientId) => {
        ApiCalls.getdetails("getpatient/" + patientId + "/patients?")
            .then((response) => {
                const data = response.data.patient[0];
                console.log(data);
                setHmrNo(data.patientId);
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setEmail(data.email);
                setDob(data.dob ? data.dob.split(' ')[0] : '');
                setGender(data.gender);
                setPhone(data.phone);
                setStreet(data.address.street);
                setCity(data.address.city);
                setState(data.address.state);
                setPostalCode(data.address.postalcode);
                setCountry(data.address.country);
                setImage(data.image);
                setAge(data.age);
                setHeight(data.height);
                setWeight(data.weight);
                setBloodGroup(data.bloodGroup);
                setSelectedTags(data.patientTag);
                setHasAllergies(data.allergies === "No" ? "No" : "Yes");
                setAllergyDetails(data.allergies === "No" ? '' : data.allergies)
                setHasPastComplications(data.pastComplications === "No" ? "No" : "Yes");
                setPastComplicationsDetails(data.pastComplications === "No" ? '' : data.pastComplications)
            })
    }

    const loadCompanyList = () => {
        var tenantId = secureSessionStorage.getItem("tenantId");
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies")
            .then((response) => {
                console.log("Company Details");
                console.log("***********************************");
                console.log(response.data);
                const options = response.data.companies.map(d => ({
                    "value": d.companyuuid,
                    "label": d.name
                }))
                console.log(options);
                setcompanies(options)
            })
    }

    const loadDeviceAssigned = (patientId) => {
        ApiCalls.getdetails("patient/" + patientId + "/getdevices")
            .then((response) => {
                console.log(response.data);
                if (response.data.device !== undefined) {
                    setAssignedDevices(response.data.device);
                } else {
                    setAssignedDevices([]);
                }
            });
    }

    const loadDeviceList = () => {
        var tenantId = secureSessionStorage.getItem("tenantId");
        var companyId = secureSessionStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureSessionStorage.getItem("rolename");
        var tenantId = secureSessionStorage.getItem("tenantId");
        var companyId = secureSessionStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureSessionStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("compId");
            physicianId = secureSessionStorage.getItem("UUID");
        }
        ApiCalls.getdetails("PhysicianId/" + tenantId + "/company/" + companyId + "/showdeviceToAssign")
            .then((response) => {
                console.log("Device list")
                console.log(response.data.device);
                if (response.data.device != undefined) {
                    setDevice(response.data.device);
                } else {
                    setDevice([]);
                }
            }).catch((error) => {
                console.log(error);
                setDevice([]);
            })
    }

    const loadPhysicianList = () => {
        var rolename = secureSessionStorage.getItem("rolename");
        var tenantId = "";
        var companyId = "";
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
        }
        if (rolename === "Company") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("UUID");
        }
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians")
            .then((response) => {
                console.log("Physician Details");
                console.log("***********************************");
                console.log(response.data);
                if (response.data != undefined) {
                    setPhysicians(response.data.physicians)
                } else {
                    setPhysicians([]);
                }
            })
    }

    const loadPhysicianDetailsById = (patientId) => {
        ApiCalls.getdetails("patient/" + patientId + "/getPhysician")
            .then((response) => {
                console.log(response.data.patient);
                if (response.data.patient !== undefined) {
                    setPhysicianDetails(response.data.patient)
                } else {
                    setPhysicianDetails([]);
                }
            })
    }

    useEffect(() => {
        const patientId = secureSessionStorage.getItem("patientIdToEdit");
        console.log(patientId)
        setSelectedPatientId(patientId);
        loadCompanyList();
        loadDeviceList();
        loadPatientDetailsById(patientId);
        loadPhysicianList();
        loadPhysicianDetailsById(patientId)
        loadDeviceAssigned(patientId);

        var roleId = secureSessionStorage.getItem("roleId");

        // ApiCalls.getdetails("roleId" + roleId + "/getpatientprivilegedetails")
        //     .then((response) => {
        //         console.log(response.data);
        //         if (response.data.patientprivilege != undefined) {
        //             const patientInfo = response.data.patientprivilege[0].patientDevice;
        //             console.log("PatientInfo: " + patientInfo);
        //             setPatientInfo(patientInfo);
        //             if (patientInfo === "true") {
        //                 
        //             }
        //         }
        //     })
    }, [])

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'company':
                    setCompanyError(true);
                    break;
                case 'firstName':
                    setFirstNameError(true);
                    break;
                case 'lastName':
                    setLastNameError(true);
                    break;
                case 'phone':
                    setPhoneError(true);
                    break;
                case 'dob':
                    setDobError(true);
                    setDobErrorMessage("Enter a valid DOB")
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'firstName':
                setFirstNameError(false);
                setFirstName(event.target.value);
                break;
            case 'lastName':
                setLastNameError(false);
                setLastName(event.target.value);
                break;
            case 'email':
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhoneError(false);
                setPhone(event.target.value);
                break;
            case 'dob':
                const inputDate = new Date(event.target.value); // Parse input date once
                const today = new Date();

                if (!event.target.value || isNaN(inputDate)) {
                    setDobError(true);
                    setAge(''); // Reset age if DOB is invalid
                } else if (inputDate >= today) {
                    setDobError(true); // Set error if DOB is in the future
                    setDobErrorMessage("Enter a valid DOB")
                    setAge(''); // Reset age if DOB is invalid
                } else {
                    setDobError(false); // Valid DOB, remove error

                    // Calculate age
                    const ageDiff = today.getFullYear() - inputDate.getFullYear();
                    const monthDiff = today.getMonth() - inputDate.getMonth();

                    // Adjust age if the birthday hasn't occurred yet this year
                    const adjustedAge = (monthDiff < 0 || (monthDiff === 0 && today.getDate() < inputDate.getDate()))
                        ? ageDiff - 1
                        : ageDiff;

                    setAge(adjustedAge); // Set calculated age
                }

                setDob(event.target.value); // Set DOB value
                break;
            case 'tags':
                setSelectedTags(event.target.value);
                break;
            case 'height':
                setHeight(event.target.value);
                break;
            case 'weight':
                setWeight(event.target.value);
                break;
            case 'bloodGroup':
                setBloodGroup(event.target.value);
                break;
            case 'allergyDetails':
                setAllergyDetails(event.target.value);
                break;
            case 'pastComplicationsDetails':
                setPastComplicationsDetails(event.target.value);
                break;
            case 'street':
                setStreet(event.target.value);
                break;
            case 'city':
                setCity(event.target.value);
                break;
            case 'state':
                setState(event.target.value);
                break;
            case 'country':
                setCountry(event.target.value);
                break;
            case 'postalCode':
                setPostalCode(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'MM/dd/yyyy');
    };

    const maxDate = formatDate(new Date());

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleDeselectDevice = (devices) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var deviceId = devices.deviceuuid;
                    var patientId = devices.patientId;

                    console.log(deviceId, patientId)

                    ApiCalls.delete("device/" + deviceId + "/patient/" + patientId + "/deselectDevice")
                        .then(function (response) {
                            console.log(response.data);
                            if (response.data.status.message === "Success") {
                                swal("Deselected Successfully!", {
                                    icon: "success",
                                });
                                loadDeviceAssigned(patientId);
                                loadDeviceList();
                            }
                        })
                }
            })
    }

    const handleUpdate = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        // Collect validation errors
        const errors = {};

        if (!hmrNo) {
            errors.hmrNoError = true;
        }
        if (!firstName) {
            errors.firstNameError = true;
        }
        if (!lastName) {
            errors.lastNameError = true;
        }
        if (!dob) {
            errors.dobError = true;
        } else {
            const today = new Date();
            const dobDate = new Date(dob); // Convert dob to a Date object
            if (dobDate > today) {
                errors.dobError = true;
                errors.dobErrorMessage = "DOB cannot be a future date";
            }
        }
        if (!phone) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Phone Number is required";
        } else if (!phoneRegex.test(phone)) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Invalid Phone Number";
        }
        if (hasAllergies === 'Yes' && !allergyDetails) {
            errors.allergyDetailsError = true;
        }
        if (hasPastComplications === 'Yes' && !pastComplicationsDetails) {
            errors.pastComplicationsDetailsError = true;
        }

        // Display errors if any
        if (Object.keys(errors).length > 0) {
            // Set all errors at once
            setHmrNoError(errors.hmrNoError || false);
            setFirstNameError(errors.firstNameError || false);
            setLastNameError(errors.lastNameError || false);
            setDobError(errors.dobError || false);
            setDobErrorMessage(errors.dobErrorMessage || "")
            setPhoneError(errors.phoneError || false);
            setPhoneErrorMessage(errors.phoneErrorMessage || "");
            setAllergyDetailsError(errors.allergyDetailsError || false);
            setPastComplicationsDetailsError(errors.pastComplicationsDetailsError || false);
            return; // Stop further execution
        } else {
            var patientId = secureSessionStorage.getItem("patientIdToEdit");
            var tenantId = secureSessionStorage.getItem("tenantId");
            var companyId = selectedCompany;

            const address = {
                street: street,
                postalcode: postalCode,
                city: city,
                state: state,
                country: country
            }

            var data = {
                tenantId: tenantId,
                companyId: companyId,
                physicianId: "0",// physicianId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                dob: dob,
                age: age,
                gender: gender,
                roleId: "6",
                phone: phone,
                address: address,
                image: image === null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image,
                bloodGroup: bloodGroup,
                patientTag: selectedTags,
                height: height,
                weight: weight,
                allergies: hasAllergies === "No" ? hasAllergies : allergyDetails,
                pastComplications: hasPastComplications === "No" ? hasPastComplications : pastComplicationsDetails,
            }

            ApiCalls.edit("organizations/0/tenants/" + tenantId + "/patients/" + patientId + "/updatePatient", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal(secureSessionStorage.getItem("PatientName") + " Successfully Updated", {
                            icon: "success",
                        });
                        setRedirectToList(true);
                    } else {
                        setRedirectToList(true);
                    }
                })
        }
    }

    const handleOpenPhysicianList = () => {
        setOpenPhysicianList(true);
    }

    const handleClosePhysicianList = () => {
        setOpenPhysicianList(false);
    }

    const handleOpenDeviceList = () => {
        setOpenAddMoreDevice(true);
    }

    const handleCloseDeviceList = () => {
        setOpenAddMoreDevice(false);
        loadDeviceAssigned(selectedPatientId);
        setSelectedDevicetoAssign([]);
        loadDeviceList();
    }

    if (redirectToList) {
        return (
            <Redirect to={'/managepatient'} />
        )
    }

    const handleCheckboxChange = (event, physician) => {
        if (event.target.checked) {
            setSelectedPhysicians([...selectedPhysicians, physician]);
        } else {
            setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
        }
    };

    const handleListItemClick = (event, physician) => {
        const checkbox = event.target.closest('input[type="checkbox"]');
        if (!checkbox) {
            const selectedIndex = selectedPhysicians.findIndex((p) => p.physicianuuid === physician.physicianuuid);
            if (selectedIndex === -1) {
                setSelectedPhysicians([...selectedPhysicians, physician]);
            } else {
                setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
            }
        }
    };

    const handleDeselectPhysician = (physician) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var physicianId = physician.physicianId;

                    const data = {
                        physicianuuid: physicianId
                    }

                    ApiCalls.edit("patient/" + selectedPatientId + "/deselectPhysician", data)
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.status.message === "Success") {
                                swal("Deselected Successfully!", {
                                    icon: "success",
                                });
                                loadPhysicianDetailsById(selectedPatientId);
                            }
                        })
                        .catch((error) => {
                            console.error("Error deselecting physician:", error);
                            swal("Error", "Failed to deselect physician", "error");
                        });
                }
            });
    }

    const handleAssignDevicetoPatient = () => {
        if (!selectedDevicestoAssign.length) {
            swal("No devices selected", { icon: "warning" });
            return;
        }
        // Structure the data payload for the API request
        const data = selectedDevicestoAssign.map(device => ({
            deviceuuid: device.id, // Use deviceuuid based on the provided data structure
            macid: device.macid, // Assuming macid is still needed
        }));

        console.log(data);

        // Send data directly as an array to the API
        ApiCalls.register(`patient/${selectedPatientId}/devices/assign`, data)
            .then(response => {
                console.log(response.data);

                const { message, details } = response.data.status;

                if (message === "Success") {
                    swal(`${secureSessionStorage.getItem("DeviceName")} assigned`, { icon: "success" }); // Show the details of assigned devices
                    handleCloseDeviceList();  // Close device selection dialog
                    loadDeviceAssigned(selectedPatientId);  // Reload assigned devices
                    loadDeviceList();
                } else if (message === "No devices were assigned.") {
                    swal(`${secureSessionStorage.getItem("DeviceName")} with same category already assigned`, { icon: "warning" }); // Show details of devices that were not assigned
                    loadDeviceList();
                } else {
                    // Handle other potential response cases here if needed
                    swal("Unexpected response from the server", { icon: "info" });
                    loadDeviceList();
                }
            })
            .catch(error => {
                console.error(error.message);
                swal("An error occurred while assigning the device(s). Please try again.", { icon: "error" });
                loadDeviceList();
            });
    };

    const handleAssignPhysician = () => {
        console.log(selectedPhysicians);
        var roleId = secureSessionStorage.getItem("roleId");
        var tenantId = secureSessionStorage.getItem("tenantId");

        var a = [];
        var list = selectedPhysicians.length;

        for (var i = 0; i < list; i++) {
            var counters = selectedPhysicians[i];
            var physicianuuid = counters.physicianuuid;
            var s = { "physicianuuid": physicianuuid }
            a.push(s);
        }

        var rolename = secureSessionStorage.getItem("rolename");

        var data = {
            tenantId: tenantId,
            companyId: rolename === "Company" ? secureSessionStorage.getItem("UUID") : selectedCompany,
            physicians: a,
            patientuuid: selectedPatientId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            gender: gender,
            dob: dob,
            age: age,
            roleId: roleId,
            phone: phone,
            image: image === null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image
        };

        console.log("Final Data");
        console.log(data)
        ApiCalls.register("assignphysicians", data)
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal(secureSessionStorage.getItem("PhysicianName") + " Successfully Assigned", {
                        icon: "success",
                    });
                    handleClosePhysicianList();
                    loadPhysicianDetailsById(selectedPatientId);
                } else if (response.data.status.details === "Patient Already Registered") {
                    swal(secureSessionStorage.getItem("PatientName") + " Already Registered", {
                        icon: "info",
                    });
                }
            }).catch((err) => {
                console.log(err)
            })

    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managepatient">
                            <Button variant="text" startIcon={<Icon icon={'material-symbols:recent-patient-rounded'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureSessionStorage.getItem("PatientName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Edit " + secureSessionStorage.getItem("PatientName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Edit " + secureSessionStorage.getItem("PatientName")}
                        </Typography>}
                    />

                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                            <Avatar
                                                alt="Image"
                                                variant="circular"
                                                src={image}
                                                sx={{
                                                    marginLeft: 5,
                                                    width: 150,
                                                    height: 150,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        transform: 'scale(1.1)',
                                                    },
                                                }}
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                onClick={() => document.getElementById('avatar-input').click()}
                                            >
                                                <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                                    {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="ooui:user-avatar-outline" color="#4481eb" width="80" />}
                                                    <div className="text-container">
                                                        <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                                    </div>
                                                </div>
                                            </Avatar>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="avatar-input"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none', }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 8">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="hmrNo"
                                                label={
                                                    <span>
                                                        Medical Record No{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                value={hmrNo}
                                                error={hmrNoError}
                                                helperText={hmrNoError && "HMR No is required"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            <TextField
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                value={email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={emailError}
                                                helperText={emailError && "Email address is required"}
                                            />
                                        </Stack>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                            <TextField
                                                name="firstName"
                                                label={
                                                    <span>
                                                        First Name{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={firstName}
                                                fullWidth
                                                error={firstNameError}
                                                helperText={firstNameError && "First Name is required"}
                                            />
                                            <TextField
                                                name="lastName"
                                                label={
                                                    <span>
                                                        Last Name{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                value={lastName}
                                                error={lastNameError}
                                                helperText={lastNameError && "Last Name is required"}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                id="date"
                                                name="dob"
                                                label={
                                                    <span>
                                                        DOB{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                type="date"
                                                value={dob}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: maxDate,
                                                }}
                                                error={dobError}
                                                helperText={dobError && dobErrorMessage}
                                            />
                                            <TextField
                                                fullWidth
                                                name="age"
                                                label="Age"
                                                value={age}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={gender}
                                                    label="Gender"
                                                    onChange={(e) => setGender(e.target.value)}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select an option
                                                    </MenuItem>
                                                    <MenuItem value="Female">Female</MenuItem>
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Others">Others</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                fullWidth
                                                name="phone"
                                                label={
                                                    <span>
                                                        Phone{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                value={phone}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={phoneError}
                                                helperText={phoneError && phoneErrorMessage}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="column" spacing={2}>
                                            <Accordion >
                                                <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 'auto' }}>
                                                    <Typography variant='h5'>Medical Information</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack direction="column" spacing={2}>
                                                        <Stack direction="row" spacing={2}>
                                                            <div style={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    freeSolo
                                                                    options={tagOptions} // Array of existing tag options
                                                                    value={selectedTags}
                                                                    onChange={(event, newValue) => {
                                                                        // Handle the change in selected tags here
                                                                        setSelectedTags(newValue);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            name="tags"
                                                                            label={secureSessionStorage.getItem("PatientName") + " Tags"}
                                                                            onChange={handleChange}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <TextField
                                                                    name="height"
                                                                    type="number"
                                                                    label="Height (in cm)"
                                                                    value={height}
                                                                    onChange={handleChange}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    fullWidth />
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <TextField
                                                                    name="weight"
                                                                    type="number"
                                                                    label="Weight (in kg)"
                                                                    value={weight}
                                                                    onChange={handleChange}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    fullWidth />
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <TextField
                                                                    name="bloodGroup"
                                                                    label="Blood Group"
                                                                    onChange={handleChange}
                                                                    value={bloodGroup}
                                                                    inputProps={{
                                                                        inputMode: 'text',
                                                                        pattern: '[A-Za-z+-]*'
                                                                    }}
                                                                    onKeyPress={(event) => {
                                                                        // Allow only letters, '+' and '-'
                                                                        if (!/[a-zA-Z+-]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    fullWidth />
                                                            </div>
                                                        </Stack>
                                                        <Stack direction="row" spacing={2}>
                                                            <div style={{ width: '100%' }}>
                                                                <Typography variant="body1">Any Allergies:</Typography>
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <RadioGroup value={hasAllergies} onChange={handleAllergyChange} row>
                                                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                    </RadioGroup>
                                                                </div>
                                                            </div>
                                                            <TextField
                                                                name="allergyDetails"
                                                                label="Specify Allergy"
                                                                fullWidth
                                                                value={allergyDetails}
                                                                onChange={handleChange}
                                                                disabled={hasAllergies !== 'Yes'}
                                                                error={allergyDetailsError}
                                                                helperText={allergyDetailsError && "Specify the Allergy Detail"}
                                                            />
                                                            <div style={{ width: '100%' }}>
                                                                <Typography variant="body1">Any Past Complications:</Typography>
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <RadioGroup value={hasPastComplications} onChange={handleCompliChange} row>
                                                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                    </RadioGroup>
                                                                </div>
                                                            </div>
                                                            <TextField
                                                                name="pastComplicationsDetails"
                                                                label="Specify Past Complications"
                                                                fullWidth
                                                                value={pastComplicationsDetails}
                                                                onChange={handleChange}
                                                                disabled={hasPastComplications !== 'Yes'}
                                                                error={pastComplicationsDetailsError}
                                                                helperText={allergyDetailsError && "Specify the Past Complication Detail"}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 'auto' }}>
                                                    <Typography variant='h5'>Address Information</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                                        <TextField
                                                            name="street"
                                                            label="Street Address"
                                                            onChange={handleChange}
                                                            value={street}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            name="city"
                                                            label="City"
                                                            fullWidth
                                                            value={city}
                                                            onChange={handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <TextField
                                                            name="state"
                                                            label="State"
                                                            fullWidth
                                                            value={state}
                                                            onChange={handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <TextField
                                                            name="postalCode"
                                                            label="Postal Code"
                                                            value={postalCode}
                                                            onChange={handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth />
                                                        <TextField
                                                            name="country"
                                                            label="Country"
                                                            fullWidth
                                                            value={country}
                                                            onChange={handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item xs={6} md={6} lg={6}> {/* Adjust the grid sizes based on your layout */}
                                {/* Empty grid item for spacing */}
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}> {/* Adjust the grid sizes based on your layout */}
                                <Button
                                    fullWidth
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="error"
                                    onClick={() => setRedirectToList(true)}
                                    sx={{
                                        borderRadius: "12px",
                                    }}
                                    startIcon={<Cancel />}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}> {/* Adjust the grid sizes based on your layout */}
                                <Button
                                    fullWidth
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    onClick={handleUpdate}
                                    sx={{
                                        borderRadius: "12px",
                                    }}
                                    startIcon={<Edit />}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>

                <Card sx={{
                    minWidth: 300,
                    marginTop: 2,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Assigned " + secureSessionStorage.getItem("PhysicianName")}
                        </Typography>}
                        action={
                            <Tooltip title='Assign More'>
                                <Fab onMouseDown={handleMouseDown} onClick={handleOpenPhysicianList} size="small" color="primary" aria-label="add">
                                    <Add />
                                </Fab>
                            </Tooltip>
                        }
                    />
                    <CardContent sx={{
                        maxHeight: 300,
                        overflowY: "scroll",
                    }}>
                        <Grid container spacing={2}>
                            {
                                physicianDetails.length > 0 ?
                                    physicianDetails.map((physician) => (
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Card
                                                key={physician.physicianuuid}
                                                sx={{
                                                    position: 'relative',
                                                    boxShadow: 3,
                                                }}>
                                                <Tooltip title={"Deselect " + secureSessionStorage.getItem("PhysicianName")}>
                                                    <PlaylistRemoveRounded
                                                        className="delete-icon" sx={{
                                                            fontSize: "30px",
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            color: pink[500],
                                                            cursor: 'pointer',
                                                            zIndex: 1,
                                                        }}
                                                        onClick={() => handleDeselectPhysician(physician)}
                                                    />
                                                </Tooltip>
                                                <CardHeader
                                                    avatar={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Avatar
                                                            variant='rounded'
                                                            alt={physician.firstName + ' ' + physician.lastName}
                                                            src={physician.image}
                                                            sx={{ width: 100, height: 100, objectFit: "contain" }} />
                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Name
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                    {physician.firstName + ' ' + physician.lastName}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Email
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                    {physician.email}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    } />
                                            </Card>
                                        </Grid>
                                    )) : <center>
                                        {"No " + secureSessionStorage.getItem("PhysicianName") + " Assigned"}
                                    </center>
                            }
                        </Grid>
                    </CardContent>
                </Card>

                <Card sx={{
                    minWidth: 300,
                    marginTop: 2,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Assigned " + secureSessionStorage.getItem("DeviceName")}
                        </Typography>}
                        action={
                            <Tooltip title='Assign More'>
                                <Fab onMouseDown={handleMouseDown} onClick={handleOpenDeviceList} size="small" color="primary" aria-label="add">
                                    <Add />
                                </Fab>
                            </Tooltip>
                        }
                    />
                    <CardContent sx={{
                        maxHeight: 300,
                        overflowY: "scroll",
                    }}>
                        <Grid container spacing={2}>
                            {
                                assignedDevices.length > 0 ?
                                    assignedDevices.map((device) => (
                                        <Grid item xs={12} sm={12} md={4}>
                                            <Card sx={{
                                                position: 'relative',
                                                boxShadow: 3,
                                                '&:hover .delete-icon': {
                                                    display: 'block',
                                                },
                                            }}>
                                                <Tooltip title={"Deselect " + secureSessionStorage.getItem("DeviceName")}>
                                                    <PlaylistRemoveRounded
                                                        className="delete-icon" sx={{
                                                            fontSize: "30px",
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            display: 'none',
                                                            color: pink[500],
                                                            cursor: 'pointer',
                                                            zIndex: 1,
                                                        }}
                                                        onClick={() => handleDeselectDevice(device)}
                                                    />
                                                </Tooltip>
                                                <CardHeader
                                                    avatar={
                                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                                            <Avatar
                                                                src={device.deviceimageName && device.deviceimageName !== "null" ? device.deviceimageName : DeviceImg}
                                                                alt={device.deviceName}
                                                                sx={{ width: 56, height: 56, marginRight: 2 }}
                                                            />
                                                            <div>
                                                                <Typography variant="subtitle1" fontWeight="bold">
                                                                    {device.devicename}
                                                                </Typography>
                                                                <Typography variant="subtitle2" color="textSecondary">
                                                                    ID: {device.decmacid}
                                                                </Typography>
                                                            </div>
                                                        </Box>
                                                    } />
                                            </Card>
                                        </Grid>
                                    )) : <center>
                                        {"No " + secureSessionStorage.getItem("DeviceName") + " Assigned"}
                                    </center>
                            }
                        </Grid>
                    </CardContent>
                </Card>
                <div style={{
                    height: "15px"
                }} />

                <div>
                    <Dialog open={openAddMoreDevice} onClose={handleCloseDeviceList}>
                        <CardHeader
                            title={"Add " + secureSessionStorage.getItem("DeviceName")}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseDeviceList}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {
                                device.length > 0 ?
                                    <>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            maxHeight: 400,
                                            overflowY: "scroll"
                                        }} >
                                            {device
                                                .map((device) => (
                                                    <FormControlLabel
                                                        key={device.deviceuuid}
                                                        control={
                                                            <Tooltip
                                                                title={tooltipMessage}
                                                                open={tooltipDeviceId === device.deviceuuid} // Show tooltip only for the specific device
                                                                disableHoverListener
                                                                onClose={() => setTooltipDeviceId(null)} // Close tooltip on interaction
                                                            >
                                                                <Checkbox
                                                                    checked={selectedDevicestoAssign.some(
                                                                        (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                                    )}
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        const deviceId = device.deviceuuid;
                                                                        const deviceName = device.devicename;
                                                                        const deviceMacId = device.decmacid;
                                                                        const deviceCategory = device.deviceCategory;

                                                                        setSelectedDevicetoAssign((prevSelectedDevices) => {
                                                                            const categoryExists = prevSelectedDevices.some(
                                                                                (d) => d.category === deviceCategory
                                                                            );

                                                                            if (checked) {
                                                                                if (categoryExists) {
                                                                                    // Show tooltip only for the current device
                                                                                    setTooltipMessage(`Only one ${secureSessionStorage.getItem("DeviceName")} from the ${deviceCategory} category can be selected.`);
                                                                                    setTooltipDeviceId(deviceId); // Set tooltip for this specific device
                                                                                    return prevSelectedDevices; // Do not add duplicate
                                                                                } else {
                                                                                    // Add device if no matching category
                                                                                    setTooltipDeviceId(null); // Close tooltip
                                                                                    return [
                                                                                        ...prevSelectedDevices,
                                                                                        { id: deviceId, name: deviceName, macid: deviceMacId, category: deviceCategory },
                                                                                    ];
                                                                                }
                                                                            } else {
                                                                                // Remove device if unchecked
                                                                                return prevSelectedDevices.filter(
                                                                                    (d) => d.id !== deviceId || d.macid !== deviceMacId
                                                                                );
                                                                            }
                                                                        });
                                                                    }}
                                                                    value={device.deviceuuid}
                                                                />
                                                            </Tooltip>
                                                        }
                                                        label={
                                                            <CardHeader
                                                                avatar={
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Avatar
                                                                            variant="rounded"
                                                                            alt={device.devicename}
                                                                            src={device.deviceimageName}
                                                                            sx={{ width: 80, height: 80, objectFit: "contain" }}
                                                                        />
                                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                    {device.devicename}
                                                                                </Typography>
                                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                    {device.decmacid}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                }
                                                            />
                                                        }
                                                    />
                                                ))}
                                        </Box>
                                    </>
                                    : <Box sx={{

                                        alignItems: "center"  // Center the content vertically
                                    }}>
                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                            No {secureSessionStorage.getItem("DeviceName")} Found
                                        </div>
                                    </Box>
                            }
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseDeviceList} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} disabled={!selectedDevicestoAssign.length > 0} onClick={handleAssignDevicetoPatient} fullWidth >Submit</Button>
                        </CardActions>
                    </Dialog>
                </div>

                <div >
                    <Dialog
                        open={openPhysicianList}
                        onClose={handleClosePhysicianList}
                        fullScreen={fullScreen}
                        aria-labelledby="responsive-dialog-title">
                        <Card>
                            <CardHeader
                                title={"Select " + secureSessionStorage.getItem("PhysicianName")}
                                action={
                                    <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePhysicianList}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent sx={{ maxHeight: 350, overflowY: "scroll" }}>
                                <List sx={{ pt: 0 }}>
                                    {physicians.length > 0 ?
                                        physicians.map((physician) => (
                                            <ListItem disableGutters>

                                                <ListItemButton onClick={(event) => handleListItemClick(event, physician)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={selectedPhysicians.some((p) => p.physicianuuid === physician.physicianuuid)}
                                                            onChange={(event) => handleCheckboxChange(event, physician)}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ bgcolor: blue[100], color: blue[600], width: 50, height: 50 }} src={physician.image} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={physician.firstName + " " + physician.lastName} />
                                                </ListItemButton>

                                            </ListItem>
                                        )) : <Typography variant="body1">No {secureSessionStorage.getItem("PhysicianName")} available</Typography>

                                    }
                                </List>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    fullWidth
                                    startIcon={<Close />}
                                    onClick={handleClosePhysicianList}
                                >Cancel
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    startIcon={<Done />}
                                    onClick={handleAssignPhysician}
                                >Submit
                                </Button>
                            </CardActions>
                        </Card>
                    </Dialog>
                </div>

                <div style={{ height: "15px" }} />

            </Container >
        </div >
    )
}

export default EditPatient
