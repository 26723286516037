import React, { useEffect, useState } from "react";

// @mui-components
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";

//components
import Iconify from "../../../components/iconify";

//externals
import PropTypes from "prop-types";
import secureSessionStorage from "../../../crypto";

//bootstrap-components
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

//services
import { Icon } from "@iconify/react";
import ApiCalls from "../../../services/try";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

MeasurementList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function MeasurementList({
  title,
  subheader,
  measurements,
  setMeasurements,
  patientId,
  loading,
  setLoading,
  onRefreshGraph, // Receive the callback prop
  ...other
}) {
  let userFeed = measurements.map(function (measures) {
    const dateStr = measures.dateOfMeasurementinIST;

    var roundedData = measures.readingValues
      .split(",")
      .map(Number)
      .map((num) => num.toFixed(2))
      .join(",");
    const row = {
      measureId: measures.measurementid,
      name: measures.deviceName,
      paramName: measures.paramName,
      meastime: dateStr,
      value:
        measures.paramName === "Panic" ? (
          <p
            style={{ color: "red", fontFamily: "calibri", fontWeight: "bold" }}
          >
            {" "}
            {measures.paramName + " Occurred"}
          </p>
        ) : measures.paramName === "Motion" ? (
          measures.paramName + " Detected"
        ) : (
          roundedData
        ),
    };
    return row;
  });

  const handleRefresh = () => {
    loadFilterMeasurements(
      selectedParamFilter.label,
      selectedDateFilter.value,
      selectedDurationFilter.value
    );
    if (onRefreshGraph) {
      onRefreshGraph(); // Call the parent component's graph refresh function
    }
  };

  const data = {
    columns: [
      // {
      //   label: "Measurement Id",
      //   field: "measureId",
      // },
      {
        label: secureSessionStorage.getItem("DeviceName"),
        field: "name",
      },
      {
        label: "Parameter",
        field: "paramName",
      },
      {
        label: "TimeStamp",
        field: "meastime",
      },
      {
        label: "Value",
        field: "value",
      },
    ],
    rows: userFeed,
  };
  const [selectedParamFilter, setSelectedParamFilter] = useState([]);
  const [parameters, setParameters] = useState([]);

  const [openDateFilter, setOpenDateFilter] = useState(null);
  const [openParamFilter, setOpenParamFilter] = useState(null);
  const [openDurationFilter, setOpenDurationFilter] = useState(null);
  const [paramName, setParamName] = useState("");

  const [filterOptions, setFilterOptions] = useState([
    {
      value: "today",
      label: "Today",
    },
    {
      value: "week",
      label: "One Week",
    },
    {
      value: "month",
      label: "One Month",
    },
    {
      value: "All",
      label: "All",
    },
  ]);

  // Updated filter options with additional duration options
  const [durationFilterOptions, setDurationFilterOptions] = useState([
    { value: "60", label: "Last 1 Hour" },
    { value: "120", label: "Last 2 Hours" },
    { value: "180", label: "Last 3 Hours" },
    { value: "240", label: "Last 4 Hours" },
    { value: "300", label: "Last 5 Hours" },
    { value: "360", label: "Last 6 Hours" },
    { value: "420", label: "Last 7 Hours" },
  ]);

  // Set the default filter to "Today"
  const [selectedDurationFilter, setSelectedDurationFilter] = useState(
    durationFilterOptions.find((option) => option.value === "60")
  );

  // Set the default filter to "Today"
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    filterOptions.find((option) => option.value === "today")
  );

  useEffect(() => {
    console.log("Entered To get The param Name" + patientId);
    ApiCalls.getdetails(
      "patientId/" + patientId + "/getmeasurementparameter"
    ).then((res) => {
      console.log("Parameter Name");
      console.log("*********************");
      console.log(res.data.measure);
      if (res.data.measure !== undefined) {
        console.log(res.data.measure[0]);
        setParamName(res.data.measure[0].paramName);
        const options = res.data.measure.map((d) => ({
          label: d.paramName,
          value: d.paramid,
        }));
        const allOption = { label: "All", value: "all" };
        options.unshift(allOption);
        setParameters(options);
        setSelectedParamFilter(options[0]);
      }
    });
  }, [patientId]);

  const handleOpenDurationFilters = (e) => {
    setOpenDurationFilter(e.currentTarget);
  };

  const handleCloseDurationFilters = () => {
    setOpenDurationFilter(null);
  };

  const handleDurationFilterChange = (filter) => {
    console.log("Select Duration Filter");
    console.log(filter.value);
    setSelectedDurationFilter(filter);
    // setLoading(true);
    loadFilterMeasurements(
      selectedParamFilter.label,
      selectedDateFilter.value,
      filter.value
    );
    handleCloseDurationFilters();
  };

  const handleOpenDateFilters = (e) => {
    setOpenDateFilter(e.currentTarget);
  };

  const handleCloseDateFilters = () => {
    setOpenDateFilter(null);
  };

  const handleDateFilterChange = (filter) => {
    console.log("Select Filter");
    console.log(filter.value);
    setSelectedDateFilter(filter);
    // setLoading(true);
    loadFilterMeasurements(
      selectedParamFilter.label,
      filter.value,
      selectedDurationFilter.value
    );
    handleCloseDateFilters();
  };

  const handleOpenParamFilters = (e) => {
    setOpenParamFilter(e.currentTarget);
  };

  const handleCloseParamFilters = () => {
    setOpenParamFilter(null);
  };
  const handleParamFilterChange = (filter) => {
    console.log("Select Filter");
    console.log(filter);
    setSelectedParamFilter(filter);
    setLoading(true);
    loadFilterMeasurements(
      filter.label,
      selectedDateFilter.value,
      selectedDurationFilter.value
    );
    handleCloseParamFilters();
  };

  const loadFilterMeasurements = (
    parameterName,
    dateFilter,
    durationFilter
  ) => {
    setLoading(true);

    // If both parameterName and dateFilter are "All"
    if (parameterName === "All" && dateFilter === "All") {
      ApiCalls.getdetails(
        `patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/${durationFilter}`
      )
        .then((response) => {
          if (response.data.measurement !== undefined) {
            console.log("Measurement Details");
            console.log(
              "Measurement Count=" + response.data.measurement.length
            );
            console.log(response.data);
            setMeasurements(response.data.measurement);
          } else {
            setMeasurements([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

    // If both parameterName and dateFilter are not "All"
    else {
      ApiCalls.getdetails(
        `patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/${durationFilter}`
      )
        .then((response) => {
          if (response.data.measurement !== undefined) {
            console.log("Measurement Details");
            console.log(
              "Measurement Count=" + response.data.measurement.length
            );
            console.log(response.data);
            setMeasurements(response.data.measurement);
          } else {
            setMeasurements([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <>
            <Tooltip title="Refresh">
              <Button
                color="primary"
                variant="contained"
                onClick={handleRefresh} // Update to call handleRefresh
                onMouseDown={handleMouseDown}
              >
                <Icon icon="mdi:refresh" width="25" />
              </Button>
            </Tooltip>
            <Tooltip title="Filter Parameter">
              <Button
                color="primary"
                className="ml-2"
                variant="contained"
                onClick={handleOpenParamFilters}
                endIcon={<Iconify icon="material-symbols:filter-alt" />}
              >
                {selectedParamFilter.label}
              </Button>
            </Tooltip>
            <Tooltip title="Filter Date">
              <Button
                className="ml-2"
                color="primary"
                variant="contained"
                onClick={handleOpenDateFilters}
                endIcon={
                  <Iconify icon="material-symbols:filter-list-rounded" />
                }
              >
                {selectedDateFilter.label}
              </Button>
            </Tooltip>
            {selectedDateFilter.value === "today" && (
              <Tooltip title="Filter Hours">
                <Button
                  className="ml-2"
                  color="primary"
                  variant="contained"
                  onClick={handleOpenDurationFilters}
                  endIcon={<Iconify icon="ph:timer-fill" />}
                >
                  {selectedDurationFilter.label}
                </Button>
              </Tooltip>
            )}
          </>
        }
      />

      <Popover
        open={Boolean(openDurationFilter)}
        anchorEl={openDurationFilter}
        onClose={handleCloseDurationFilters}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {durationFilterOptions.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedDurationFilter.value}
              onClick={() => handleDurationFilterChange(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>

      <Popover
        open={Boolean(openDateFilter)}
        anchorEl={openDateFilter}
        onClose={handleCloseDateFilters}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {filterOptions.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedDateFilter.value}
              onClick={() => handleDateFilterChange(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>

      <Popover
        open={Boolean(openParamFilter)}
        anchorEl={openParamFilter}
        onClose={handleCloseParamFilters}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {parameters.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedParamFilter.value}
              onClick={() => handleParamFilterChange(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>

      <Box sx={{ mx: 3 }} dir="ltr">
        {loading ? (
          <MDBTable
            striped
            borderless
            hover
            responsive
            style={{ marginTop: 15 }}
          >
            <MDBTableHead color="primary-color" textWhite>
              <tr>
                {/* <th>Measurement Id</th> */}
                <th>{secureSessionStorage.getItem("DeviceName")}</th>
                <th>Parameter</th>
                <th>Measured @</th>
                <th>Reading</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td colSpan="5">
                  <center>
                    <CircularProgress />
                  </center>
                </td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        ) : (
          <MDBDataTable
            theadColor="primary-color"
            borderless
            entriesOptions={[5, 10, 20, 50, 100]}
            fullpagination="true"
            entries={5}
            tag
            dark={false}
            noBottomColumns
            responsive
            data={data}
            processing={loading}
            striped
            btn={true}
            sortable={false}
            noRecordsFoundLabel={<center>No Measurements Found</center>}
          />
        )}
      </Box>
    </Card>
  );
}
