import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//components
import Preview from '../../layout/Preview';

//mui-components
import { Home } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Container,
    FormControlLabel,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//externals
import { Icon } from '@iconify/react';
import secureSessionStorage from "../../crypto";

//services
import ApiCalls from "../../services/try";

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

//----------------------------------------------------------------

const DeviceTwin = () => {

    const [tabvalue, setTabvalue] = useState(0);

    const [groupName, setGroupName] = useState('');

    const [selectedDevice, setSelectedDevice] = useState([]);
    const [deviceParameters, setDeviceParameters] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkedActionItems, setCheckedActionItems] = useState([]);

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleChangeTab = (event, newValue) => {
        setTabvalue(newValue);
    };


    const handleToggle = (id) => {
        const currentIndex = checkedItems.indexOf(id);
        const newChecked = [...checkedItems];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedItems(newChecked);
    };

    const fetchLatestMeasurement = (parameter) => {
        ApiCalls.getdetails("parameter/" + parameter.name + "/device/" + selectedDevice.deviceuuid + "/getlastmeasurement")
            .then((response) => {
                console.log(response.data.measure[0]);
                setCheckedItems((prevCheckedItems) => {
                    const updatedItems = prevCheckedItems.map((item) => {
                        if (item.paramId === parameter.paramId) {
                            return {
                                ...item,
                                intVal: response.data.measure[0].intVal,
                                floatVal: response.data.measure[0].floatVal,
                            };
                        }
                        return item;
                    });
                    return updatedItems;
                });
            })
            .catch((err) => {
                console.log(err);
                setCheckedItems((prevCheckedItems) => {
                    const updatedItems = prevCheckedItems.map((item) => {
                        if (item.paramId === parameter.paramId) {
                            return {
                                ...item,
                                intVal: "‒ ‒",
                            };
                        }
                        return item;
                    });
                    return updatedItems;
                });
            });
    };

    useEffect(() => {
        // Fetch initial data
        deviceParameters.forEach((parameter) => {
            if (parameter.typeName === "measurement") {
                fetchLatestMeasurement(parameter);
            }
        });

        // Set up interval to fetch updated data every 5 minutes
        const interval = setInterval(() => {
            deviceParameters.forEach((parameter) => {
                if (parameter.typeName === "measurement") {
                    fetchLatestMeasurement(parameter);
                }
            });
        }, 5 * 60 * 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [deviceParameters]); // Run whenever deviceParameters changes


    const loadDeviceParameters = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/getparameterdetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.parameters != undefined) {
                    setDeviceParameters(response.data.parameters);
                } else {
                    setDeviceParameters([]);
                }
            })
    }

    const loadDeviceGroups = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/getparameterdetailsss?")
            .then((response) => {
                console.log(response.data);
                if (response.data.parameterGroup != undefined) {
                    setGroupName(response.data.parametergroup[0].groupName);
                } else {
                    setGroupName("");
                }
            })
    }

    useEffect(() => {
        const device = JSON.parse(secureSessionStorage.getItem("deviceIdtoTwin"));
        setSelectedDevice(device);
        loadDeviceParameters(device.decmacid);
        loadDeviceGroups(device.decmacid);
    }, [])

    const handleSwitchChange = (event, parameter) => {
        var devmacid = selectedDevice.decmacid;
        var deviceName = selectedDevice.devicename;
        var paramName = parameter.name;
        var value = event.target.checked ? "1" : "0";

        console.log(devmacid, deviceName, groupName, paramName, value);

        ApiCalls.register("devid/" + devmacid + "/groupname/" + groupName + "/parameter/" + paramName + "/value/" + value + "/sendmqttdata")
            .then((response) => {
                console.log("Action Success")
                console.log(response.data);
            })
            .catch((err) => {
                console.log("Error Occured")
                console.log(err);
            })

    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={secureSessionStorage.getItem("DeviceName") + " Twin"} />
                    </Breadcrumbs>

                </Stack>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card sx={{
                            boxShadow: 3,
                            minHeight: 200,
                            maxHeight: 600,
                            overflowY: "scroll",
                            backgroundColor: "#F4F6F8",
                        }}>
                            <CardHeader title="Parameters" />
                            <CardContent>
                                <Tabs
                                    value={tabvalue}
                                    onChange={handleChangeTab}
                                    onMouseDown={handleMouseDown}
                                    aria-label="icon label tabs example"
                                >
                                    <Tab
                                        icon={<Icon icon={'ri:numbers-line'} />}
                                        label="Measurement"
                                        sx={{
                                            fontSize: 18,
                                        }}
                                    />
                                    <Tab
                                        icon={<Icon icon={'mdi:interaction-tap'} />}
                                        label="Actions"
                                        sx={{
                                            fontSize: 18,
                                        }}
                                    />
                                </Tabs>

                                <TabPanel value={tabvalue} index={0}>
                                    {
                                        deviceParameters.length > 0 ?
                                            <>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                }}>
                                                    {deviceParameters
                                                        .filter((parameter) => parameter.typeName === "measurement")
                                                        .map((parameter) => (
                                                            <ListItem>
                                                                <ListItemButton sx={{ pl: 5 }}>
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            checked={checkedItems.some((item) => item.paramId === parameter.paramId)}
                                                                            onChange={() => {
                                                                                if (checkedItems.some((item) => item.paramId === parameter.paramId)) {
                                                                                    setCheckedItems(checkedItems.filter((item) => item.paramId !== parameter.paramId));
                                                                                } else {
                                                                                    ApiCalls.getdetails("parameter/" + parameter.name + "/device/" + selectedDevice.deviceuuid + "/getlastmeasurement")
                                                                                        .then((response) => {
                                                                                            console.log(response.data.measure[0]);
                                                                                            setCheckedItems([...checkedItems, { paramId: parameter.paramId, name: parameter.name, intVal: response.data.measure[0].intVal, floatVal: response.data.measure[0].floatVal }]);
                                                                                        }).catch((err) => {
                                                                                            console.log(err);
                                                                                            setCheckedItems([...checkedItems, { paramId: parameter.paramId, name: parameter.name, intVal: "‒ ‒" }]);
                                                                                        });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={parameter.name} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                </Box>
                                            </>
                                            : <center>
                                                No Parameter Found
                                            </center>
                                    }
                                </TabPanel>

                                <TabPanel value={tabvalue} index={1}>
                                    {deviceParameters.filter((parameter) => parameter.typeName === "action").length > 0 ? (
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}>
                                            {deviceParameters
                                                .filter((parameter) => parameter.typeName === "action")
                                                .map((parameter) => (
                                                    <ListItem>
                                                        <ListItemButton sx={{ pl: 5 }}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    checked={checkedActionItems.some((item) => item.paramId === parameter.paramId)}
                                                                    onChange={() => {
                                                                        if (checkedActionItems.some((item) => item.paramId === parameter.paramId)) {
                                                                            setCheckedActionItems(checkedActionItems.filter((item) => item.paramId !== parameter.paramId));
                                                                        } else {
                                                                            ApiCalls.getdetails("parameter/" + parameter.name + "/device/" + selectedDevice.deviceuuid + "/getlastmeasurement")
                                                                                .then((response) => {
                                                                                    console.log(response.data.measure[0]);
                                                                                    setCheckedActionItems([...checkedActionItems, { paramId: parameter.paramId, name: parameter.name, intVal: response.data.measure[0].intVal }]);
                                                                                }).catch((err) => {
                                                                                    console.log(err);
                                                                                    setCheckedActionItems([...checkedActionItems, { paramId: parameter.paramId, name: parameter.name, intVal: 0 }]);
                                                                                });
                                                                        }
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={parameter.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                        </Box>
                                    ) : (
                                        <center>
                                            No Action Parameter Found
                                        </center>
                                    )}
                                </TabPanel>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <Card sx={{
                            boxShadow: 3,
                            minHeight: 550,
                            maxHeight: 600,
                            overflowY: "scroll",
                            backgroundColor: "#F4F6F8",
                        }}>
                            <CardHeader
                                title={<Tabs
                                    value={tabvalue}
                                    onChange={handleChangeTab}
                                    onMouseDown={handleMouseDown}
                                    aria-label="icon label tabs example"
                                >
                                    <Tab
                                        icon={<Icon icon={'tabler:device-desktop-cog'} />}
                                        label={secureSessionStorage.getItem("DeviceName") + " Twin"}
                                        sx={{
                                            fontSize: 18,
                                        }}
                                    />
                                    <Tab
                                        icon={<Icon icon={'carbon:touch-interaction'} />}
                                        label="Action Parameters"
                                        sx={{
                                            fontSize: 18,
                                        }}
                                    />
                                    {/* <Tab
                                        icon={<Icon icon={'solar:graph-new-linear'} />}
                                        label="Graphical View"
                                        sx={{
                                            fontSize: 18,
                                        }}
                                    /> */}
                                </Tabs>}
                            />
                            <CardContent>
                                <TabPanel value={tabvalue} index={0}>
                                    {checkedItems.length > 0 ?
                                        <Grid container spacing={2}>
                                            {checkedItems.map((item) => (
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <Box sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        minHeight: 100,
                                                        minWidth: 80,
                                                        boxShadow: 3,
                                                        borderRadius: 2
                                                    }}>
                                                        <Typography variant="h6">{item.name}</Typography>
                                                        <TextField
                                                            inputProps={{ size: 4, readOnly: true }}
                                                            value={
                                                                item.intVal !== null && item.intVal !== undefined && item.intVal !== 0
                                                                    ? item.intVal
                                                                    : item.floatVal
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        :
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}>
                                            <Typography variant="h6">No parameter selected</Typography>
                                        </Box>
                                    }
                                </TabPanel>

                                <TabPanel value={tabvalue} index={1}>
                                    {checkedActionItems.length > 0 ?
                                        <Grid container spacing={2}>
                                            {checkedActionItems.map((item) => (
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <Box sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        minHeight: 100,
                                                        minWidth: 80,
                                                        boxShadow: 3,
                                                        borderRadius: 2
                                                    }}>
                                                        <Typography variant="h6">{item.name}</Typography>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Typography>Off</Typography>
                                                            <FormControlLabel
                                                                control={
                                                                    <IOSSwitch
                                                                        sx={{ m: 1 }}
                                                                        defaultChecked={item.intVal == 1}
                                                                        id={item.paramId}
                                                                        value={item.name}
                                                                        name={item.name}
                                                                        onChange={(e) => handleSwitchChange(e, item)}

                                                                    />
                                                                }
                                                            />
                                                            <Typography>On</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        :
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}>
                                            <Typography variant="h6">No parameter selected</Typography>
                                        </Box>
                                    }
                                </TabPanel>
                                {/* <TabPanel value={tabvalue} index={2}>

                                </TabPanel> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Container>
        </div>
    )
}
export default DeviceTwin
