import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';

// WebSocket URL
const WS_URL = 'wss://itouch-health.iorbit.health:8081/consumer';

const BplLiveChart = ({ parameterName, hrValue }) => {
  const maxDataPoints = 2500;
  const [data, setData] = useState({
    labels: Array.from({ length: maxDataPoints }, (_, i) => i),
    datasets: [
      {
        label: parameterName,
        data: Array(maxDataPoints).fill(0),
        fill: false,
        borderColor: "rgb(0, 255, 0)",
        tension: 0.4,
        borderWidth: 2,
        showLine: true,
        pointRadius: 0,
      },
    ],
  });

  const socketRef = useRef(null);

  useEffect(() => {
    // Initialize WebSocket connection
    socketRef.current = new WebSocket(WS_URL);

    socketRef.current.onopen = () => {
      console.log('WebSocket connection opened.');
      // Send the request packet when connection is opened
      const requestPacket = {
        PacketID: "WS_DEVICE_CONSUME_REQ",
        DeviceUUID: "22824",
        ParamUUID: 20002,
        SamplingRate: 250,
        WindowSize: 10,
        MinYVal: 0.0,
        MaxYVal: 128.0,
        isStream: true,
        labelValues: null
      };
      socketRef.current.send(JSON.stringify(requestPacket));
    };

    socketRef.current.onmessage = (event) => {
      try {
        const response = JSON.parse(event.data);
        console.log("WebSocket response:", response);

        if (response && response.valueQuantity) {
          let value = response.valueQuantity.value;
          // Convert value to a number if it's a string
          if (typeof value === 'string') {
            value = parseFloat(value);
          }

          if (!isNaN(value)) {
            //console.log("The hr vital is", value);
            // Update hrValue state here if hrValue is a prop passed from a parent component
          } else {
            console.error('Invalid number format:', value);
          }
        }

        // Validate response structure
        if (response && response.component && Array.isArray(response.component) && response.component.length > 0) {
          const component = response.component[0];
          const valueSampledData = component.valueSampledData;

          if (valueSampledData && valueSampledData.data) {
            const arr = valueSampledData.data.split(",").map(Number); // Convert string array to number array
            setData(prevData => ({
              ...prevData,
              datasets: [
                {
                  ...prevData.datasets[0],
                  data: arr
                }
              ]
            }));
          } else {
            console.error("Missing or invalid data in valueSampledData:", valueSampledData);
          }
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
      }
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    return () => {
      socketRef.current.close();
    };
  }, []);

  useEffect(() => {
    if (hrValue !== null) {
      console.log("The HR vital value received is", hrValue);
      // Optionally update chart data with the new hrValue here if needed
    }
  }, [hrValue]);

  const chartOptions = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        min: -2048,
        max: 2048
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <div style={{ height: '23vh' }}>
      <Line data={data} options={chartOptions} />
    </div>
  );
};

export default BplLiveChart;
